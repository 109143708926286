import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, notification, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { STATUS_CODE_AMENDMENT } from '../../../../constants/Alert';
import { ROLE_ANALYST } from '../../../../constants/Role';
import { BASE_NOTIFICATION_CONFIG } from '../../../../constants/common';
import { getUserName } from '../../../../helpers/getUserName';
import { BaseAlert } from '../../../../models/Alert';
import { EmployeeModel } from '../../../../models/UserModel';
import { useUpdateAnalystsMutation, useUpdateStatusMutation } from '../../../../redux/api/alertsApi';
import { useGetEmployeesQuery } from '../../../../redux/api/userApi';
import { getUser } from '../../../../redux/features/authSlice';
import { useAppSelector } from '../../../../redux/hooks';

const defaultUserQuery = {
    orderBy: 'createdDate desc',
    filter: {
        roles: {
            name: ROLE_ANALYST,
        },
        lockedDate: null,
    },
};
interface AmendmentFormProps {
    ids: Array<BaseAlert['id']>;
    onSuccess: () => void;
    analyst?: EmployeeModel;
}

const { TextArea } = Input;
const AmendmentForm: FC<AmendmentFormProps> = ({ ids, onSuccess, analyst }) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const user = useAppSelector(getUser);
    const { data: analysts, isLoading } = useGetEmployeesQuery(defaultUserQuery);
    const [updateStatus, { isLoading: isUpdatingStatus }] = useUpdateStatusMutation();
    const [updateAnalyst, { isLoading: isUpdatingAnalyst }] = useUpdateAnalystsMutation();

    const handleSubmit = async () => {
        const userId = form.getFieldValue('userId');
        const needUpdateAnalyst = analyst?.id && userId && analyst?.id !== userId;
        try {
            await updateStatus({
                alerts: ids,
                statusCode: STATUS_CODE_AMENDMENT,
                comment: form.getFieldValue('comment'),
                controller: user?.id,
                skipUpdateCache: needUpdateAnalyst,
            }).unwrap();
            notification.success({
                ...BASE_NOTIFICATION_CONFIG,
                message: t('forms.alert.amendmentCard.notifications.changeStatus'),
            });
            if (needUpdateAnalyst) {
                try {
                    await updateAnalyst({ alerts: ids, userId }).unwrap();
                    notification.success({
                        ...BASE_NOTIFICATION_CONFIG,
                        message: t('forms.alert.amendmentCard.notifications.contractorChanged'),
                    });
                } catch (error: any) {
                    notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message });
                }
            }
        } catch (error: any) {
            notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message });
        }
        onSuccess();
    };

    return (
        <Spin spinning={isLoading || isUpdatingStatus || isUpdatingAnalyst}>
            <Form
                form={form}
                onFinish={handleSubmit}
                validateMessages={{
                    required: t('validation.required'),
                }}
                layout="vertical">
                <Form.Item name="userId" initialValue={analyst?.id} rules={[{ required: true }]}>
                    <Select
                        placeholder={t('forms.alert.amendmentCard.userPlaceholder')}
                        style={{ width: '100%' }}
                        options={analysts?.map((item) => ({
                            value: item.id,
                            label: getUserName(item),
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    name="comment"
                    label={t('forms.alert.amendmentCard.comment.label')}
                    rules={[{ required: true }, { whitespace: true, message: t('common.disableWhitespace') }]}>
                    <TextArea placeholder={t('forms.alert.amendmentCard.comment.placeholder')} rows={4} />
                </Form.Item>
                <Row justify="end">
                    <Button type="primary" htmlType="submit">
                        {t('common.submit')}
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default AmendmentForm;
