import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';
import { QueryOptions } from 'odata-query';
import { TECH_USER_NAME } from '../../../constants';
import { DATE_FORMAT, TIME_WITH_SECONDS_FORMAT } from '../../../constants/Alert';
import { defaultHistoryQuery, statusFilter, calendarHistoryStatusDesc } from '../../../constants/History';
import { HISTORY_STATUS } from '../../../constants/enums';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { getHistoryFilter } from '../../../helpers/getHistoryFilters';
import getTopSkip from '../../../helpers/getTopSkip';
import { SessionHistory } from '../../../models/Session';
import { useGetSessionHistoryQuery } from '../../../redux/api/calendarApi';

interface HistoryProps {
    intervalCode: string | undefined;
}

const GlobalHistory: FC<HistoryProps> = ({ intervalCode }) => {
    const { t } = useTranslation();
    const dateInputRef = useRef(null);
    const authorInputRef = useRef(null);
    const defaultFilter = {};

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });
    const [filter, setFilter] = useState<QueryOptions<SessionHistory>['filter']>(defaultFilter);

    const queryData = useMemo<Partial<QueryOptions<SessionHistory>>>(
        () => ({
            ...defaultHistoryQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: filter,
        }),
        [pagination, filter],
    );

    const { data: sessionHistory, isLoading, isFetching } = useGetSessionHistoryQuery(queryData);

    const count = sessionHistory?.count;

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<SessionHistory>['onChange'] = (newPagination, filters) => {
        setFilter([defaultFilter, ...getHistoryFilter(filters)]);
        setPagination(newPagination);
    };

    const handleValue = (row: any, inverse: boolean = false) => {
        const isRemove = !inverse ? row.action === HISTORY_STATUS.remove : row.action !== HISTORY_STATUS.remove;
        if (isRemove) {
            return row.prevValue;
        }
        return row.newValue;
    };

    const column: ColumnsType<SessionHistory> = [
        {
            title: t('pages.sessionAdministration.globalHistory.columns.action'),
            dataIndex: 'action',
            key: 'action',
            render: (value) => calendarHistoryStatusDesc[value],
            filters: statusFilter(),
            width: 120,
            ellipsis: true,
        },
        {
            title: t('pages.sessionAdministration.globalHistory.columns.createdDate'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(DATE_FORMAT),
            ...getColumnSearchProps<SessionHistory>(dateInputRef, 'date'),
            width: 100,
            ellipsis: true,
        },
        {
            title: t('pages.sessionAdministration.globalHistory.columns.time'),
            dataIndex: 'createdDate',
            key: 'time',
            render: (value) => moment(value).format(TIME_WITH_SECONDS_FORMAT),
            width: 100,
            ellipsis: true,
        },
        {
            title: t('pages.sessionAdministration.globalHistory.columns.user'),
            dataIndex: 'userId',
            key: 'user',
            render: (value) => (value ? `${value.lastName || ''} ${value.firstName || ''}` : TECH_USER_NAME()),
            ...getColumnSearchProps<SessionHistory>(authorInputRef, 'fio'),
            width: 150,
            ellipsis: true,
        },
        {
            title: t('pages.sessionAdministration.globalHistory.columns.value'),
            key: 'value',
            render: (row) => {
                const value = handleValue(row);
                return value ? value.date : '';
            },
            width: 150,
            ellipsis: true,
        },
        {
            title: t('pages.sessionAdministration.globalHistory.columns.name'),
            dataIndex: ['entryCode', 'name'],
            key: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: t('pages.sessionAdministration.globalHistory.columns.comment'),
            key: 'comment',
            render: (row) => row.prevValue?.comment || row.newValue?.comment || '',
            width: 150,
            ellipsis: true,
        },
    ];

    return (
        <Table<SessionHistory>
            size="small"
            scroll={{ x: 600, y: 600 }}
            rowKey="id"
            bordered
            loading={isLoading || isFetching}
            columns={column}
            dataSource={sessionHistory ? sessionHistory.items : []}
            pagination={pagination}
            onChange={handleTableChange}
        />
    );
};

export default GlobalHistory;
