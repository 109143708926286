import { Modal } from 'antd';
import i18next from 'i18next';

const closeWithoutSaveConfirmModal = (onOk: () => void) => {
    const t = i18next.t;
    Modal.confirm({
        title: t('modal.confirm.withoutSave.title'),
        content: t('modal.confirm.withoutSave.content'),
        onOk: onOk,
        okText: t('modal.confirm.withoutSave.ok'),
        cancelText: t('modal.confirm.withoutSave.cancel'),
        style: { top: '20%' },
    });
};

export default closeWithoutSaveConfirmModal;
