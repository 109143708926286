import React, { FC, useMemo } from 'react';
import { Tabs } from 'antd';
import dayjs from 'dayjs';
import AccountTurnover from './AccountTurnover';
import BankAccounts from './BankAccounts';
import { ClientContext } from './ClientContext';
import OperationParams from './OperationParams';
import OtherAccounts from './OtherAccounts';
import Relations from './Relations';
import { DATE_FORMAT } from '../../../../../constants';
import { blackListColumn, clientAlerts, contractors, otherRnkColumns } from '../../../../../helpers/getBankInfoColumns';
import { ExtendedAlert } from '../../../../../models/Alert';
import { QueryType, useGetLazyQuery } from '../../../hooks/useQuery';
import RisksTab from '../RisksTab';
import TableWrapper from '../TableWrapper';
import { Trans, useTranslation } from 'react-i18next';

interface BankInfoProps {
    model?: ExtendedAlert;
    tableHeight?: number;
}
const BankInfo: FC<BankInfoProps> = ({ model }) => {
    const { t } = useTranslation();
    const id = model?.id;
    const customerId = model?.customer?.customerId;
    const { triggerFetch, data, loading } = useGetLazyQuery();

    const clientInfo = useMemo(
        () => ({
            nationalName: model?.customer?.nationalName,
            customerCode: model?.customer?.customerCode,
            customerId: model?.customer?.customerId,
            analysisFromDate: dayjs(model?.analysisFromDate).format(DATE_FORMAT),
            analysisToDate: dayjs(model?.analysisToDate).format(DATE_FORMAT),
        }),
        [model],
    );

    return (
        <ClientContext.Provider value={clientInfo}>
            <Tabs
                destroyInactiveTabPane
                defaultActiveKey="1"
                size="small"
                type="card"
                items={[
                    {
                        label: t('pages.alerts.components.bankInfo.bankInfo.alertParams'),
                        key: 'alertParams',
                        children: <RisksTab model={model} />,
                    },
                    {
                        label: t('pages.alerts.components.bankInfo.bankInfo.operationParams'),
                        key: 'operationParams',
                        children: (
                            <OperationParams
                                data={data.payments?.transactions}
                                loading={loading.isLoadingTransactions}
                                triggerFetch={() => triggerFetch(QueryType.payments, id)}
                            />
                        ),
                    },
                    {
                        label: t('pages.alerts.components.bankInfo.bankInfo.bankAccounts'),
                        key: 'bankAccounts',
                        children: (
                            <BankAccounts
                                data={data.accounts}
                                loading={loading.isLoadingAccounts}
                                triggerFetch={() => triggerFetch(QueryType.accounts, id)}
                            />
                        ),
                    },
                    {
                        label: t('pages.alerts.components.bankInfo.bankInfo.otherBankAccounts'),
                        key: 'otherBankAccounts',
                        children: (
                            <OtherAccounts
                                data={data.alienAccounts}
                                loading={loading.isLoadingAlienAccounts}
                                triggerFetch={() => triggerFetch(QueryType.alienAccounts, customerId)}
                                queryType={QueryType.alienAccounts}
                                alertStatus={model?.status?.code}
                            />
                        ),
                    },
                    {
                        label: t('pages.alerts.components.bankInfo.bankInfo.accountTurnover'),
                        key: 'accountTurnover',
                        children: (
                            <AccountTurnover
                                data={data.accounts}
                                loading={loading.isLoadingAccounts}
                                triggerFetch={() => triggerFetch(QueryType.accounts, id)}
                            />
                        ),
                    },
                    {
                        label: t('pages.alerts.components.bankInfo.bankInfo.relatedPerson'),
                        key: 'relatedPerson',
                        children: (
                            <Relations
                                data={data.relations}
                                loading={loading.isLoadingRelations}
                                triggerFetch={() => triggerFetch(QueryType.relations, customerId)}
                            />
                        ),
                    },
                    {
                        label: t('pages.alerts.components.bankInfo.bankInfo.generalCounterparty'),
                        key: 'generalCounterparty',
                        children: (
                            <TableWrapper
                                rowKey="id"
                                columns={contractors()}
                                dataSource={data.payments?.counterparties}
                                loading={loading.isLoadingTransactions}
                                triggerFetch={() => triggerFetch(QueryType.payments, id)}
                            />
                        ),
                    },
                    {
                        label: t('pages.alerts.components.bankInfo.bankInfo.clientAlerts'),
                        key: 'clientAlerts',
                        children: (
                            <TableWrapper
                                rowKey="id"
                                columns={clientAlerts()}
                                dataSource={data.alerts}
                                loading={loading.isLoadingAlerts}
                                triggerFetch={() => triggerFetch(QueryType.alerts, id)}
                            />
                        ),
                    },
                    {
                        label: t('pages.alerts.components.bankInfo.bankInfo.otherRnk'),
                        key: 'otherRnk',
                        children: (
                            <TableWrapper
                                rowKey="id"
                                columns={otherRnkColumns()}
                                dataSource={data.otherRnk}
                                loading={loading.isLoadingOtherRnk}
                                triggerFetch={() => triggerFetch(QueryType.otherRnk, id)}
                            />
                        ),
                    },
                    {
                        label: (
                            <div
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                title={t('pages.alerts.components.bankInfo.bankInfo.blockList')}>
                                <Trans i18nKey="pages.alerts.components.bankInfo.bankInfo.blockList" />
                            </div>
                        ),
                        key: 'blackList',
                        children: (
                            <TableWrapper
                                rowKey="id"
                                columns={blackListColumn(t)}
                                dataSource={JSON.parse(model?.blacklist ?? '[]')}
                            />
                        ),
                    },
                ]}
            />
        </ClientContext.Provider>
    );
};

export default BankInfo;
