import { FC, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import RecordDataFields from './RecordDataFields';
import { checkParam18 } from './helpers/checkParam18';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { numberToInputString } from '../../../helpers/numberToInputString';
import { replaceQuotesWithDoubleQuotesInObj } from '../../../helpers/replaceQuotesWithDoubleQuotesInObj';
import { DictionaryModel, FormValuesType, RecordModel } from '../../../models/DictionaryModel';
import { useUpdateRecordInDictionaryMutation } from '../../../redux/api/dictionaryApi';
import { useTranslation } from 'react-i18next';

interface UpdateRecordFormProps {
    onSuccess: () => void;
    dictionary: DictionaryModel;
    setIsEdited: Dispatch<SetStateAction<boolean>>;
    isOpenedUpdateModal: boolean;
    selected: RecordModel | null;
}

const UpdateRecordForm: FC<UpdateRecordFormProps> = ({
    onSuccess,
    dictionary,
    setIsEdited,
    isOpenedUpdateModal,
    selected,
}) => {
    const { t } = useTranslation();
    const [updateRecord, { isLoading: isLoadingUpdate }] = useUpdateRecordInDictionaryMutation();
    const [form] = useForm<FormValuesType>();

    useEffect(() => {
        if (isOpenedUpdateModal) {
            if (selected !== null) {
                form.setFieldsValue({
                    ...selected,
                    ...(selected.fraction !== null
                        ? { value: numberToInputString(selected.value, selected.fraction) }
                        : {}),
                    dict_name: dictionary.name,
                    dict_description: dictionary.description,
                });
            }
            return () => {
                setIsEdited(false);
            };
        }
    }, [dictionary, form, selected, isOpenedUpdateModal, setIsEdited]);

    const handleSubmit = useCallback(
        (values: FormValuesType) => {
            // NOTE: replace comma with dot for correct number input
            if (selected?.fraction) {
                values.value = +values.value.replace(',', '.');
            }
            updateRecord(replaceQuotesWithDoubleQuotesInObj(values))
                .unwrap()
                .then(() => {
                    onSuccess();
                    notification.success({
                        ...BASE_NOTIFICATION_CONFIG,
                        message: t('forms.dictionary.updateRecord.success'),
                    });
                    form.resetFields();
                })
                .catch((error) =>
                    notification.error({
                        ...BASE_NOTIFICATION_CONFIG,
                        message: error.data.message,
                    }),
                );
        },
        [updateRecord, onSuccess, form, selected, t],
    );

    return (
        <>
            <Form<FormValuesType>
                onFieldsChange={() => {
                    setIsEdited(true);
                    if (selected?.param18) checkParam18(form);
                }}
                form={form}
                onFinish={handleSubmit}
                validateMessages={{ required: t('validation.required') }}
                layout="vertical">
                <RecordDataFields
                    fraction={selected?.fraction}
                    isLoading={isLoadingUpdate}
                    dictionary={dictionary}
                    isUpdateForm={true}
                />
            </Form>
        </>
    );
};

export default UpdateRecordForm;
