import { useWatch } from 'antd/es/form/Form';

export const useWatchAllFields = () => {
    const manuallyAddedCriteriaCodes: number[] = useWatch('manuallyCriteria');
    const approvedCriteriaCodes: number[] = useWatch('approvedCriteria');
    const declinedCriteriaCodes: number[] = useWatch('declinedCriteria');
    const additionalCriteriaCodes: number[] = useWatch('additionalCriteria');
    const manuallyAddedIndicatorCodes: number[] = useWatch('manuallyIndicator');
    const approvedIndicatorsCodes: number[] = useWatch('approvedIndicators');
    const declinedIndicatorsCodes: number[] = useWatch('declinedIndicators');

    useWatch('isIndependentActivity');
    useWatch('isAddressMassRegistration');
    useWatch('isClientTrustor');
    useWatch('isClientTrustedPerson');
    useWatch('isClientBeneficiary');
    useWatch('isHeadHeadInOtherCompanies');
    useWatch('isHeadCFOinOtherCompanies');
    useWatch('isCFOHeadInOtherCompanies');
    useWatch('isCFOCFOInOtherCompanies');
    useWatch('isManyVED');
    useWatch('isSendingRequestToCustomer');
    useWatch('isRequestReceived');
    useWatch('isDocumentsProvided');
    useWatch('isCustomerDeclinedProvidingDocuments');

    return {
        manuallyAddedCriteriaCodes,
        approvedCriteriaCodes,
        declinedCriteriaCodes,
        additionalCriteriaCodes,
        manuallyAddedIndicatorCodes,
        approvedIndicatorsCodes,
        declinedIndicatorsCodes,
    };
};
