import { FC, useState } from 'react';
import closeWithoutSaveConfirmModal from '../../../components/common/closeWithoutSaveConfirmModal';
import Modal from '../../../components/common/Modal';
import CreateRecordForm from '../../../components/form/dictionary-form/CreateRecordForm';
import { DictionaryModel } from '../../../models/DictionaryModel';
import { useTranslation } from 'react-i18next';

interface CreateDictionaryModalProps {
    onCancel: () => void;
    onSuccess: () => void;
    dictionary?: DictionaryModel;
    createRecordModal: boolean;
}

const CreateDictionaryModal: FC<CreateDictionaryModalProps> = ({
    onCancel,
    onSuccess,
    dictionary,
    createRecordModal,
}) => {
    const { t } = useTranslation();
    const [isEdited, setIsEdited] = useState(false);

    const confirmModal = () => {
        closeWithoutSaveConfirmModal(() => {
            onCancel();
            setIsEdited(false);
        });
    };

    return (
        <Modal
            destroyOnClose
            title={t('pages.dictionary.modal.createDictionary')}
            onCancel={isEdited ? confirmModal : onCancel}
            open={createRecordModal}
            footer={null}
            bodyStyle={{ paddingTop: '1rem' }}>
            <CreateRecordForm
                dictionary={dictionary}
                onSuccess={onSuccess}
                setIsEdited={setIsEdited}
                createRecordModal={createRecordModal}
            />
        </Modal>
    );
};

export default CreateDictionaryModal;
