import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, DatePicker, Dropdown, Row, Space, Spin, Table, TablePaginationConfig, TableProps } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { QueryOptions } from 'odata-query';
import { CaretDownOutlined, PrinterOutlined, ReloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { dateColumns, getColumns, stringContainsColumns, stringExistColumns } from './components/getColumns';
import Modal from '../../components/common/Modal';
import ReportModal from '../../components/modal/ReportModal';
import { DATE_FORMAT, DATE_FORMAT_FOR_QUERY, INTGR_STATS_TABLE } from '../../constants';
import { ReportTypes } from '../../constants/enums';
import { getSorterQuery } from '../../helpers';
import { getFilters } from '../../helpers/getFilters';
import getTopSkip from '../../helpers/getTopSkip';
import { useHandleDatePickerWithArrows, useModal } from '../../hooks';
import { useHandleError } from '../../hooks/useHandleError';
import { IntgrStat } from '../../models/IntgrStat';
import { useGetSystemDictionaryQuery } from '../../redux/api/dictionaryApi';
import { useGetIntgrStatsQuery } from '../../redux/api/systemApi';
import { useTranslation } from 'react-i18next';

const defaultOrder = 'id desc';

const IntgrStats = () => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const [reportModal, openReportModal, closeReportModal] = useModal();
    const [filter, setFilter] = useState<Record<string, FilterValue | null>>({});
    const [order, setOrder] = useState<QueryOptions<IntgrStat>['orderBy']>(defaultOrder);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem(INTGR_STATS_TABLE);
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);
    const queryData = useMemo<Partial<QueryOptions<IntgrStat>>>(() => {
        const allFilter = getFilters({ filter, stringContainsColumns, stringExistColumns, dateColumns });

        return {
            count: true,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: allFilter,
            orderBy: order,
        };
    }, [filter, pagination, order]);

    const { date, setDate, handlePrevDay, handleNextDay } = useHandleDatePickerWithArrows();
    const { data, error, isLoading, isFetching, refetch } = useGetIntgrStatsQuery({ ...queryData, date });
    const { data: dictKafkaTopics, isLoading: isLoadingDictKafkaTopics } = useGetSystemDictionaryQuery({
        dictionaryName: 'dict_kafka_topics',
    });

    const handleTableChange: TableProps<IntgrStat>['onChange'] = (newPagination, filters, sorter) => {
        setPagination(newPagination);
        setOrder(getSorterQuery(sorter));
        setFilter(filters);
    };

    const items = data?.items;
    const count = data?.count;

    useEffect(() => setPagination((currentPagination) => ({ ...currentPagination, total: count })), [count]);

    useHandleError(error);

    return (
        <Spin spinning={isLoading || isLoadingDictKafkaTopics}>
            <Modal
                onCancel={closeReportModal}
                destroyOnClose
                title={t('pages.intgrStats.report')}
                open={reportModal}>
                <ReportModal
                    onSuccess={closeReportModal}
                    reportType={ReportTypes.intgrStats}
                    filters={filter}
                    processedFilters={getFilters({ filter, stringContainsColumns, stringExistColumns, dateColumns })}
                />
            </Modal>
            <Row justify="space-between" className="marginBottom2">
                <Row gutter={8}>
                    <Col>
                        <Button onClick={handlePrevDay} icon={<LeftOutlined />} />
                    </Col>
                    <Col>
                        <DatePicker
                            value={dayjs(date)}
                            onChange={(date) => setDate(dayjs(date).format(DATE_FORMAT_FOR_QUERY))}
                            format={DATE_FORMAT}
                            allowClear={false}
                        />
                    </Col>
                    <Col>
                        <Button onClick={handleNextDay} icon={<RightOutlined />} />
                    </Col>
                </Row>
                <Row gutter={8} justify="end" className="marginBottom2 alignItemsCenter">
                    <Col>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: '1',
                                        label: t('pages.intgrStats.report'),
                                        onClick: openReportModal,
                                    },
                                ],
                            }}>
                            <Button>
                                <Space>
                                    <PrinterOutlined />
                                    <CaretDownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Col>
                    <Col>
                        <Button icon={<ReloadOutlined />} onClick={refetch} loading={isFetching} />
                    </Col>
                </Row>
            </Row>
            <Table<IntgrStat>
                rowKey="id"
                size="small"
                scroll={{ x: 600 }}
                columns={getColumns(sizes, inputRef, dictKafkaTopics)}
                dataSource={items}
                pagination={pagination}
                onChange={handleTableChange}
                bordered
            />
        </Spin>
    );
};

export default IntgrStats;
