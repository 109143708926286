import { TranslatedEnum } from '../helpers/translatedEnum';
import { ApartmentTypes, BuildingTypes, CorpsTypes, LocalityTypes, StreetTypes } from './enums';

export const systemDictTranslation = {
    ...TranslatedEnum(LocalityTypes),
    ...TranslatedEnum(StreetTypes),
    ...TranslatedEnum(BuildingTypes),
    ...TranslatedEnum(CorpsTypes),
    ...TranslatedEnum(ApartmentTypes),
};
