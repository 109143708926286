import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';
import { QueryOptions } from 'odata-query';
import { LeftOutlined } from '@ant-design/icons';
import HistoryRow from '../../../components/common/HistoryRow';
import { ACTION_TYPE_UA, TECH_USER_NAME } from '../../../constants';
import { DATE_FORMAT, TIME_WITH_SECONDS_FORMAT } from '../../../constants/Alert';
import { defaultHistoryQuery, statusFilter } from '../../../constants/History';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { getHistoryFilter } from '../../../helpers/getHistoryFilters';
import { getHistoryRow } from '../../../helpers/getHistoryValue';
import getTopSkip from '../../../helpers/getTopSkip';
import { useEscape } from '../../../hooks';
import { Schedule, ScheduleHistory } from '../../../models/Schedule';
import { UserModel } from '../../../models/UserModel';
import { useGetSystemDictionaryQuery } from '../../../redux/api/dictionaryApi';
import { useGetScheduleHistoryQuery } from '../../../redux/api/schedulesApi';
import { TranslatedEnum } from '../../../helpers/translatedEnum';
import { useTranslation } from 'react-i18next';

interface SchedulersHistoryProps {
    schedule: Schedule;
    closePage: () => void;
}

const getFullName = (user: UserModel) => {
    if (user && (user.lastName || user.firstName || user.middleName)) {
        return `${user?.lastName ?? ''} ${user?.firstName ? user.firstName[0].toUpperCase() + '.' : ''}`;
    } else {
        return TECH_USER_NAME();
    }
};

const SchedulersHistory: FC<SchedulersHistoryProps> = ({ schedule, closePage }) => {
    const { t } = useTranslation();
    useEscape(closePage);
    const dateInputRef = useRef(null);
    const authorInputRef = useRef(null);
    const defaultFilter = { entryId: schedule.id };
    const { data: descriptionDict, isLoading: isLoadingDict } = useGetSystemDictionaryQuery({
        dictionaryName: 'dict_history_params_description',
    });

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });
    const [filter, setFilter] = useState<QueryOptions<ScheduleHistory>['filter']>(defaultFilter);

    const queryData = useMemo<Partial<QueryOptions<ScheduleHistory>>>(
        () => ({
            ...defaultHistoryQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: filter,
        }),
        [filter, pagination],
    );

    const { data: userHistory, isLoading } = useGetScheduleHistoryQuery({ id: schedule.id, queryData });

    const count = userHistory?.count;

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<ScheduleHistory>['onChange'] = (newPagination, filters) => {
        setFilter([defaultFilter, ...getHistoryFilter(filters)]);
        setPagination(newPagination);
    };

    const parsedHistory = useMemo(
        () =>
            userHistory?.items.map((history) => ({
                ...history,
                newValue: JSON.parse(history.newValue as string),
                prevValue: JSON.parse(history.prevValue as string),
            })),
        [userHistory],
    );

    const column: ColumnsType<ScheduleHistory> = [
        {
            title: t('common.history.columns.action'),
            dataIndex: 'action',
            key: 'action',
            filters: statusFilter(),
            render: (value) => TranslatedEnum(ACTION_TYPE_UA)[value as keyof typeof ACTION_TYPE_UA],
            width: 70,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.createdDate'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(DATE_FORMAT),
            ...getColumnSearchProps<ScheduleHistory>(dateInputRef, 'date'),
            width: 85,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.time'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(TIME_WITH_SECONDS_FORMAT),
            width: 70,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.user'),
            dataIndex: 'userId',
            key: 'user',
            render: (value) => getFullName(value),
            ...getColumnSearchProps<ScheduleHistory>(authorInputRef, 'fio'),
            width: 125,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.param'),
            dataIndex: 'newValue',
            key: 'params',
            render: (value) =>
                Object.keys(value).map((key, index) => (
                    <HistoryRow value={descriptionDict?.find((item) => item.code === key)?.description} key={index} />
                )),
            width: 100,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.prevValue'),
            dataIndex: 'prevValue',
            key: 'prevValue',
            render: (value) => getHistoryRow(value),
            width: 250,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.newValue'),
            dataIndex: 'newValue',
            key: 'newValue',
            render: (value) => getHistoryRow(value),
            width: 250,
            ellipsis: true,
        },
    ];

    return (
        <>
            <Row>
                <div onClick={closePage} className={'user-history__title'}>
                    <LeftOutlined />
                    {t('pages.schedulers.history', { description: schedule?.description })}
                </div>
            </Row>
            <Table<ScheduleHistory>
                scroll={{ y: '61vh' }}
                size="small"
                rowKey="id"
                bordered
                loading={isLoading || isLoadingDict}
                columns={column}
                dataSource={parsedHistory}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default SchedulersHistory;
