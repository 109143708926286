import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Form, Input, notification, Row, Spin } from 'antd';
import { RouteCode } from '../constants/enums';
import { LoginRequestPayload, useLoginUserMutation } from '../redux/api/authApi';
import { getChangePasswordData, getToken, getUser } from '../redux/features/authSlice';
import { useAppSelector } from '../redux/hooks';
import { useTranslation } from 'react-i18next';

const Login: FC = () => {
    const { t } = useTranslation();
    const token = useAppSelector(getToken);
    const user = useAppSelector(getUser);
    const changePasswordData = useAppSelector(getChangePasswordData);
    const navigate = useNavigate();
    const [loginUser, { isLoading, error }] = useLoginUserMutation();

    const onFinish = (values: LoginRequestPayload) => {
        loginUser(values);
    };

    useEffect(() => {
        if (token && user) {
            if (user.menuFunctions?.length) {
                if (user.menuFunctions.some((menuFunc) => menuFunc.path === RouteCode.alerts))
                    navigate(`/${RouteCode.alerts}`);
                else navigate(`/${RouteCode.users}`);
            } else {
                notification.error({
                    message: t('pages.login.failed.message'),
                    description: t('pages.login.failed.description'),
                });
            }
        }
    }, [token, navigate, user, t]);

    useEffect(() => {
        if (changePasswordData) {
            navigate(`/${RouteCode.changePassword}`);
        }
    }, [changePasswordData, navigate]);

    useEffect(() => {
        if (error && 'data' in error) {
            notification.error({
                message: (error as any).originalStatus === 502 ? (error.data as any) : (error.data as any).message,
            });
        }
    }, [error]);

    return (
        <div className="login__form">
            <Spin spinning={isLoading}>
                <Card title={<img src={'logo_big.svg'} width={238} alt="logo" />}>
                    <Form<LoginRequestPayload>
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="off">
                        <Form.Item
                            label={t('pages.login.username')}
                            name="email"
                            rules={[{ required: true, message: t('validation.required') }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t('pages.login.password')}
                            name="password"
                            rules={[{ required: true, message: t('validation.required') }]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Row justify={'center'} style={{ marginTop: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    {t('pages.login.submit')}
                                </Button>
                            </Row>
                        </Form.Item>
                    </Form>
                </Card>
            </Spin>
        </div>
    );
};

export default Login;
