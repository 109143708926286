import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import CreateForm from '../../../components/form/calendar-form/CreateForm';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { ReqChangeIntervalDate, useChangeIntervalDateMutation } from '../../../redux/api/calendarApi';
import { useGetSystemDictionaryQuery } from '../../../redux/api/dictionaryApi';

interface CreateSessionProps {
    onSuccess: () => void;
}
const CreateSession: FC<CreateSessionProps> = ({ onSuccess }) => {
    const { t } = useTranslation();
    const {
        data: dictionary,
        isLoading: isLoadingDict,
        isFetching: isFetchingDict,
    } = useGetSystemDictionaryQuery({ dictionaryName: 'dict_rule_interval' });
    const [createSession, { isLoading: processing }] = useChangeIntervalDateMutation();

    const create = useCallback(
        (data: ReqChangeIntervalDate) => {
            createSession(data)
                .unwrap()
                .then(() => {
                    notification.success({
                        ...BASE_NOTIFICATION_CONFIG,
                        message: t('pages.sessionAdministration.createSession.notifications.create'),
                    });
                    onSuccess();
                })
                .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
        },
        [createSession, onSuccess, t],
    );

    return (
        <CreateForm dictionary={dictionary} onSubmit={create} loading={isLoadingDict || isFetchingDict || processing} />
    );
};

export default CreateSession;
