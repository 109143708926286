import { FC, useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Select, Spin } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import dayjs, { Dayjs } from 'dayjs';
import buildQuery, { Filter } from 'odata-query';
import { DATE_FORMAT } from '../../constants/Alert';
import { DATE_FORMAT_FOR_QUERY } from '../../constants/Alert';
import { ReportTypes } from '../../constants/enums';
import { reportFetch } from '../../helpers/reportFetch';
import { useBoolean } from '../../hooks';
import { UserModel } from '../../models/UserModel';
import { useLazyGetUsersQuery } from '../../redux/api/userApi';
import { getToken, getUser } from '../../redux/features/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';

interface ReportModalProps {
    onSuccess: () => void;
    reportType: ReportTypes;
    filters?: Record<string, FilterValue | null>;
    defaultFilters?: Record<string, FilterValue | null>;
    processedFilters?: Filter;
}

interface ReportForm {
    dateRange: Dayjs[];
    entities: string[];
    uids: number[];
}

const usersOptions = (users: UserModel[]) => {
    return users.map((user) => ({
        value: `${user.id}`,
        label: `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`,
    }));
};

const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const ReportModal: FC<ReportModalProps> = ({
    onSuccess,
    reportType,
    filters,
    defaultFilters = {},
    processedFilters = [],
}) => {
    const { t } = useTranslation();
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const user = useAppSelector(getUser);
    const token = useAppSelector(getToken);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [enableFilters, _setTrueEnableFilters, _setFalseEnableFilters, toggleEnableFilters] = useBoolean(
        JSON.stringify(filters) !== JSON.stringify(defaultFilters),
    );

    const BlockTranslation = {
        alerts: t('modal.report.block.alerts'),
        users: t('modal.report.block.users'),
        criterions: t('modal.report.block.criterions'),
        indicators: t('modal.report.block.indicators'),
        dictionaries: t('modal.report.block.dictionaries'),
    };

    const blockOptions = Object.keys(BlockTranslation).map((block) => ({
        value: block,
        label: BlockTranslation[block as keyof typeof BlockTranslation],
    }));

    const isReportWithFilters = [
        ReportTypes.alertRegister,
        ReportTypes.sessionRegister,
        ReportTypes.systemLog,
        ReportTypes.actionsLog,
    ].includes(reportType);

    const [getUsers, { data: users, isLoading: isUsersLoading, isFetching: isUsersFetching }] = useLazyGetUsersQuery();

    const handleSubmit = async (values: ReportForm) => {
        const dateRange = values.dateRange.map((date) => date.format(DATE_FORMAT_FOR_QUERY));
        let query = 'startDate=' + dateRange[0] + '&endDate=' + dateRange[1];
        if (reportType === ReportTypes.activity) {
            if (values.entities) query = query + '&entities=' + JSON.stringify(values.entities);
            if (values.uids) query = query + '&uids=' + JSON.stringify(values.uids);
        }
        if (enableFilters && isReportWithFilters && processedFilters.length > 0) {
            const odataQuery = buildQuery({ filter: processedFilters });
            query += '&' + odataQuery.substring(1);
        }
        setIsLoadingReport(true);
        await reportFetch(reportType, null, query, onSuccess, null, token, user?.id);
        setIsLoadingReport(false);
    };

    return (
        <Spin spinning={isLoadingReport}>
            <Form<ReportForm>
                onFinish={handleSubmit}
                validateMessages={{
                    required: t('validation.required'),
                }}
                layout="vertical">
                <Form.Item name="dateRange" rules={[{ required: true }]}>
                    <DatePicker.RangePicker
                        className={'wWide'}
                        placeholder={[t('common.dateFrom'), t('common.dateTo')]}
                        format={DATE_FORMAT}
                        disabledDate={(current) => {
                            return (
                                current &&
                                (current > dayjs().endOf('day') ||
                                    current < dayjs().startOf('day').subtract(3, 'months'))
                            );
                        }}
                    />
                </Form.Item>
                {reportType === ReportTypes.activity && (
                    <>
                        <Form.Item label={t('modal.report.entities.label')} name="entities">
                            <Select
                                mode="multiple"
                                placeholder={t('modal.report.entities.placeholder')}
                                style={{ width: '100%' }}
                                options={blockOptions}
                                filterOption={filterOption}
                            />
                        </Form.Item>
                        <Form.Item label={t('modal.report.uids.label')} name="uids">
                            <Select
                                mode="multiple"
                                placeholder={t('modal.report.uids.placeholder')}
                                style={{ width: '100%' }}
                                options={usersOptions(users?.items || [])}
                                onClick={() =>
                                    getUsers({
                                        count: true,
                                        select: 'id,firstName,lastName,middleName',
                                        orderBy: 'lastName asc',
                                    })
                                }
                                loading={isUsersLoading || isUsersFetching}
                                filterOption={filterOption}
                            />
                        </Form.Item>
                    </>
                )}
                <Form.Item style={{ textAlign: 'center' }}>
                    {isReportWithFilters && (
                        <Checkbox onChange={toggleEnableFilters} checked={enableFilters} className="report-checkbox">
                            {t('modal.report.reportCheckbox')}
                        </Checkbox>
                    )}
                    <Button type="primary" htmlType="submit">
                        {t('common.download')}
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default ReportModal;
