import { FC, useCallback } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';

interface CommentFormProps {
    onSubmit: (comment: string) => void;
    closeModal: () => void;
}

const CommentForm: FC<CommentFormProps> = ({ onSubmit, closeModal }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handleSubmit = useCallback(
        (values: any) => {
            onSubmit(values.comment);
            closeModal();
        },
        [onSubmit, closeModal],
    );
    return (
        <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Form.Item
                        label={t('forms.calendar.label')}
                        name="comment"
                        rules={[{ required: true }, { whitespace: true, message: t('common.disableWhitespace') }]}
                        className="marginBottom4">
                        <Input placeholder={t('forms.calendar.placeholder')} className="wWide" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            {t('forms.calendar.submit')}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export default CommentForm;
