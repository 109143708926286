import React, { useCallback } from 'react';

import { Row, Col, DatePicker, Input, Button, Form, Spin } from 'antd';
import dayjs from 'dayjs';

import { DATE_FORMAT } from '../../../constants/Alert';
import { useTranslation } from 'react-i18next';

interface DeleteFormProps {
    date: string;
    onSubmit: (coment: string) => void;
    loading: boolean;
}

const DeleteForm = ({ date, onSubmit, loading }: DeleteFormProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handleSubmit = useCallback(
        (values: any) => {
            onSubmit(values.comment);
        },
        [onSubmit],
    );

    return (
        <Spin spinning={loading}>
            <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ date: dayjs(date) }}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Form.Item label={t('forms.calendar.delete.date.label')} name="date" rules={[{ required: true }]}>
                            <DatePicker disabled className="wWide" format={DATE_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t('forms.calendar.delete.comment.label')}
                            name="comment"
                            rules={[{ required: true }, { whitespace: true, message: t('common.disableWhitespace') }]}
                            className="marginBottom4">
                            <Input placeholder={t('forms.calendar.delete.comment.placeholder')} className="wWide" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Button type="primary" htmlType="submit">
                                {t('forms.calendar.delete.submit')}
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default DeleteForm;
