import { FC, useCallback, useMemo } from 'react';
import { Spin, Table, TableProps } from 'antd';
import ExpandedRow from './ExpandedRow';
import { RULES_TYPE_CRITERION, RULES_TYPE_INDICATOR } from '../../../../constants/Rules';
import { usePagination } from '../../../../hooks';
import { ExtendedAlert, Rule } from '../../../../models/Alert';
import { useLazyGetAccountsParamsQuery, useLazyGetTransactionsParamsQuery } from '../../../../redux/api/alertsApi';
import { useGetSystemDictionaryQuery } from '../../../../redux/api/dictionaryApi';
import { riskColumns, indicatorsColumns } from '../../helpers/getColumns';

interface RisksInfoProps {
    model?: ExtendedAlert;
}

const RisksTab: FC<RisksInfoProps> = ({ model }) => {
    const {
        data: dict,
        isLoading: isLoadingDict,
        isFetching: isFetchingDict,
    } = useGetSystemDictionaryQuery({ dictionaryName: 'dict_rule_options_descriptions' });

    const rules = model?.rules?.filter((item) => item.rule?.type?.code === RULES_TYPE_CRITERION && item.isAuto === 1);

    const indicators = model?.rules?.filter(
        (item) => item.rule?.type?.code === RULES_TYPE_INDICATOR && item.isAuto === 1,
    );
    const { pagination: transactionsPagination, handleTableChange: handleTransactionsTableChange } = usePagination();
    const { pagination: accountsPagination, handleTableChange: handleAccountsTableChange } = usePagination();
    const [getTransactionsParams, { data: transactionsData }] = useLazyGetTransactionsParamsQuery();
    const [getAccountsParams, { data: accountsData }] = useLazyGetAccountsParamsQuery();

    const handleExpand = useCallback(
        async (expanded: boolean, record: Rule) => {
            if (expanded) {
                await getTransactionsParams({ ruleId: record.id, pagination: transactionsPagination }, true);
                await getAccountsParams({ ruleId: record.id, pagination: accountsPagination }, true);
            }
        },
        [accountsPagination, getAccountsParams, getTransactionsParams, transactionsPagination],
    );

    const expandable: TableProps<Rule>['expandable'] = useMemo(
        () => ({
            expandedRowRender: (record) => (
                <ExpandedRow
                    accountsData={accountsData}
                    accountsPagination={accountsPagination}
                    transactionsData={transactionsData}
                    transactionsPagination={transactionsPagination}
                    dict={dict}
                    handleAccountsTableChange={handleAccountsTableChange}
                    handleTransactionsTableChange={handleTransactionsTableChange}
                    record={record}
                    getTransactionsParams={getTransactionsParams}
                    getAccountsParams={getAccountsParams}
                />
            ),
            expandedRowClassName: () => 'rowWrapper',
            rowExpandable: (record: Rule) =>
                !!(
                    (record.customerParams && Object.keys(record.customerParams).length) ||
                    record.transactionsParams?.length ||
                    record.accountsParams?.length ||
                    record.relationsParams?.length
                ),
            onExpand: handleExpand,
        }),
        [
            accountsData,
            accountsPagination,
            dict,
            getAccountsParams,
            getTransactionsParams,
            handleAccountsTableChange,
            handleExpand,
            handleTransactionsTableChange,
            transactionsData,
            transactionsPagination,
        ],
    );

    return (
        <Spin spinning={isLoadingDict || isFetchingDict}>
            {model?.rules && (
                <Table<Rule>
                    className="marginBottom2"
                    rowKey="id"
                    columns={riskColumns()}
                    expandable={expandable}
                    dataSource={rules}
                    pagination={false}
                    size="small"
                    bordered
                />
            )}
            {model?.rules && (
                <Table<Rule>
                    rowKey="id"
                    columns={indicatorsColumns()}
                    expandable={expandable}
                    dataSource={indicators}
                    pagination={false}
                    size="small"
                    bordered
                />
            )}
        </Spin>
    );
};

export default RisksTab;
