import React, { useCallback } from 'react';
import { Row, Col, DatePicker, Button, Form, Spin, Checkbox, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/uk';
import { DATE_FORMAT, DATE_FORMAT_FOR_QUERY } from '../../../constants/Alert';
import { SESSION_INTERVAL_TRANSLATE } from '../../../constants/enums';
import { getPeriodValue } from '../../../helpers/calendar';
import { ReqChangeIntervalDate } from '../../../redux/api/calendarApi';
import { useTranslation } from 'react-i18next';
import { TranslatedEnum } from '../../../helpers/translatedEnum';

interface UpdateFormProps {
    date: string;
    onSubmit: (data: ReqChangeIntervalDate) => void;
    intervalId: number;
    loading: boolean;
    intervalCode: string;
}

const UpdateForm = ({ date, intervalId, onSubmit, loading, intervalCode }: UpdateFormProps) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const newDate = Form.useWatch('newDate', form);
    const scheduleDate = dayjs(date);
    const periodValue = getPeriodValue(scheduleDate, intervalCode, i18n.language);
    const handleSubmit = useCallback(
        (values: any) => {
            onSubmit({
                fromDate: date,
                toDate: values.newDate.format(DATE_FORMAT_FOR_QUERY),
                interval: intervalId,
                multiple: values.multiple,
                comment: values.comment,
            });
        },
        [date, intervalId, onSubmit],
    );

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{ date: scheduleDate, newDate: scheduleDate }}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Form.Item label={t('forms.calendar.update.date.label')} name="date" rules={[{ required: true }]}>
                            <DatePicker disabled className="wWide" format={DATE_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={t('forms.calendar.update.newDate.label')} name="newDate" rules={[{ required: true }]}>
                            <DatePicker
                                disabledDate={(date: Dayjs) => getPeriodValue(date, intervalCode, i18n.language) !== periodValue}
                                placeholder={t('common.datePlaceholder')}
                                className="wWide"
                                format={DATE_FORMAT}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('forms.calendar.update.comment.label')}
                            name="comment"
                            rules={[{ required: true }, { whitespace: true, message: t('common.disableWhitespace') }]}
                            className="marginBottom4">
                            <Input placeholder={t('forms.calendar.update.comment.placeholder')} className="wWide" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="multiple" valuePropName="checked">
                            <Checkbox>
                                {t('forms.calendar.update.multiple', { interval: TranslatedEnum(SESSION_INTERVAL_TRANSLATE)[intervalCode as keyof typeof SESSION_INTERVAL_TRANSLATE] })}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={date === newDate.format(DATE_FORMAT_FOR_QUERY)}>
                                {t('forms.calendar.update.submit')}
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default UpdateForm;
