import { NotificationPlacement } from './enums';
import { translatedEnumValueUse } from '../helpers/translatedEnum';

export const BOOLEAN = 'boolean';
export const NUMBER = 'number';
export const STRING = 'string';
export const DICTIONARY = 'dictionary';

export const COLOR_GREEN = 'green';
export const COLOR_RED = 'red';
export const COLOR_PURPLE = 'purple';
export const COLOR_ORANGE = 'orange';
export const COLOR_BLUE = 'blue';
export const COLOR_DEFAULT = 'default';

export const BASE_NOTIFICATION_CONFIG = {
    placement: NotificationPlacement.TOP,
    duration: 2,
};

export const BASE_PAGINATION_CONFIG_20 = {
    current: 1,
    pageSize: 20,
    showSizeChanger: true,
};

export enum ACTION_TYPE_UA {
    insert = 'enums.common.actionType.insert',
    update = 'enums.common.actionType.update',
    delete = 'enums.common.actionType.delete',
    run = 'enums.common.actionType.run',
}

export const TECH_USER_NAME = translatedEnumValueUse((t) => process.env.REACT_TECH_USER_NAME || t('common.techUser'));
