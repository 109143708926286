import { Button, Row, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { HistoryOutlined } from '@ant-design/icons';
import { ROLE_PROPERTY_NAME, activeStatusFilter, rolesFilter } from '../../../constants';
import { Roles, Colors } from '../../../constants/enums';
import { sortArrayOfObjectsByProperty, activeUserCheck } from '../../../helpers';
import { RoleModel } from '../../../models/RoleModel';
import { HandleHistoryClick, LockedDate, UserModel } from '../../../models/UserModel';
import { TFunction } from 'i18next';

const getRoleTags = (roles: RoleModel[]) => (
    <>
        {sortArrayOfObjectsByProperty(roles, ROLE_PROPERTY_NAME).map((role) => (
            <Tag
                key={role.description}
                className="user-roles__tag"
                color={(() => {
                    switch (role.name) {
                        case Roles.admin:
                            return Colors.red;
                        case Roles.analyst:
                            return Colors.green;
                        default:
                            return Colors.orange;
                    }
                })()}>
                {role.description}
            </Tag>
        ))}
    </>
);

const getStatusTag = (lockedDate: LockedDate) => (
    <Tag className="user-is_active" color={lockedDate ? Colors.red : Colors.green}>
        {activeUserCheck(lockedDate)}
    </Tag>
);

const getColumns = (handleHistoryClick: HandleHistoryClick, t: TFunction): ColumnsType<UserModel> => [
    {
        title: t('pages.users.columns.lastName'),
        dataIndex: 'lastName',
        key: 'lastName',
        render: (value, record) => `${value || ''} ${record.firstName} ${record.middleName || ''}`,
        width: '160px',
        ellipsis: true,
        sorter: true,
    },
    {
        title: t('pages.users.columns.email'),
        dataIndex: 'email',
        key: 'email',
        width: '240px',
        sorter: true,
    },
    {
        title: t('pages.users.columns.phone'),
        dataIndex: 'phone',
        key: 'phone',
        width: '100px',
        sorter: true,
    },
    {
        title: t('pages.users.columns.roles'),
        dataIndex: 'roles',
        key: 'roles',
        render: (roles: RoleModel[]) => getRoleTags(roles),
        ellipsis: true,
        width: '200px',
        filters: rolesFilter(),
    },
    {
        title: t('pages.users.columns.lockedDate'),
        dataIndex: 'lockedDate',
        key: 'lockedDate',
        width: '80px',
        render: (lockedDate: LockedDate) => <Row justify={'center'}>{getStatusTag(lockedDate)}</Row>,
        filters: activeStatusFilter(),
        sorter: true,
    },
    {
        title: t('pages.users.columns.id'),
        dataIndex: 'id',
        key: 'id',
        width: '30px',
        render: (id) => (
            <Row justify={'center'}>
                <Button size="small" icon={<HistoryOutlined />} onClick={(e) => handleHistoryClick(id, e)}></Button>{' '}
            </Row>
        ),
    },
];

export default getColumns;
