export const ROLE_ADMIN = 'admin';
export const ROLE_CONTROLLER = 'controller';
export const ROLE_CONTROLLER_1ST = 'controller_1st';
export const ROLE_CONTROLLER_2ND = 'controller_2nd';
export const ROLE_ANALYST = 'analyst';

export enum Roles {
    ADMIN = 'admin',
    CONTROLLER = 'controller',
    ANALYST = 'analyst',
}

export const ROLE_PROPERTY_NAME = 'name';
