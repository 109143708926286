import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { getResizableColumn } from './getResizableColumn';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import {
    RULE_TRANSACTION_SIZE,
    DATE_FORMAT,
    TIME_FORMAT,
    RULE_ACCOUNT_SIZE,
    RULE_RELATION_SIZE,
} from '../../../constants';
import { formatNumberToFinance, convertToCurrency } from '../../../helpers/convertCurrency';
import { TableSettings } from '../../../helpers/getTableSizeFromStore';
import { Accounts, RelationParams, Transaction } from '../../../models/Alert';
import { Trans } from 'react-i18next';
import { translatedEnumValueUse } from '../../../helpers/translatedEnum';
import { TFunction } from 'i18next';

export const riskColumns = translatedEnumValueUse((t) => [
    { key: 'code', title: t('pages.alerts.helpers.riskColumns.code'), dataIndex: ['rule', 'code'], width: 220 },
    { key: 'name', title: t('pages.alerts.helpers.riskColumns.name'), dataIndex: ['rule', 'description'] },
    { key: 'score', title: t('pages.alerts.helpers.riskColumns.score'), dataIndex: 'ruleScore' },
]);

export const indicatorsColumns = translatedEnumValueUse((t) => [
    { key: 'code', title: t('pages.alerts.helpers.indicatorsColumns.code'), dataIndex: ['rule', 'code'], width: 220 },
    { key: 'name', title: t('pages.alerts.helpers.indicatorsColumns.name'), dataIndex: ['rule', 'description'] },
]);

export const nestedRiskColumns = translatedEnumValueUse((t) => [
    { key: 'param', title: t('pages.alerts.helpers.nestedRiskColumns.param'), dataIndex: 'name' },
    { key: 'value', title: t('pages.alerts.helpers.nestedRiskColumns.value'), dataIndex: 'value' },
]);

const toUnderscoreCase = (str: string) => {
    return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const getTransactionColumns = (size?: TableSettings, isOldParams: boolean = true): ColumnsType<Transaction> => {
    const result = [
        {
            key: 'documentId',
            title: (
                <ResizableColumn colIndex={0} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.documentId" />
                </ResizableColumn>
            ),
            dataIndex: 'documentId',
            ellipsis: true,
            width: size?.[0] || 106,
        },
        {
            key: 'documentDate',
            title: (
                <ResizableColumn colIndex={1} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.documentDate" />
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'documentDate',
            ellipsis: true,
            width: size?.[1] || 80,
        },
        {
            key: 'amount',
            title: (
                <ResizableColumn colIndex={2} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.amount" />
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(value),
            dataIndex: 'amount',
            ellipsis: true,
            width: size?.[2] || 106,
        },
        {
            key: 'currencyCode',
            title: (
                <ResizableColumn colIndex={3} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.currencyCode" />
                </ResizableColumn>
            ),
            dataIndex: 'currencyCode',
            ellipsis: true,
            width: size?.[3] || 40,
        },
        {
            key: 'amountEquivalent',
            title: (
                <ResizableColumn colIndex={4} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.amountEquivalent" />
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(value),
            dataIndex: 'amountEquivalent',
            ellipsis: true,
            width: size?.[4] || 106,
        },
        {
            key: 'senderIban',
            title: (
                <ResizableColumn colIndex={5} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.senderIban" />
                </ResizableColumn>
            ),
            dataIndex: 'senderIban',
            ellipsis: true,
            width: size?.[5] || 140,
        },
        {
            key: 'senderBankCode',
            title: (
                <ResizableColumn colIndex={6} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.senderBankCode" />
                </ResizableColumn>
            ),
            dataIndex: 'senderBankCode',
            ellipsis: true,
            width: size?.[6] || 56,
        },
        {
            key: 'senderBankName',
            title: (
                <ResizableColumn colIndex={7} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.senderBankName" />
                </ResizableColumn>
            ),
            dataIndex: 'senderBankName',
            ellipsis: true,
            width: size?.[7] || 106,
        },
        {
            key: 'senderName',
            title: (
                <ResizableColumn colIndex={8} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.senderName" />
                </ResizableColumn>
            ),
            dataIndex: 'senderName',
            ellipsis: true,
            width: size?.[8] || 106,
        },
        {
            key: 'senderTaxCode',
            title: (
                <ResizableColumn colIndex={9} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.senderTaxCode" />
                </ResizableColumn>
            ),
            dataIndex: 'senderTaxCode',
            ellipsis: true,
            width: size?.[9] || 80,
        },
        {
            key: 'recipientIban',
            title: (
                <ResizableColumn colIndex={10} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.recipientIban" />
                </ResizableColumn>
            ),
            dataIndex: 'recipientIban',
            ellipsis: true,
            width: size?.[10] || 146,
        },
        {
            key: 'recipientBankCode',
            title: (
                <ResizableColumn colIndex={11} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.recipientBankCode" />
                </ResizableColumn>
            ),
            dataIndex: 'recipientBankCode',
            ellipsis: true,
            width: size?.[11] || 60,
        },
        {
            key: 'recipientBankName',
            title: (
                <ResizableColumn colIndex={12} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.recipientBankName" />
                </ResizableColumn>
            ),
            dataIndex: 'recipientBankName',
            ellipsis: true,
            width: size?.[12] || 106,
        },
        {
            key: 'recipientName',
            title: (
                <ResizableColumn colIndex={13} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.recipientName" />
                </ResizableColumn>
            ),
            dataIndex: 'recipientName',
            ellipsis: true,
            width: size?.[13] || 106,
        },
        {
            key: 'recipientTaxCode',
            title: (
                <ResizableColumn colIndex={14} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.recipientTaxCode" />
                </ResizableColumn>
            ),
            dataIndex: 'recipientTaxCode',
            ellipsis: true,
            width: size?.[14] || 80,
        },
        {
            key: 'purpose',
            title: (
                <ResizableColumn colIndex={15} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.purpose" />
                </ResizableColumn>
            ),
            dataIndex: 'purpose',
            ellipsis: true,
            width: size?.[15] || 106,
        },
        {
            key: 'paymentDate',
            title: (
                <ResizableColumn colIndex={16} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.paymentDate" />
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'paymentDate',
            ellipsis: true,
            width: size?.[16] || 80,
        },
        {
            key: 'paymentTime',
            title: (
                <ResizableColumn colIndex={17} storageKey={RULE_TRANSACTION_SIZE}>
                    <Trans i18nKey="pages.alerts.helpers.transactionColumns.paymentTime" />
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(TIME_FORMAT) : ''),
            dataIndex: 'paymentTime',
            ellipsis: true,
            width: size?.[17] || 46,
        },
    ];

    if (isOldParams) {
        result.forEach((column) => {
            column.key = toUnderscoreCase(column.key);
            column.dataIndex = toUnderscoreCase(column.dataIndex);
        });
    }

    return result;
};

export const getAccountsColumns = (size?: TableSettings, isOldParams: boolean = true): ColumnsType<Accounts> => {
    const result = [
        {
            key: 'branchName',
            title: (
                <ResizableColumn colIndex={0} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="common.branch" />
                </ResizableColumn>
            ),
            dataIndex: 'branchName',
            ellipsis: true,
            width: size?.[0] || 106,
        },
        {
            key: 'bankCode',
            title: (
                <ResizableColumn colIndex={1} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="common.bankCode" />
                </ResizableColumn>
            ),
            dataIndex: 'bankCode',
            ellipsis: true,
            width: size?.[1] || 56,
        },
        {
            key: 'bankName',
            title: (
                <ResizableColumn colIndex={2} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="common.bankName" />
                </ResizableColumn>
            ),
            dataIndex: 'bankName',
            ellipsis: true,
            width: size?.[2] || 106,
        },
        {
            key: 'accountNumber',
            title: (
                <ResizableColumn colIndex={3} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="common.accountIban" />
                </ResizableColumn>
            ),
            dataIndex: 'accountNumber',
            ellipsis: true,
            width: size?.[3] || 106,
        },
        {
            key: 'accountType',
            title: (
                <ResizableColumn colIndex={4} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.accountType" />
                </ResizableColumn>
            ),
            dataIndex: 'accountType',
            ellipsis: true,
            width: size?.[4] || 40,
        },
        {
            key: 'currency',
            title: (
                <ResizableColumn colIndex={5} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="common.accountCurrencyId" />
                </ResizableColumn>
            ),
            dataIndex: 'currency',
            ellipsis: true,
            width: size?.[5] || 36,
        },
        {
            key: 'customerId',
            title: (
                <ResizableColumn colIndex={6} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="common.customerId" />
                </ResizableColumn>
            ),
            dataIndex: 'customerId',
            ellipsis: true,
            width: size?.[6] || 106,
        },
        {
            key: 'accountName',
            title: (
                <ResizableColumn colIndex={7} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="common.accountName" />
                </ResizableColumn>
            ),
            dataIndex: 'accountName',
            ellipsis: true,
            width: size?.[7] || 106,
        },
        {
            key: 'iban',
            title: (
                <ResizableColumn colIndex={8} storageKey={RULE_ACCOUNT_SIZE}>
                    IBAN
                </ResizableColumn>
            ),
            dataIndex: 'iban',
            ellipsis: true,
            width: size?.[8] || 140,
        },
        {
            key: 'openDate',
            title: (
                <ResizableColumn colIndex={9} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.openDate" />
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'openDate',
            ellipsis: true,
            width: size?.[9] || 82,
        },
        {
            key: 'accountBalance',
            title: (
                <ResizableColumn colIndex={10} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.accountBalance" />
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'accountBalance',
            ellipsis: true,
            width: size?.[10] || 106,
        },
        {
            key: 'debetTurns',
            title: (
                <ResizableColumn colIndex={11} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="common.turnDebit" />
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'debetTurns',
            ellipsis: true,
            width: size?.[11] || 106,
        },
        {
            key: 'creditTurns',
            title: (
                <ResizableColumn colIndex={12} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="common.turnCredit" />
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'creditTurns',
            ellipsis: true,
            width: size?.[12] || 106,
        },
        {
            key: 'residual',
            title: (
                <ResizableColumn colIndex={13} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.actualBalance" />
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'residual',
            ellipsis: true,
            width: size?.[13] || 106,
        },
        {
            key: 'planResidual',
            title: (
                <ResizableColumn colIndex={14} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.planActualBalance" />
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'planResidual',
            ellipsis: true,
            width: size?.[14] || 106,
        },
        {
            key: 'closeDate',
            title: (
                <ResizableColumn colIndex={15} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.closeDate" />
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'closeDate',
            ellipsis: true,
            width: size?.[15] || 82,
        },
        {
            key: 'balanceDate',
            title: (
                <ResizableColumn colIndex={16} storageKey={RULE_ACCOUNT_SIZE}>
                    <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.lastTurnDate" />
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'balanceDate',
            ellipsis: true,
            width: size?.[16] || 82,
        },
    ];

    if (isOldParams) {
        result.forEach((column) => {
            column.key = toUnderscoreCase(column.key);
            column.dataIndex = toUnderscoreCase(column.dataIndex);
        });
    }

    return result;
};

export const getRelationsColumns = (t: TFunction, size?: TableSettings, rowData?: RelationParams): ColumnsType<RelationParams> => {
    const rowDataKeys = rowData
        ? Object.keys(rowData).map((key) => rowData[key as keyof RelationParams] !== 'empty' && key)
        : [];
    const possibleColumns: ColumnsType<RelationParams> = [
        {
            key: 'reft_number',
            title: t('pages.alerts.helpers.relationsColumns.reft_number'),
            dataIndex: 'reft_number',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'client_name',
            title: t('pages.alerts.helpers.relationsColumns.client_name'),
            dataIndex: 'client_name',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'client_code',
            title: t('pages.alerts.helpers.relationsColumns.client_code'),
            dataIndex: 'client_code',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'birth_day',
            title: t('pages.alerts.helpers.relationsColumns.birth_day'),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'birth_day',
            ellipsis: true,
            width: 82,
        },
        {
            key: 'relation_name',
            title: t('pages.alerts.helpers.relationsColumns.relation_name'),
            dataIndex: 'relation_name',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'vaga1',
            title: t('pages.alerts.helpers.relationsColumns.vaga1'),
            dataIndex: 'vaga1',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'vaga2',
            title: t('pages.alerts.helpers.relationsColumns.vaga2'),
            dataIndex: 'vaga2',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'is_bank_client',
            title: t('pages.alerts.helpers.relationsColumns.is_bank_client'),
            dataIndex: 'is_bank_client',
            render: (value: any) => (value === 1 ? 'Так' : 'Ні'),
            ellipsis: true,
            width: 106,
        },
        {
            key: 'country',
            title: t('pages.alerts.helpers.relationsColumns.country'),
            dataIndex: 'country',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'citizenship_name',
            title: t('pages.alerts.helpers.relationsColumns.citizenship_name'),
            dataIndex: 'citizenship_name',
            ellipsis: true,
            width: 106,
        },
    ];

    const columns: ColumnsType<RelationParams> = [];
    possibleColumns.forEach((column) => rowDataKeys.includes(`${column.key}`) && columns.push(column));

    return getResizableColumn(columns, RULE_RELATION_SIZE, size);
};
