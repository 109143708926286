import { useEffect } from 'react';
import { notification } from 'antd';
import { BASE_NOTIFICATION_CONFIG } from '../constants/common';

const activeNotifications = new Set<string>();

export const baseErrorNotification = (error: any) => {
    if (error) {
        const errorMessage = (error as any)?.data?.message || error.data || error.message || error.error || error;

        if (!activeNotifications.has(errorMessage)) {
            activeNotifications.add(errorMessage);

            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: errorMessage,
                onClose: () => {
                    activeNotifications.delete(errorMessage);
                },
            });
        }
    }
};

export const useHandleError = (error: any) =>
    useEffect(() => {
        if (error) {
            baseErrorNotification(error);
        }
    }, [error]);

export const useHandleErrors = (...args: any[]) =>
    useEffect(() => {
        args.forEach((error) => {
            if (error) {
                baseErrorNotification(error);
            }
        });
    }, [args]);
