import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Col, notification, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { CalendarOutlined, CloseCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import History from './History';
import Modal from '../../../components/common/Modal';
import DeleteForm from '../../../components/form/calendar-form/DeleteForm';
import UpdateForm from '../../../components/form/calendar-form/UpdateForm';
import { INTERVAL_CODE_DAY } from '../../../constants/Session';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { SESSION_INTERVAL_TRANSLATE } from '../../../constants/enums';
import { TranslatedEnum } from '../../../helpers/translatedEnum';
import { SessionCalendarIntervals } from '../../../models/Session';
import {
    ReqChangeIntervalDate,
    useChangeIntervalDateMutation,
    useUpdateCalendarByIdMutation,
} from '../../../redux/api/calendarApi';

interface EditorProps {
    schedule: SessionCalendarIntervals;
    intervalId: number;
    onClose: () => void;
}
const Editor = ({ schedule, intervalId, onClose }: EditorProps) => {
    const { t } = useTranslation();
    const [changeModal, setChangeModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [historyModal, setHistoryModal] = useState(false);
    const disabledEdit = dayjs().subtract(1, 'day').isBefore(dayjs(schedule.date), 'day');
    const interval = useMemo(
        () => schedule.intervals.find((model) => model.interval.id === intervalId)?.interval,
        [schedule, intervalId],
    );
    const [updateSchedule, { isLoading: isLoadingUpdate }] = useUpdateCalendarByIdMutation();
    const [changeDate, { isLoading: isLoadingChange }] = useChangeIntervalDateMutation();
    const deleteInterval = useCallback(
        (comment: string) => {
            schedule.intervals = schedule.intervals.filter((model) => model.interval.id !== intervalId);
            updateSchedule({ ...schedule, comment })
                .unwrap()
                .then(() => {
                    notification.success({
                        ...BASE_NOTIFICATION_CONFIG,
                        message: t('pages.sessionAdministration.editor.notifications.delete'),
                    });
                    setDeleteModal(false);
                    onClose();
                })
                .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
        },
        [schedule, intervalId, updateSchedule, onClose, t],
    );
    const handleChangeIntervalDate = useCallback(
        (data: ReqChangeIntervalDate) => {
            changeDate(data)
                .unwrap()
                .then(() => {
                    notification.success({
                        ...BASE_NOTIFICATION_CONFIG,
                        message: t('pages.sessionAdministration.editor.notifications.changeInterval'),
                    });
                    setChangeModal(false);
                    onClose();
                })
                .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
        },
        [changeDate, onClose, t],
    );
    return (
        <Col span={24}>
            <Modal
                title={`${t('common.history.title')} ${
                    TranslatedEnum(SESSION_INTERVAL_TRANSLATE)[
                        interval?.code as keyof typeof SESSION_INTERVAL_TRANSLATE
                    ]
                }`}
                open={historyModal}
                onCancel={() => setHistoryModal(false)}
                destroyOnClose
                width={1200}>
                <History intervalCode={interval?.code} />
            </Modal>
            <Modal
                title={t('pages.sessionAdministration.editor.modal.delete')}
                open={deleteModal}
                onCancel={() => setDeleteModal(false)}
                destroyOnClose
                width={340}>
                <DeleteForm loading={isLoadingUpdate} date={schedule.date} onSubmit={deleteInterval} />
            </Modal>
            <Modal
                title={t('pages.sessionAdministration.editor.modal.change')}
                open={changeModal}
                onCancel={() => setChangeModal(false)}
                destroyOnClose
                width={340}>
                <UpdateForm
                    loading={isLoadingChange}
                    intervalId={intervalId}
                    date={schedule.date}
                    intervalCode={interval?.code as string}
                    onSubmit={handleChangeIntervalDate}
                />
            </Modal>
            <Row gutter={[8, 16]} className="edit-session__content">
                <Col span={24} className="edit-session__coll">
                    <Row>
                        <Col span={6} className="edit-session__label">
                            <Trans i18nKey="pages.sessionAdministration.editor.name" />
                        </Col>
                        <Col>{interval?.name}</Col>
                    </Row>
                </Col>
                <Col span={24} className="edit-session__coll">
                    <Row>
                        <Col span={6} className="edit-session__label">
                            <Trans i18nKey="pages.sessionAdministration.editor.description" />
                        </Col>
                        <Col>{interval?.description}</Col>
                    </Row>
                </Col>
                <Col span={24} className="edit-session__coll">
                    <Row>
                        <Col span={6} className="edit-session__label">
                            <Trans i18nKey="pages.sessionAdministration.editor.date" />
                        </Col>
                        <Col>{schedule.date}</Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={8} justify="end">
                <Space>
                    <Button icon={<HistoryOutlined />} onClick={() => setHistoryModal(true)}>
                        {t('common.history.title')}
                    </Button>
                    {disabledEdit && (
                        <>
                            <Button icon={<CloseCircleOutlined />} onClick={() => setDeleteModal(true)}>
                                {t('pages.sessionAdministration.editor.delete')}
                            </Button>
                            {interval?.code !== INTERVAL_CODE_DAY && (
                                <Button icon={<CalendarOutlined />} onClick={() => setChangeModal(true)}>
                                    {t('pages.sessionAdministration.editor.change')}
                                </Button>
                            )}
                        </>
                    )}
                </Space>
            </Row>
        </Col>
    );
};

export default Editor;
