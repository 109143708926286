import { translatedEnumUse, translatedEnumValueUse } from "../helpers/translatedEnum";

export const INTERVAL_CODE_DAY = 'one_time_a_day';
export const INTERVAL_CODE_WEEK = 'one_time_a_week';
export const INTERVAL_CODE_MONTH = 'one_time_per_month';
export const INTERVAL_CODE_QUARTER = 'one_time_per_quarter';

export enum STATUS_CODES {
    success = 'success',
    has_errors = 'has_errors',
    terminated = 'terminated',
    in_progress = 'in_progress',
    waiting = 'waiting',
    interrupted = 'interrupted',
    in_prepare = 'in_prepare',
    result_processing = 'result_processing',
}

export enum STATUS_CODES_DESC {
    success = 'enums.session.statusCodes.success',
    has_errors = 'enums.session.statusCodes.has_errors',
    terminated = 'enums.session.statusCodes.terminated',
    in_progress = 'enums.session.statusCodes.in_progress',
    waiting = 'enums.session.statusCodes.waiting',
}

export const SESSION_TABLE_SIZE = 'sessionTableSize';

export const intervalFilter = translatedEnumValueUse((t) => [
    { value: INTERVAL_CODE_DAY, text: t('enums.session.intervals.interval_desc_day') },
    { value: INTERVAL_CODE_WEEK, text: t('enums.session.intervals.interval_desc_week') },
    { value: INTERVAL_CODE_MONTH, text: t('enums.session.intervals.interval_desc_month') },
    { value: INTERVAL_CODE_QUARTER, text: t('enums.session.intervals.interval_desc_quarter') },
]);

export const sessionStatusFilter = translatedEnumUse(STATUS_CODES_DESC, (enumObj) =>  [
    { text: enumObj.success, value: STATUS_CODES.success },
    { text: enumObj.has_errors, value: STATUS_CODES.has_errors },
    { text: enumObj.terminated, value: STATUS_CODES.terminated },
    { text: enumObj.in_progress, value: STATUS_CODES.in_progress },
    { text: enumObj.waiting, value: STATUS_CODES.waiting },
]);

export const SINGLE_SESSION_KEY = 'single_session';
