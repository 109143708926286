import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Menu, Card, MenuProps, Row, Col, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Header from './common/Header';
import Version from './common/Version';
import Routers from '../components/common/Routers';
import { defaultFunctions } from '../constants';
import { colorPrimary } from '../constants/InterfaceThemes';
import { RouteCode, RouteList } from '../constants/enums';
import { getRouting } from '../helpers/getRouting';
import { ExtendedRouteModel, BaseRouteModel } from '../models/RouterModel';
import { usePutUserActionLogMutation } from '../redux/api/systemApi';
import { getUser } from '../redux/features/authSlice';
import { useAppSelector } from '../redux/hooks';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { Content, Footer, Sider } = Layout;

const initMenuItems = (routings?: ExtendedRouteModel[]): ItemType[] | undefined =>
    routings
        ?.sort((a: ExtendedRouteModel, b: ExtendedRouteModel) => a.ord - b.ord)
        .map((route: ExtendedRouteModel) => {
            return {
                key: route.code,
                icon: route.icon && <route.icon />,
                label: route.children?.length || !route.path ? route.name : <Link to={route.path}>{route.name}</Link>,
                ...(route.children?.length ? { children: initMenuItems(route.children) } : {}),
            } as ItemType;
        });

const MainLayout = () => {
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState(false);
    const [showVersion, setShowVersion] = useState(false);
    const navigate = useNavigate();
    const user = useAppSelector(getUser);
    const [trigger] = usePutUserActionLogMutation();
    const handleMenuSelect: MenuProps['onSelect'] = ({ key }) => {
        const route = RouteList.find((item: BaseRouteModel) => item.code === key);
        if (route && route.path) {
            user?.id && trigger({ action: t('common.notifications.menuSelect', { path: route.path }), userId: user.id });
            navigate(route.path);
        }
    };

    const functions = [...(user?.menuFunctions || []), ...defaultFunctions];
    const defaultKey = window.location.pathname.split('/')[1];
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                style={{ backgroundColor: colorPrimary }}
                width={270}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}>
                <div className="logo" style={{ padding: '1rem 1rem 2rem' }}>
                    <img src={collapsed ? 'logo_small.svg' : 'logo_big.svg'} alt="logo" style={{ width: '100%' }} />
                </div>
                <Menu
                    theme="dark"
                    defaultSelectedKeys={[defaultKey ? defaultKey : RouteCode.alerts]}
                    selectedKeys={[defaultKey]}
                    items={initMenuItems(getRouting(functions))}
                    onSelect={handleMenuSelect}
                    mode={'inline'}
                    defaultOpenKeys={[RouteCode.administration]}
                />
            </Sider>
            <Layout className="site-layout">
                <Header name={user?.firstName} />
                <Content style={{ margin: '20px 20px 0px 20px' }}>
                    <Card
                        style={{ height: '100%', position: 'relative' }}
                        bodyStyle={{ height: '100%', overflow: 'auto' }}>
                        {<Routers />}
                    </Card>
                </Content>
                <Footer>
                    <Row justify="center">
                        <Col className="copyright" onClick={() => setShowVersion((prev) => !prev)}>
                            <Text>AML ©2023</Text>
                            {showVersion && <Version />}
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
