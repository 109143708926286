import React, { Component, FC, LegacyRef, RefObject } from 'react';
import { Button, DatePicker, Input, InputRef } from 'antd';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT, DATE_FORMAT_FOR_QUERY } from '../../constants/Alert';
import { filterTypes } from '../../models/Catalog';
import { useTranslation } from 'react-i18next';

interface FilterDropDownProps extends FilterDropdownProps {
    container: RefObject<InputRef> | LegacyRef<Component<PickerProps<Dayjs>>>;
    type: filterTypes;
}
const FilterDropDown: FC<FilterDropDownProps> = ({
    type,
    container,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
}) => {
    const { t } = useTranslation();
    return (
        <div className="filter" onKeyDown={(e) => e.stopPropagation()}>
            <div className="filter__wrapper">
                {type === 'date' ? (
                    <DatePicker
                        className="filter__field"
                        placeholder={t('common.datePlaceholder')}
                        ref={container as LegacyRef<Component<PickerProps<Dayjs>>>}
                        format={DATE_FORMAT}
                        onChange={(e) => setSelectedKeys(e ? [e?.format(DATE_FORMAT_FOR_QUERY)] : [])}
                        value={selectedKeys[0] ? dayjs(selectedKeys[0], DATE_FORMAT_FOR_QUERY) : null}
                    />
                ) : (
                    <Input
                        className="filter__field"
                        ref={container as RefObject<InputRef>}
                        placeholder={t('components.filterDropDown.input')}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                    />
                )}
            </div>
            <div className="filter__buttons">
                <Button
                    onClick={() => {
                        clearFilters && clearFilters();
                        confirm();
                    }}
                    type="link"
                    size="small">
                    {t('common.clear')}
                </Button>
                <Button type="primary" onClick={() => confirm()} size="small">
                    {t('common.ok')}
                </Button>
            </div>
        </div>
    );
};

export default FilterDropDown;
