import { Tabs } from 'antd';
import SystemLog from './SystemLog';
import UsersActions from './UsersActions';
import { useTranslation } from 'react-i18next';

const SystemLogs = () => {
    const { t } = useTranslation();
    const tabItems = [
        {
            key: 'server-logs',
            label: t('pages.systemLog.tabs.server-logs'),
            children: <SystemLog />,
        },
        {
            key: 'users-actions',
            label: t('pages.systemLog.tabs.users-actions'),
            children: <UsersActions />,
        },
    ];
    return <Tabs type="card" defaultActiveKey="1" items={tabItems} />;
};

export default SystemLogs;
