import React, { FC, useMemo, useRef } from 'react';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment/moment';
import ResizableColumn from '../../../../../components/common/ResizebleColumn';
import { ACCOUNTS_INFO_TABLE, DATE_FORMAT } from '../../../../../constants/Alert';
import { ReportTypes } from '../../../../../constants/enums';
import { formatNumberToFinance } from '../../../../../helpers/convertCurrency';
import { accountsDto } from '../../../../../helpers/dto/accountsDto';
import { getColumnSearchProps } from '../../../../../helpers/getColumnSearchProps';
import getTableSizeFromStore from '../../../../../helpers/getTableSizeFromStore';
import { localeCompareSort } from '../../../../../helpers/sort';
import { Account } from '../../../../../models/Account';
import TableWrapper from '../TableWrapper';
import { Trans } from 'react-i18next';

interface BankAccountsProps {
    loading: boolean;
    data?: Account[];
    triggerFetch?: () => void;
}
const BankAccounts: FC<BankAccountsProps> = ({ loading, data, triggerFetch }) => {
    const size = useMemo(() => getTableSizeFromStore(ACCOUNTS_INFO_TABLE), []);
    const inputRef = useRef(null);
    const columns: ColumnsType<Account> = useMemo(
        () => [
            {
                key: 'branch',
                dataIndex: 'branch',
                title: (
                    <ResizableColumn colIndex={0} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="common.branch" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'branch'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'branch'),
                ellipsis: true,
                width: size?.[0] || 82,
            },
            {
                key: 'bankCode',
                dataIndex: 'bankCode',
                title: (
                    <ResizableColumn colIndex={1} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="common.bankCode" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'bankCode'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'bankCode'),
                ellipsis: true,
                width: size?.[1] || 60,
            },
            {
                key: 'bankName',
                dataIndex: 'bankName',
                title: (
                    <ResizableColumn colIndex={2} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="common.bankName" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'bankName'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'bankName'),
                ellipsis: true,
                width: size?.[2] || 82,
            },
            {
                key: 'accountIban',
                dataIndex: 'accountIban',
                title: (
                    <ResizableColumn colIndex={3} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="common.accountIban" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'accountIban'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'accountIban'),
                ellipsis: true,
                width: size?.[3] || 198,
            },
            {
                key: 'accountType',
                dataIndex: 'accountType',
                title: (
                    <ResizableColumn colIndex={4} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.accountType" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'accountType'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'accountType'),
                ellipsis: true,
                width: size?.[4] || 42,
            },
            {
                key: 'accountCurrencyId',
                dataIndex: 'accountCurrencyId',
                title: (
                    <ResizableColumn colIndex={5} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="common.accountCurrencyId" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'accountCurrencyId'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'accountCurrencyId'),
                ellipsis: true,
                width: size?.[5] || 36,
            },
            {
                key: 'customerId',
                dataIndex: 'customerId',
                title: (
                    <ResizableColumn colIndex={6} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="common.customerId" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => (a.customerId as number) - (b.customerId as number),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'customerId'),
                ellipsis: true,
                width: size?.[6] || 94,
            },
            {
                key: 'accountName',
                dataIndex: 'accountName',
                title: (
                    <ResizableColumn colIndex={7} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="common.accountName" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'accountName'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'accountName'),
                ellipsis: true,
                width: size?.[7] || 198,
            },
            {
                key: 'accountIban',
                dataIndex: 'accountIban',
                title: (
                    <ResizableColumn colIndex={8} storageKey={ACCOUNTS_INFO_TABLE}>
                        IBAN
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'accountIban'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'accountIban'),
                ellipsis: true,
                width: size?.[8] || 150,
            },
            {
                key: 'openDate',
                dataIndex: 'openDate',
                title: (
                    <ResizableColumn colIndex={9} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.openDate" />
                    </ResizableColumn>
                ),
                render: (value) => value && moment(value).format(DATE_FORMAT),
                sorter: (a, b) => new Date(a.openDate as string).getTime() - new Date(b.openDate as string).getTime(),
                ...getColumnSearchProps<Account>(inputRef, 'date', 'openDate'),
                ellipsis: true,
                width: size?.[9] || 82,
            },
            {
                key: 'accountBalance',
                dataIndex: 'accountBalance',
                title: (
                    <ResizableColumn colIndex={10} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.accountBalance" />
                    </ResizableColumn>
                ),
                render: (value) => formatNumberToFinance(value),
                sorter: (a, b) => (a.accountBalance as number) - (b.accountBalance as number),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'accountBalance'),
                ellipsis: true,
                width: size?.[10] || 150,
            },
            {
                key: 'turnDebit',
                dataIndex: 'turnDebit',
                title: (
                    <ResizableColumn colIndex={11} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="common.turnDebit" />
                    </ResizableColumn>
                ),
                render: (value) => formatNumberToFinance(value),
                sorter: (a, b) => (a.turnDebit as number) - (b.turnDebit as number),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'turnDebit'),
                ellipsis: true,
                width: size?.[11] || 150,
            },
            {
                key: 'turnCredit',
                dataIndex: 'turnCredit',
                title: (
                    <ResizableColumn colIndex={12} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="common.turnCredit" />
                    </ResizableColumn>
                ),
                render: (value) => formatNumberToFinance(value),
                sorter: (a, b) => (a.turnCredit as number) - (b.turnCredit as number),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'turnCredit'),
                ellipsis: true,
                width: size?.[12] || 150,
            },
            {
                key: 'actualBalance',
                dataIndex: 'actualBalance',
                title: (
                    <ResizableColumn colIndex={13} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.actualBalance" />
                    </ResizableColumn>
                ),
                render: (value) => formatNumberToFinance(value),
                sorter: (a, b) => (a.actualBalance as number) - (b.actualBalance as number),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'actualBalance'),
                ellipsis: true,
                width: size?.[13] || 150,
            },
            {
                key: 'actualBalance',
                dataIndex: 'actualBalance',
                title: (
                    <ResizableColumn colIndex={14} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.planActualBalance" />
                    </ResizableColumn>
                ),
                render: (value) => formatNumberToFinance(value),
                sorter: (a, b) => (a.actualBalance as number) - (b.actualBalance as number),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'actualBalance'),
                ellipsis: true,
                width: size?.[14] || 150,
            },
            {
                key: 'closeDate',
                dataIndex: 'closeDate',
                title: (
                    <ResizableColumn colIndex={15} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.closeDate" />
                    </ResizableColumn>
                ),
                render: (value) => value && moment(value).format(DATE_FORMAT),
                sorter: (a, b) => new Date(a.closeDate as string).getTime() - new Date(b.closeDate as string).getTime(),
                ...getColumnSearchProps<Account>(inputRef, 'date', 'closeDate'),
                ellipsis: true,
                width: size?.[15] || 82,
            },
            {
                key: 'lastTurnDate',
                dataIndex: 'lastTurnDate',
                title: (
                    <ResizableColumn colIndex={16} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.lastTurnDate" />
                    </ResizableColumn>
                ),
                sorter: (a, b) =>
                    new Date(a.lastTurnDate as string).getTime() - new Date(b.lastTurnDate as string).getTime(),
                render: (value) => value && moment(value).format(DATE_FORMAT),
                ...getColumnSearchProps<Account>(inputRef, 'date', 'lastTurnDate'),
                ellipsis: true,
                width: size?.[16] || 82,
            },
            {
                key: 'lockDebitCode',
                dataIndex: 'lockDebitCode',
                title: (
                    <ResizableColumn colIndex={17} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.lockDebitCode" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'lockDebitCode'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'lockDebitCode'),
                ellipsis: true,
                width: size?.[17] || 82,
            },
            {
                key: 'lockCreditCode',
                dataIndex: 'lockCreditCode',
                title: (
                    <ResizableColumn colIndex={18} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.lockCreditCode" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'lockCreditCode'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'lockCreditCode'),
                ellipsis: true,
                width: size?.[18] || 82,
            },
            {
                key: 'closeDate',
                dataIndex: 'closeDate',
                title: (
                    <ResizableColumn colIndex={19} storageKey={ACCOUNTS_INFO_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.bankAccounts.closeDateAcc" />
                    </ResizableColumn>
                ),
                render: (value) => (value ? 'Так' : 'Ні'),
                sorter: (a, b) => localeCompareSort(a, b, 'closeDate'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'closeDate'),
                ellipsis: true,
                width: size?.[19] || 82,
            },
        ],
        [size],
    );

    return (
        <TableWrapper
            rowKey="accountId"
            dataSource={data}
            columns={columns}
            loading={loading}
            triggerFetch={triggerFetch}
            isActionBar={true}
            isClearFilters={true}
            reportType={ReportTypes.accounts}
            dto={accountsDto}
        />
    );
};

export default BankAccounts;
