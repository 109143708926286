import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';

interface IProps {
    name: (string | number)[];
    label: string;
    min?: number;
    max?: number;
}
const ParameterArray = ({ min, max, name, label }: IProps) => {
    const { t } = useTranslation();
    return (
        <Row gutter={[8, 8]}>
            <Form.List name={name}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key }) => (
                            <Col key={key}>
                                <Form.Item
                                    name={`${key}`}
                                    rules={[
                                        { required: true },
                                        { whitespace: true, message: t('common.disableWhitespace') },
                                        {
                                            min,
                                            max,
                                            // eslint-disable-next-line no-template-curly-in-string
                                            message: t('validation.minMaxMessage'),
                                        },
                                    ]}>
                                    <MaskedInput
                                        style={{
                                            width: 50,
                                        }}
                                        mask={[{ mask: Number, scale: 0 }]}
                                    />
                                </Form.Item>
                            </Col>
                        ))}
                        {fields.length > 1 && (
                            <Col>
                                <Button type="primary" danger onClick={() => remove(fields.length - 1)}>
                                    {t('common.delete')} {label}
                                </Button>
                            </Col>
                        )}
                        <Col>
                            <Button type="primary" onClick={() => add()}>
                                {t('common.add')} {label}
                            </Button>
                        </Col>
                    </>
                )}
            </Form.List>
        </Row>
    );
};

export default ParameterArray;
