import { useMemo } from 'react';
import { useHandleErrors } from '../../../hooks/useHandleError';
import {
    useAddCddMeasuresMutation,
    useDeleteCddMeasuresMutation,
    useLazyGetAlertAccountsQuery,
    useLazyGetAlertBlackListQuery,
    useLazyGetAlertOtherRnkQuery,
    useLazyGetAlertTransactionsQuery,
    useLazyGetCddMeasuresQuery,
    useLazyGetClientAlertQuery,
} from '../../../redux/api/alertsApi';
import {
    useDeleteCustomerAlienAccountsMutation,
    useLazyGetCustomerAlienAccountsQuery,
    useLazyGetCustomerRelationsQuery,
    usePutCustomerAlienAccountsMutation,
} from '../../../redux/api/customerApi';

export enum QueryType {
    alerts = 'alerts',
    accounts = 'accounts',
    payments = 'payments',
    otherRnk = 'otherRnk',
    blackList = 'blackList',
    relations = 'relations',
    alienAccounts = 'alien-accounts',
    cddMeasures = 'cddMeasures',
}

export const useGetLazyQuery = () => {
    const [getAlerts, { data: alerts, isLoading: isLoadingAlerts, isError: isErrorAlerts }] =
        useLazyGetClientAlertQuery();
    const [getAccounts, { data: accounts, isLoading: isLoadingAccounts, isError: isErrorAccounts }] =
        useLazyGetAlertAccountsQuery();
    const [getPayments, { data: payments, isLoading: isLoadingTransactions, isError: isErrorPayments }] =
        useLazyGetAlertTransactionsQuery();
    const [getOtherRNK, { data: otherRnk, isLoading: isLoadingOtherRnk, isError: isErrorOtherRnk }] =
        useLazyGetAlertOtherRnkQuery();
    const [getBlackList, { data: blackList, isLoading: isLoadingBlackList, isError: isErrorBlacklist }] =
        useLazyGetAlertBlackListQuery();
    const [getRelations, { data: relations, isLoading: isLoadingRelations, isError: isErrorRelations }] =
        useLazyGetCustomerRelationsQuery();
    const [
        getAlienAccounts,
        { data: alienAccounts, isLoading: isLoadingAlienAccounts, isError: isErrorAlienAccounts },
    ] = useLazyGetCustomerAlienAccountsQuery();
    const [getCddMeasures, { data: cddMeasures, isLoading: isLoadingCddMeasures, isError: isErrorCddMeasures }] =
        useLazyGetCddMeasuresQuery();

    const triggerFetch = (type: QueryType, id?: number) => {
        if (!id) return;
        if (type === QueryType.alerts && !alerts && !isErrorAlerts) getAlerts(id);
        if (type === QueryType.accounts && !accounts && !isErrorAccounts) getAccounts(id);
        if (type === QueryType.payments && !payments && !isErrorPayments) getPayments(id);
        if (type === QueryType.otherRnk && !otherRnk && !isErrorOtherRnk) getOtherRNK(id);
        if (type === QueryType.blackList && !blackList && !isErrorBlacklist) getBlackList(id);
        if (type === QueryType.relations && !relations && !isErrorRelations) getRelations(id);
        if (type === QueryType.alienAccounts && !alienAccounts && !isErrorAlienAccounts) getAlienAccounts(id);
        if (type === QueryType.cddMeasures && !cddMeasures && !isErrorCddMeasures) getCddMeasures(id);
    };

    const data = useMemo(() => {
        return {
            alerts,
            accounts,
            payments,
            otherRnk,
            blackList,
            relations,
            alienAccounts,
            cddMeasures,
        };
    }, [alerts, accounts, payments, otherRnk, blackList, relations, alienAccounts, cddMeasures]);

    const loading = useMemo(() => {
        return {
            isLoadingAlerts,
            isLoadingAccounts,
            isLoadingTransactions,
            isLoadingOtherRnk,
            isLoadingBlackList,
            isLoadingRelations,
            isLoadingAlienAccounts,
            isLoadingCddMeasures,
        };
    }, [
        isLoadingAlerts,
        isLoadingAccounts,
        isLoadingTransactions,
        isLoadingOtherRnk,
        isLoadingBlackList,
        isLoadingRelations,
        isLoadingAlienAccounts,
        isLoadingCddMeasures,
    ]);

    return { triggerFetch, data, loading };
};

export const usePutQuery = () => {
    const [putAlienAccounts, { isLoading: isLoadingAlienAccounts, error: putAlienAccountsError }] =
        usePutCustomerAlienAccountsMutation();
    const [putCddMeasures, { isLoading: isLoadingCddMeasures, error: addCddMeasuresError }] =
        useAddCddMeasuresMutation();

    useHandleErrors(putAlienAccountsError, addCddMeasuresError);

    const triggerPut = async (type?: QueryType, value?: any, cb?: () => void) => {
        if (type === QueryType.alienAccounts) {
            const result = await putAlienAccounts(value);
            if (!('error' in result) && cb) cb();
        }
        if (type === QueryType.cddMeasures) {
            const result = await putCddMeasures(value);
            if (!('error' in result) && cb) cb();
        }
    };

    const loading = useMemo(
        () => isLoadingAlienAccounts || isLoadingCddMeasures,
        [isLoadingAlienAccounts, isLoadingCddMeasures],
    );

    return { triggerPut, loading };
};

export const useDeleteQuery = () => {
    const [deleteAlienAccounts, { isLoading: isLoadingAlienAccounts, error: putAlienAccountsError }] =
        useDeleteCustomerAlienAccountsMutation();
    const [deleteCddMeasures, { isLoading: isLoadingCddMeasures, error: addCddMeasuresError }] =
        useDeleteCddMeasuresMutation();

    useHandleErrors(putAlienAccountsError, addCddMeasuresError);

    const triggerDelete = (type?: QueryType, value?: any, cb?: () => void) => {
        if (type === QueryType.alienAccounts) deleteAlienAccounts(value).then(() => cb && cb());
        if (type === QueryType.cddMeasures) deleteCddMeasures(value).then(() => cb && cb());
    };

    const loading = useMemo(
        () => isLoadingAlienAccounts || isLoadingCddMeasures,
        [isLoadingAlienAccounts, isLoadingCddMeasures],
    );

    return { triggerDelete, loading };
};
