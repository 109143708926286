import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { ObjProperties, RuleParamModel } from '../../../models/RulesModel';
import { useTranslation } from 'react-i18next';

interface IProps {
    paramIndex: number;
    properties: ObjProperties;
}

export const possibleAccKey = ['account', 'nbs'];

const getAccountKey = (properties: RuleParamModel['properties']) => {
    if (!properties) return;
    return Object.keys(properties).find((key) => possibleAccKey.includes(key));
};
const Account = ({ paramIndex, properties }: IProps) => {
    const { t } = useTranslation();
    return (
        <Form.List name={['params', paramIndex, 'value']}>
            {(fields, { add, remove }) => (
                <Row gutter={[8, 8]}>
                    {fields.map(({ key }) => (
                        <Col span={24} key={key}>
                            <Row gutter={[8, 8]}>
                                <Col>
                                    <Form.Item
                                        name={[key, `${getAccountKey(properties)}`]}
                                        rules={[
                                            { required: true },
                                            { whitespace: true, message: t('common.disableWhitespace') },
                                            {
                                                len: 4,
                                                pattern: /^[0-9]*$/,
                                                // eslint-disable-next-line no-template-curly-in-string
                                                message: t('validation.lenMessage'),
                                            },
                                        ]}>
                                        <Input
                                            disabled={properties?.[`${getAccountKey(properties)}`]?.disabled}
                                            maxLength={4}
                                            style={{ width: '60px' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Form.List name={[key, 'ob22']}>
                                    {(fields, { add, remove }) => {
                                        return (
                                            <>
                                                {fields.map(({ key }) => (
                                                    <Col key={key}>
                                                        <Form.Item
                                                            name={key}
                                                            rules={[
                                                                {
                                                                    len: 2,
                                                                    pattern: /^[0-9]*$/,
                                                                    // eslint-disable-next-line no-template-curly-in-string
                                                                    message: t('validation.lenMessage'),
                                                                },
                                                            ]}>
                                                            <Input maxLength={2} style={{ width: '40px' }} />
                                                        </Form.Item>
                                                    </Col>
                                                ))}
                                                {fields.length > 0 && (
                                                    <Col>
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            onClick={() => remove(fields.length - 1)}>
                                                            {t('components.rules.account.ob22.delete')}
                                                        </Button>
                                                    </Col>
                                                )}
                                                <Col>
                                                    <Button type="primary" onClick={() => add()}>
                                                        {t('components.rules.account.ob22.add')}
                                                    </Button>
                                                </Col>
                                            </>
                                        );
                                    }}
                                </Form.List>
                            </Row>
                        </Col>
                    ))}
                    {!properties?.[`${getAccountKey(properties)}`]?.disabled && (
                        <>
                            {fields.length > 1 && (
                                <Col>
                                    <Button type="primary" danger onClick={() => remove(fields.length - 1)}>
                                        {t('components.rules.account.delete')}
                                    </Button>
                                </Col>
                            )}
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => add({ [`${getAccountKey(properties)}`]: '', ob22: [''] })}>
                                    {t('components.rules.account.add')}
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            )}
        </Form.List>
    );
};

export default Account;
