import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { HistoryOutlined } from '@ant-design/icons';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import { DATE_FORMAT_WITH_TIME, SCHEDULERS_TABLE, TECH_USER_NAME } from '../../../constants';
import { Schedule } from '../../../models/Schedule';
import { HandleClick } from '../models/HandleClick';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';

const defaultColumnsWidth = {
    0: '4%',
    1: '15%',
    2: '20%',
    3: '7%',
    4: '10%',
    5: '5%',
    6: '10%',
    7: '17%',
} as const;

const actionButtons = (
    record: Schedule,
    handleHistoryClick: HandleClick,
    handleRunOnceClick: HandleClick,
    handleSwitchClick: HandleClick,
    t: TFunction,
) => {
    const disabled = !['scheduled', 'stoped'].includes(record.status_code ?? '');
    return (
        <Space>
            <Button size="small" type="text" onClick={(e) => handleRunOnceClick(record.id, e)} disabled={disabled}>
                <Trans i18nKey="common.startup" />
            </Button>
            <Button
                size="small"
                type="text"
                disabled={disabled}
                {...(record.status_code === 'scheduled' ? { danger: true } : {})}
                style={{ width: 80 }}
                onClick={(e) =>
                    handleSwitchClick(record.id, e, record.status_code === 'scheduled' ? 'deactivate' : 'activate')
                }>
                {record.status_code === 'scheduled' ? t('pages.schedulers.disable') : t('pages.schedulers.enable')}
            </Button>
            <Button size="small" icon={<HistoryOutlined />} onClick={(e) => handleHistoryClick(record.id, e)}></Button>
        </Space>
    );
};

const getFullName = (record: Schedule) => {
    if (record.last_run_by_first_name || record.last_run_by_last_name || record.last_run_by_middle_name) {
        return `${record.last_run_by_last_name || ''} ${record.last_run_by_first_name || ''} ${
            record.last_run_by_middle_name || ''
        }`;
    } else {
        return TECH_USER_NAME();
    }
};

export const getColumns = (
    sizes: Record<string, number>,
    handleHistoryClick: HandleClick,
    handleRunOnceClick: HandleClick,
    handleSwitchClick: HandleClick,
    t: TFunction,
): ColumnsType<Schedule> => {
    return [
        {
            title: (
                <ResizableColumn colIndex={0} storageKey={SCHEDULERS_TABLE}>
                    <Trans i18nKey="pages.schedulers.columns.id" />
                </ResizableColumn>
            ),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: sizes?.[0] || defaultColumnsWidth[0],
        },
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={SCHEDULERS_TABLE}>
                    <Trans i18nKey="pages.schedulers.columns.name" />
                </ResizableColumn>
            ),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: sizes?.[1] || defaultColumnsWidth[1],
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={SCHEDULERS_TABLE}>
                    <Trans i18nKey="pages.schedulers.columns.description" />
                </ResizableColumn>
            ),
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
            width: sizes?.[2] || defaultColumnsWidth[2],
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={SCHEDULERS_TABLE}>
                    <Trans i18nKey="pages.schedulers.columns.status_description" />
                </ResizableColumn>
            ),
            dataIndex: 'status_description',
            key: 'status_description',
            ellipsis: true,
            width: sizes?.[3] || defaultColumnsWidth[3],
        },
        {
            title: (
                <ResizableColumn colIndex={4} storageKey={SCHEDULERS_TABLE}>
                    <Trans i18nKey="pages.schedulers.columns.interval" />
                </ResizableColumn>
            ),
            dataIndex: 'interval',
            key: 'interval',
            ellipsis: true,
            width: sizes?.[4] || defaultColumnsWidth[4],
        },
        {
            title: (
                <ResizableColumn colIndex={5} storageKey={SCHEDULERS_TABLE}>
                    <Trans i18nKey="pages.schedulers.columns.last_run" />
                </ResizableColumn>
            ),
            dataIndex: 'last_run',
            key: 'last_run',
            ellipsis: true,
            width: sizes?.[5] || defaultColumnsWidth[5],
            render: (value) => (value ? dayjs(value).format(DATE_FORMAT_WITH_TIME) : ''),
        },
        {
            title: (
                <ResizableColumn colIndex={6} storageKey={SCHEDULERS_TABLE}>
                    <Trans i18nKey="pages.schedulers.columns.last_run_user" />
                </ResizableColumn>
            ),
            key: 'interval',
            ellipsis: true,
            width: sizes?.[6] || defaultColumnsWidth[6],
            render: (_value, record) => getFullName(record),
        },
        {
            title: (
                <ResizableColumn colIndex={7} storageKey={SCHEDULERS_TABLE}>
                    <Trans i18nKey="pages.schedulers.columns.operation" />
                </ResizableColumn>
            ),
            key: 'operation',
            fixed: 'right',
            width: sizes?.[7] || defaultColumnsWidth[7],
            render: (record) => actionButtons(record, handleHistoryClick, handleRunOnceClick, handleSwitchClick, t),
        },
    ];
};
