import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import i18n from 'i18next';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import translationEN from './common/locales/en/translation.json';
import translationUK from './common/locales/uk/translation.json';
import './index.css';
import { persistor, store } from './redux/store';

const LANG = process.env.REACT_APP_DEFAULT_LANG || 'uk';

dayjs.locale(LANG);
dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);

const resources = {
    en: { translation: translationEN },
    uk: { translation: translationUK },
};

i18n.use(initReactI18next).init({
    resources,
    lng: LANG,
    fallbackLng: LANG,
    interpolation: {
        escapeValue: false,
    },
} as any);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
);
