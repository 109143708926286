import React, { FC } from 'react';
import { Button, Checkbox, Col, Dropdown, Row, Space } from 'antd';
import { MenuProps } from 'antd';
import {
    CheckOutlined,
    CaretDownOutlined,
    UndoOutlined,
    CloseCircleOutlined,
    SnippetsOutlined,
    MoreOutlined,
    WarningOutlined,
    CalendarOutlined,
    PrinterOutlined,
    UserOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import { UserModel } from '../../../models/UserModel';
import { Trans, useTranslation } from 'react-i18next';

interface StatusActionsControlsProps {
    roleParams?: UserModel['rolesParams'];
    assignDisable: boolean;
    approveDisable: boolean;
    amendmentDisabled: boolean;
    priorityDisabled: boolean;
    deadlineDisabled: boolean;
    processDisabled: boolean;
    processedDisabled: boolean;
    finishDisabled: boolean;
    disabledHistory: boolean;
    relevant: boolean;
    disableMultipleRow: boolean;
    assignControllerDisable: boolean;
    onAssignController: () => void;
    onAssign: () => void;
    onApprove: () => void;
    onAmendment: () => void;
    onPriority: () => void;
    onDeadline: () => void;
    onProcess: () => void;
    onProcessed: () => void;
    onFinish: () => void;
    onOpenHistory: () => void;
    onChangeRelevant: () => void;
    handleAlertConclusionClick: (type: string) => void;
    handleAlertRegisterClick: () => void;
    handleBusinessRelationshipsClick: () => void;
    handleResetFiltersClick: () => void;
    isFetchingAlerts: boolean;
    refetchAlerts: () => void;
}
const StatusActionsControls: FC<StatusActionsControlsProps> = ({
    roleParams,
    assignDisable,
    approveDisable,
    amendmentDisabled,
    priorityDisabled,
    deadlineDisabled,
    processDisabled,
    processedDisabled,
    finishDisabled,
    disabledHistory,
    relevant,
    disableMultipleRow,
    onAssign,
    onApprove,
    onAmendment,
    onPriority,
    onDeadline,
    onProcess,
    onProcessed,
    onFinish,
    onOpenHistory,
    onChangeRelevant,
    handleAlertConclusionClick,
    handleAlertRegisterClick,
    handleBusinessRelationshipsClick,
    handleResetFiltersClick,
    assignControllerDisable,
    onAssignController,
    isFetchingAlerts,
    refetchAlerts,
}) => {
    const { t } = useTranslation();
    const termButtons: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div onClick={onPriority}>
                    <Space>
                        <WarningOutlined />
                        <Trans i18nKey="pages.alerts.components.statusActionsControls.termButtons.1" />
                    </Space>
                </div>
            ),
            disabled: priorityDisabled,
        },
        {
            key: '2',
            label: (
                <div onClick={onDeadline}>
                    <Space>
                        <CalendarOutlined />
                        <Trans i18nKey="pages.alerts.components.statusActionsControls.termButtons.2" />
                    </Space>
                </div>
            ),
            disabled: deadlineDisabled,
        },
    ];

    const printButtons: MenuProps['items'] = [
        {
            key: '1',
            label: t('pages.alerts.components.statusActionsControls.printButtons.1'),
            disabled: disableMultipleRow,
            children: [
                {
                    key: 'pdf',
                    label: 'pdf',
                    onClick: () => handleAlertConclusionClick('pdf'),
                },
                {
                    key: 'docx',
                    label: 'docx',
                    onClick: () => handleAlertConclusionClick('docx'),
                },
                {
                    key: 'xlsx',
                    label: 'xlsx',
                    onClick: () => handleAlertConclusionClick('xlsx'),
                },
            ],
        },
        {
            key: '2',
            label: t('pages.alerts.components.statusActionsControls.printButtons.2'),
            onClick: () => handleBusinessRelationshipsClick(),
        },
        {
            key: '3',
            label: t('pages.alerts.components.statusActionsControls.printButtons.3'),
            onClick: () => handleAlertRegisterClick(),
        },
    ];

    return (
        <Row gutter={[8, 8]} align="middle" justify="space-between">
            <Col>
                <Checkbox checked={relevant} onChange={onChangeRelevant}>
                    <Trans i18nKey="pages.alerts.components.statusActionsControls.relevant" />
                </Checkbox>
                <Button onClick={handleResetFiltersClick}><Trans i18nKey="pages.alerts.components.statusActionsControls.clearFilters" /></Button>
            </Col>
            <Col>
                <Row gutter={[8, 8]}>
                    {roleParams?.IS_CONTROLLER_1ST && (
                        <Col>
                            <Button
                                icon={<UserOutlined />}
                                disabled={assignControllerDisable}
                                onClick={onAssignController}>
                                {t('pages.alerts.components.statusActionsControls.assignController')}
                            </Button>
                        </Col>
                    )}
                    {roleParams?.IS_CONTROLLER && (
                        <>
                            <Col>
                                <Button icon={<UserOutlined />} disabled={assignDisable} onClick={onAssign}>
                                    {t('pages.alerts.components.statusActionsControls.assign')}
                                </Button>
                            </Col>
                            <Col>
                                <Button icon={<CheckOutlined />} disabled={approveDisable} onClick={onApprove}>
                                    {t('common.agree')}
                                </Button>
                            </Col>
                            <Col>
                                <Button icon={<UndoOutlined />} disabled={amendmentDisabled} onClick={onAmendment}>
                                    {t('pages.alerts.components.statusActionsControls.response')}
                                </Button>
                            </Col>
                            <Col>
                                <Button icon={<CloseCircleOutlined />} disabled={finishDisabled} onClick={onFinish}>
                                    {t('common.close')}
                                </Button>
                            </Col>
                            <Col>
                                <Dropdown disabled={priorityDisabled && deadlineDisabled} menu={{ items: termButtons }}>
                                    <Button icon={<MoreOutlined />} />
                                </Dropdown>
                            </Col>
                        </>
                    )}
                    {roleParams?.IS_ANALYST && (
                        <>
                            <Col>
                                <Button disabled={processDisabled} onClick={onProcess}>
                                    {t('pages.alerts.components.statusActionsControls.onProcess')}
                                </Button>
                            </Col>
                            <Col>
                                <Button icon={<CheckOutlined />} disabled={processedDisabled} onClick={onProcessed}>
                                    {t('pages.alerts.components.statusActionsControls.processed')}
                                </Button>
                            </Col>
                        </>
                    )}
                    <>
                        <Col>
                            <Button disabled={disabledHistory} onClick={onOpenHistory} icon={<SnippetsOutlined />}>
                                {t('common.history.title')}
                            </Button>
                        </Col>
                        <Col>
                            <Dropdown menu={{ items: printButtons }}>
                                <Button>
                                    <Space>
                                        <PrinterOutlined />
                                        <CaretDownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Button icon={<ReloadOutlined />} onClick={refetchAlerts} loading={isFetchingAlerts} />
                        </Col>
                    </>
                </Row>
            </Col>
        </Row>
    );
};

export default StatusActionsControls;
