import React, { FC, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Button, DatePicker, Form, Input, Row, Select, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME } from '../../../../../constants';
import { FormItemsWithInputType } from '../../../../../models/Common';
import { alertsApi, Tags } from '../../../../../redux/api/alertsApi';
import { useAppDispatch } from '../../../../../redux/hooks';
import { QueryType, usePutQuery } from '../../../hooks/useQuery';

interface EditableTableModalProps {
    formItemsProps?: FormItemsWithInputType[];
    isUpdate?: boolean;
    selectedRows?: any[];
    closeModal?: () => void;
    queryType?: QueryType;
    clearSelection?: () => void;
    alertId?: number;
}

const formItems = (formItemsData?: FormItemsWithInputType[]) => {
    return formItemsData?.map((item, index) => (
        <Form.Item
            key={index}
            name={item.name}
            label={item.label}
            hidden={item.hidden}
            initialValue={item.initialValue}
            rules={[{ ...item.rules?.[0], required: item.required }]}>
            {item.type === 'select' ? (
                <Select options={item.options} disabled={item.disabled} />
            ) : item.type === 'date' ? (
                <DatePicker
                    disabled={item.disabled}
                    className="wWide"
                    format={item.withTime ? DATE_FORMAT_WITH_TIME : DATE_FORMAT}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                    disabledTime={(current) => {
                        if (!item.withTime) return {};
                        const today = dayjs().startOf('day');
                        return current && current.isSame(today, 'day')
                            ? {
                                  disabledHours: () => {
                                      const hours = Array.from({ length: 24 }, (_, i) => i);
                                      return hours.filter((hour) => hour > dayjs().hour());
                                  },
                                  disabledMinutes: () => {
                                      const minutes = Array.from({ length: 60 }, (_, i) => i);
                                      return minutes.filter((minute) => minute > dayjs().minute());
                                  },
                                  disabledSeconds: () => {
                                      const seconds = Array.from({ length: 60 }, (_, i) => i);
                                      return seconds.filter((second) => second > dayjs().second());
                                  },
                              }
                            : {};
                    }}
                    showTime={item.withTime}
                />
            ) : item.type === 'textArea' ? (
                <Input.TextArea disabled={item.disabled} rows={4} />
            ) : (
                <Input disabled={item.disabled} type={item.type} />
            )}
        </Form.Item>
    ));
};

const EditableTableModal: FC<EditableTableModalProps> = ({
    formItemsProps,
    isUpdate,
    selectedRows,
    closeModal,
    queryType,
    clearSelection,
    alertId,
}) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { triggerPut, loading } = usePutQuery();

    useEffect(() => {
        if (isUpdate && selectedRows?.[0]) {
            form.setFieldsValue(selectedRows[0]);
            if (selectedRows[0].date) {
                form.setFieldValue('date', dayjs(selectedRows[0].date));
            }
        }
    }, [form, isUpdate, selectedRows]);

    const handleSubmit = (value: any) =>
        triggerPut(queryType, value, () => {
            closeModal && closeModal();
            clearSelection && clearSelection();
            if (queryType === QueryType.cddMeasures && alertId)
                dispatch(alertsApi.util.invalidateTags([{ type: Tags.alert, id: alertId }]));
        });

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Spin spinning={loading}>
                {formItems(formItemsProps)}
                <Row justify="end">
                    <Space wrap>
                        <Button type="primary" onClick={closeModal}>
                            <Trans i18nKey="common.cancel" />
                        </Button>
                        <Button type="primary" htmlType="submit">
                            <Trans i18nKey="common.submit" />
                        </Button>
                    </Space>
                </Row>
            </Spin>
        </Form>
    );
};

export default EditableTableModal;
