import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import TextArea from 'antd/lib/input/TextArea';
import { TFunction } from 'i18next';
import Account from './Account';
import DataField from './DataField';
import ParameterArray from './ParametrArray';
import ParameterObject from './ParametrObject';
import {
    RULES_OPTION_PARAMETER_TYPE_ARRAY,
    RULES_OPTION_PARAMETER_TYPE_NUMBER,
    RULES_OPTION_PARAMETER_TYPE_OBJECT,
    RULES_OPTION_PARAMETER_TYPE_SELECT,
} from '../../../constants/Rules';
import { PERIODS_TRANSLATE, RuleParamsGroupType } from '../../../constants/enums';
import { numberToInputString } from '../../../helpers/numberToInputString';
import { TranslatedEnum } from '../../../helpers/translatedEnum';
import { RecordModel } from '../../../models/DictionaryModel';
import { ArrayProperty, ObjProperties, OptionsType, RuleModel, RuleParamModel } from '../../../models/RulesModel';

interface ParamsProps {
    selectedRule?: RuleModel;
    optionType: OptionsType;
    dictParamsGroupTypes?: RecordModel[];
}

const getValue = (param: RuleParamModel, optionType: OptionsType) => {
    const { type, value, properties, fraction } = param;

    if (type.code === RULES_OPTION_PARAMETER_TYPE_OBJECT && optionType === 'view') {
        if (Array.isArray(value)) {
            return value
                .map((item) =>
                    Object.keys(properties as ObjProperties)
                        .map((key) => item?.[key])
                        .filter((item) => item && item.length > 0)
                        .join('/'),
                )
                .join('; ');
        } else {
            return Object.entries(value)
                .map(([key, val]) => {
                    const label = (properties as ObjProperties)[key]?.label
                        ? (properties as ObjProperties)[key]?.label + ':'
                        : '';
                    if ((properties as ObjProperties)[key]?.type === 'select') {
                        return `${label} ${
                            TranslatedEnum(PERIODS_TRANSLATE)[val as keyof typeof PERIODS_TRANSLATE] || ''
                        }`;
                    }
                    return `${label} ${val}`;
                })
                .join('; ');
        }
    }

    if (type.code === RULES_OPTION_PARAMETER_TYPE_ARRAY && optionType === 'view') {
        return (value as string[]).join('; ');
    }

    if (type.code === RULES_OPTION_PARAMETER_TYPE_SELECT && optionType === 'view') {
        return TranslatedEnum(PERIODS_TRANSLATE)[value as keyof typeof PERIODS_TRANSLATE] || '';
    }

    if (type.code === RULES_OPTION_PARAMETER_TYPE_NUMBER) {
        return numberToInputString(value as number, fraction);
    }

    return value as string;
};

const getParam = (
    params: RuleParamModel | RuleParamModel[] | undefined,
    optionType: OptionsType,
    groupIndex: number,
    t: TFunction<'translation', undefined>,
) => {
    if (!params || !Array.isArray(params) || params.length === 0) return null;
    return (
        <div key={groupIndex}>
            {params[0].group?.code !== RuleParamsGroupType.common && (
                <div className="rules-block__sub_title">{params[0].group?.description}</div>
            )}
            {params.map(
                (param) =>
                    param.index !== undefined && (
                        <DataField
                            key={param.id}
                            describe={param.label}
                            value={getValue(param, optionType)}
                            optionType={optionType}>
                            <>
                                <Form.Item name={['params', param.index, 'id']} hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item name={['params', param.index, 'type']} hidden>
                                    <Input />
                                </Form.Item>
                                {param.type?.code === RULES_OPTION_PARAMETER_TYPE_ARRAY ? (
                                    <ParameterArray
                                        name={['params', param.index, 'value']}
                                        min={(param.properties as ArrayProperty)?.minLength}
                                        max={(param.properties as ArrayProperty)?.maxLength}
                                        label={param.label}
                                    />
                                ) : param.type?.code === RULES_OPTION_PARAMETER_TYPE_OBJECT ? (
                                    Array.isArray(param.value) ? (
                                        <Account
                                            paramIndex={param.index}
                                            properties={param.properties as ObjProperties}
                                        />
                                    ) : (
                                        <ParameterObject
                                            value={param.value as object}
                                            param={param}
                                            paramIndex={param.index}
                                        />
                                    )
                                ) : (
                                    <Form.Item
                                        name={['params', param.index, 'value']}
                                        rules={[{ whitespace: true, message: t('common.disableWhitespace') }]}>
                                        {param.type?.code === RULES_OPTION_PARAMETER_TYPE_SELECT ? (
                                            <Select options={param.options} />
                                        ) : param.type?.code === RULES_OPTION_PARAMETER_TYPE_NUMBER ? (
                                            <MaskedInput
                                                mask={[
                                                    {
                                                        mask: Number, // enable number mask
                                                        scale: param.fraction ?? 0, // digits after point, 0 for integers
                                                        signed: false, // disallow negative
                                                        normalizeZeros: false,
                                                        radix: ',', // fractional delimiter
                                                        mapToRadix: [',', '.'], // symbols to process as radix
                                                    },
                                                ]}
                                                className="wWide"
                                            />
                                        ) : (
                                            <Input className="wWide" />
                                        )}
                                    </Form.Item>
                                )}
                            </>
                        </DataField>
                    ),
            )}
        </div>
    );
};

const getGroupedParams = (params?: RuleParamModel | RuleParamModel[]) => {
    if (!params || !Array.isArray(params) || params.length === 0) return null;
    return params
        .map((param, index) => ({ ...param, index }))
        .sort((a, b) => (a.ord && b.ord && !(a.ord === b.ord) ? a.ord - b.ord : 0))
        .reduce((acc, param) => {
            const group = param.group?.code;
            if (group) {
                if (!acc[group]) acc[group] = [];
                acc[group].push(param);
            }
            return acc;
        }, {} as { [key: string]: RuleParamModel[] });
};

const Params: FC<ParamsProps> = ({ selectedRule, optionType, dictParamsGroupTypes }) => {
    const { t } = useTranslation();
    const groupedParams = getGroupedParams(selectedRule?.params);
    const groupOrder =
        dictParamsGroupTypes?.reduce((acc, group, index) => {
            acc[group.code] = group.ord ?? index + 1;
            return acc;
        }, {} as { [key: string]: number }) ?? {};

    const activityItems = [
        {
            value: 0,
            label: t('common.no'),
        },
        {
            value: 1,
            label: t('common.yes'),
        },
    ];

    return (
        <>
            <div className="rules-block__sub_title">{t('components.rules.params.title')}</div>
            <div className="rules-block__items">
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <DataField
                    describe={t('components.rules.params.isActive')}
                    value={selectedRule?.isActive ? t('common.yes') : t('common.no')}
                    optionType={optionType}>
                    <Form.Item name="isActive">
                        <Select options={activityItems} />
                    </Form.Item>
                </DataField>
                <DataField
                    describe={t('components.rules.params.description')}
                    value={selectedRule?.description}
                    optionType={optionType}>
                    <Form.Item
                        name="description"
                        rules={[
                            { required: true, message: t('validation.required') },
                            { whitespace: true, message: t('common.disableWhitespace') },
                        ]}>
                        <TextArea rows={4} />
                    </Form.Item>
                </DataField>
                <DataField
                    describe={t('components.rules.params.shortName')}
                    value={selectedRule?.shortName}
                    optionType={optionType}>
                    <Form.Item
                        name="shortName"
                        rules={[
                            { required: true, message: t('validation.required') },
                            { whitespace: true, message: t('common.disableWhitespace') },
                        ]}>
                        <Input />
                    </Form.Item>
                </DataField>
                {groupedParams &&
                    Object.keys(groupedParams)
                        .filter((key) => groupedParams[key].length)
                        .sort((a, b) => groupOrder?.[a] - groupOrder?.[b])
                        .map((key, index) => getParam(groupedParams[key], optionType, index, t))}
            </div>
        </>
    );
};

export default Params;
