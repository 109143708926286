import React, { FC, useEffect, useState } from 'react';
import { Pagination, Spin, Table, TablePaginationConfig, TableProps } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { ColumnType } from 'antd/lib/table';
import ActionsBar from './ActionsBar';
import { ReportTypes } from '../../../../constants/enums';
import { StatusCodes } from '../../../../models/Catalog';
import { FormItemsWithInputType } from '../../../../models/Common';
import { useControlledFiltersAndSorter } from '../../hooks/useControlledFiltersAndSorter';
import { QueryType } from '../../hooks/useQuery';
import { useRowSelection } from '../../hooks/useRowSelection';

interface TableWrapperProps extends TableProps<any> {
    dataSource: TableProps<any>['dataSource'];
    loading?: boolean;
    triggerFetch?: () => void;
    isActionBar?: boolean;
    isClearFilters?: boolean;
    defaultSorter?: SorterResult<Record<string, any>>;
    reportType?: ReportTypes;
    dto?: (dataSource: any) => Record<string, any>;
    isRowSelection?: boolean;
    isEditable?: boolean;
    formItemsProps?: FormItemsWithInputType[];
    queryType?: QueryType;
    alertStatus?: StatusCodes;
    scrollable?: boolean;
    alertId?: number;
}

const defaultPagination: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    style: { display: 'none' },
};

const addControlledProps = (
    columns: ColumnType<any>[] = [],
    filters: Record<string, FilterValue | null>,
    sorters: SorterResult<Record<string, any>>,
) => {
    return columns.map((column) => ({
        ...column,
        filteredValue: filters[`${column.key}`] || null,
        sortOrder: sorters?.columnKey === column.key ? sorters.order : undefined,
    }));
};

const TableWrapper: FC<TableWrapperProps> = ({
    triggerFetch,
    loading,
    columns,
    dataSource,
    isActionBar = false,
    isClearFilters = false,
    isRowSelection = false,
    isEditable = false,
    defaultSorter,
    dto,
    reportType,
    formItemsProps,
    queryType,
    alertStatus,
    scrollable = true,
    alertId,
    ...rest
}) => {
    useEffect(() => {
        triggerFetch && triggerFetch();
    }, [triggerFetch]);

    const [currentDataSource, setCurrentDataSource] = useState<TableProps<any>['dataSource']>(dataSource);
    useEffect(() => setCurrentDataSource(dataSource), [dataSource]);

    const { filters, sorters, pagination, handleTableChange, clearAll, setPagination } = useControlledFiltersAndSorter(
        undefined,
        defaultSorter,
        defaultPagination,
    );
    const { rowSelection, selectedRows, clearSelection } = useRowSelection();

    const clearFilters = () => {
        clearAll();
        clearSelection();
        setCurrentDataSource(dataSource);
    };

    const CurrTable = (
        <Table
            size="small"
            columns={addControlledProps(columns, filters, sorters)}
            dataSource={dataSource}
            pagination={pagination}
            onChange={(pagination, filters, sorter, extra) => {
                handleTableChange(pagination, filters, sorter);
                if (extra.action === 'filter' || extra.action === 'sort') setCurrentDataSource(extra.currentDataSource);
            }}
            {...(isRowSelection && { rowSelection })}
            {...rest}
        />
    );

    return (
        // 24 висота табів
        <Spin spinning={loading || false}>
            {isActionBar && (
                <ActionsBar
                    reportType={reportType}
                    clearFilters={isClearFilters && clearFilters}
                    isFiltersApplied={Object.keys(filters).length > 0}
                    isSorterApplied={
                        sorters.columnKey !== defaultSorter?.columnKey && sorters.order !== defaultSorter?.order
                    }
                    currentDataSource={currentDataSource}
                    dto={dto}
                    isEditable={isEditable}
                    formItemsProps={formItemsProps}
                    selectedRows={selectedRows}
                    clearSelection={clearSelection}
                    queryType={queryType}
                    alertStatus={alertStatus}
                    alertId={alertId}
                />
            )}
            {scrollable && <div className="scrollable-wrapper">{CurrTable}</div>}
            {!scrollable && CurrTable}
            {currentDataSource && currentDataSource.length > 9 ? (
                <div className="card__table-pagination">
                    <Pagination
                        size="small"
                        onChange={(page) => setPagination((prev) => ({ ...prev, current: page }))}
                        defaultCurrent={1}
                        total={currentDataSource?.length}
                        showSizeChanger={false}
                    />
                </div>
            ) : null}
        </Spin>
    );
};

export default TableWrapper;
