import React, { useRef } from 'react';
import { Select } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { AlertTabRowData } from '../../../../helpers/getAlertData';
import { useTranslation } from 'react-i18next';

interface CustomSelectProps {
    options: AlertTabRowData['options'];
    mode?: boolean;
    onBlur: () => void;
    disabled: boolean;
    value?: DefaultOptionType['value'];
    onChange?: (value: any) => void;
    selectMenuContainer: HTMLDivElement;
    placeholder?: string;
}
const CustomSelect = ({
    options,
    mode,
    onBlur,
    disabled,
    value,
    onChange,
    selectMenuContainer,
    placeholder,
}: CustomSelectProps) => {
    const { t } = useTranslation();
    const selectRef = useRef<RefSelectProps>(null);

    return (
        <Select
            ref={selectRef}
            value={value}
            onChange={onChange}
            placeholder={placeholder || t('common.selectValue')}
            className="wWide"
            options={options}
            mode={mode ? 'multiple' : undefined}
            disabled={disabled}
            onBlur={onBlur}
            onDeselect={() => {
                if (selectRef.current) {
                    selectRef.current.focus();
                }
            }}
            getPopupContainer={() => selectMenuContainer}
        />
    );
};

export default CustomSelect;
