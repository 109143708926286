import { FC, ChangeEvent } from 'react';
import { Select, Spin } from 'antd';
import { selectOptions } from '../../../../helpers/selectOptionsSystemDict';
import { ColumnModel, DictionaryModel } from '../../../../models/DictionaryModel';
import { useGetSystemDictionaryQuery } from '../../../../redux/api/dictionaryApi';

interface IProps {
    column: ColumnModel;
    value: ChangeEvent<HTMLSelectElement>;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    dict?: DictionaryModel;
}

const DictionarySelect: FC<IProps> = ({ column, value, onChange, dict }) => {
    const { data, isLoading, isFetching } = useGetSystemDictionaryQuery(
        {
            dictionaryName: column.refDictName,
            disabledDict: dict?.name,
        },
        {
            skip: !column.refDictName,
        },
    );

    return (
        <Spin spinning={isLoading || isFetching}>
            <Select
                placeholder={column.description}
                options={selectOptions(data, column.refDictName?.includes('dict_rules_'))}
                value={value}
                onChange={onChange}
            />
        </Spin>
    );
};

export default DictionarySelect;
