import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { Filter } from 'odata-query';
import getContainFilterValue from './getContainFilterValue';
import { DictionaryColumnsTypes } from '../constants/enums';
import { ColumnModel } from '../models/DictionaryModel';

export const makeFilterQuery = (data: Record<string, FilterValue | null>, columns: ColumnModel[]): Filter[] => {
    let equalFilters = [];
    let containFilters = [];

    for (const key in data) {
        if (data[key] !== null) {
            const column = columns.find((column) => column.colOrmName === key);
            if (column) {
                switch (column.type.code) {
                    case DictionaryColumnsTypes.number:
                    case DictionaryColumnsTypes.string:
                        containFilters.push({
                            ['tolower(' + [key] + ')']: getContainFilterValue(data[key]?.[0] as string),
                        });
                        break;
                    case DictionaryColumnsTypes.date:
                        equalFilters.push({
                            [key]: {
                                ge: {
                                    type: 'raw',
                                    value: dayjs(data[key]?.[0] as string)
                                        .startOf('day')
                                        .toISOString(),
                                },
                                le: {
                                    type: 'raw',
                                    value: dayjs(data[key]?.[0] as string)
                                        .endOf('day')
                                        .toISOString(),
                                },
                            },
                        });
                        break;
                    case DictionaryColumnsTypes.boolean:
                        equalFilters.push({ [key]: data[key]?.[0] === '' ? null : data[key]?.[0] });
                        break;
                    case DictionaryColumnsTypes.dictionary:
                        let currKey = key;
                        let currColumn = 'description';
                        if (column.refDictName?.includes('dict_rules_')) {
                            currKey = 'rule';
                            if (key === 'rule') currColumn = 'code';
                        }
                        equalFilters.push({
                            [currKey]: { [currColumn]: getContainFilterValue(data[key]?.[0] as string) },
                        });
                        break;
                    default:
                        break;
                }
            }
        }
    }

    return [...equalFilters, ...containFilters];
};

export const filterForSearch = (columns: ColumnModel[], value: string) => {
    const searchFields = ['description', 'cyrillic', 'street', 'code', 'iso', 'rule'];
    let filter: any[] = [{ or: [] }];
    searchFields.forEach((key) => {
        if (columns.some((column) => column.colOrmName === key) && value) {
            let currKey = key;
            if (columns.some((column) => column.refDictName?.includes('dict_rules_'))) {
                currKey = `rule/${key === 'rule' ? 'code' : 'description'}`;
            }
            filter[0].or.push({ ['tolower(' + [currKey] + ')']: getContainFilterValue(value) });
        }
    });

    return filter;
};

export const getExpand = (columns: ColumnModel[]) => {
    const expand = columns
        .filter((column) => column.type.code === DictionaryColumnsTypes.dictionary)
        .map((column) => {
            if (column.refDictName?.includes('dict_rules_')) return 'rule';
            return column.colOrmName;
        })
        .join(',');
    return expand ? expand : '';
};
