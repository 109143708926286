import { notification } from 'antd';
import i18next from 'i18next';
import { BASE_NOTIFICATION_CONFIG } from '../constants/common';

export const checkUpdateStatusResponse = (response: any, status: string) => {
    const t = i18next.t;
    if (response.some((item: any) => item.error)) {
        let alertsWithError: number[] = [];
        let alertsWithoutError: number[] = [];
        response.forEach((item: any) => {
            if (item.error) alertsWithError.push(item.id);
            else alertsWithoutError.push(item.id);
        });
        if (alertsWithError.length)
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: t('helpers.checkUpdateStatusResponse.notifications.withErrors', {
                    alertsWithError: alertsWithError.join(', '),
                }),
            });
        if (alertsWithoutError.length)
            notification.success({
                ...BASE_NOTIFICATION_CONFIG,
                message: t('helpers.checkUpdateStatusResponse.notifications.withoutErrors', {
                    status,
                    alertsWithoutError: alertsWithoutError.join(', '),
                }),
            });
    } else
        notification.success({
            ...BASE_NOTIFICATION_CONFIG,
            message: t('helpers.checkUpdateStatusResponse.notifications.success', { status }),
        });
};
