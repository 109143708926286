// TODO: check runtime error while using import from index file
import { Roles, RolesUa } from './enums/Role';
import { activeStatusUa } from './enums/User';
import { translatedEnumUse } from '../helpers/translatedEnum';
import { GetUsersResponse } from '../models/UserModel';

export const userHistoryKeyDescription: { [key: string]: string } = {
    email: 'enums.userHistKeyDesc.email',
    firstName: 'enums.userHistKeyDesc.firstName',
    lastName: 'enums.userHistKeyDesc.lastName',
    middleName: 'enums.userHistKeyDesc.middleName',
    lockedDate: 'enums.userHistKeyDesc.lockedDate',
    roles: 'enums.userHistKeyDesc.roles',
    phone: 'enums.userHistKeyDesc.phone',
    first_name: 'enums.userHistKeyDesc.firstName',
    last_name: 'enums.userHistKeyDesc.lastName',
    middle_name: 'enums.userHistKeyDesc.middleName',
    locked_date: 'enums.userHistKeyDesc.lockedDate',
    role: 'enums.userHistKeyDesc.role',
};

export const activeStatusSelectOptions = translatedEnumUse(activeStatusUa, (enumObj) => [
    {
        label: enumObj.active,
        value: true,
    },
    {
        label: enumObj.inactive,
        value: false,
    },
]);

export const activeStatusFilter = translatedEnumUse(activeStatusUa, (enumObj) => [
    { text: enumObj.active, value: false },
    { text: enumObj.inactive, value: true },
]);

export const rolesFilter = translatedEnumUse(RolesUa, (enumObj) => [
    { text: enumObj.admin, value: Roles.admin },
    { text: enumObj.controller_1st, value: Roles.controller_1st },
    { text: enumObj.controller_2nd, value: Roles.controller_2nd },
    { text: enumObj.analyst, value: Roles.analyst },
]);

export const emptyData: GetUsersResponse = { count: 0, items: [] };

export const defaultSorter = 'lastName asc';

export const defaultUserQuery = {
    count: true,
};
