import React, { FC } from 'react';

import { Button, Row, Select, Spin, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { ROLE_ANALYST } from '../../../constants/Role';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { getUserName } from '../../../helpers/getUserName';
import { BaseAlert } from '../../../models/Alert';
import { useUpdateAnalystsMutation } from '../../../redux/api/alertsApi';
import { useGetEmployeesQuery } from '../../../redux/api/userApi';
import { useTranslation } from 'react-i18next';

const defaultUserQuery = {
    orderBy: 'lastName asc',
    filter: {
        roles: {
            name: ROLE_ANALYST,
        },
        lockedDate: null,
    },
};
interface AssignFormProps {
    ids: Array<BaseAlert['id']>;
    onSuccess: () => void;
}
const AssignForm: FC<AssignFormProps> = ({ ids, onSuccess }) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const { data, isLoading } = useGetEmployeesQuery(defaultUserQuery);
    const [updateAnalyst, { isLoading: isUpdating }] = useUpdateAnalystsMutation();

    const handleSubmit = () => {
        updateAnalyst({
            alerts: ids,
            userId: form.getFieldValue('employee'),
        })
            .unwrap()
            .then(() => {
                notification.success({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: `Алерт${ids.length > 1 ? 'и' : ''} успішно призначено`,
                });
                onSuccess();
            })
            .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
    };

    return (
        <Spin spinning={isLoading || isUpdating}>
            <Form
                form={form}
                onFinish={handleSubmit}
                validateMessages={{
                    required: t('validation.required'),
                }}
                layout="vertical">
                <Form.Item name="employee" rules={[{ required: true }]}>
                    <Select
                        placeholder={t('forms.alert.assign.employeePlaceholder')}
                        style={{ width: '100%' }}
                        options={data?.map((item) => ({
                            value: item.id,
                            label: getUserName(item),
                        }))}
                    />
                </Form.Item>
                <Row justify="end">
                    <Button type="primary" htmlType="submit">
                        {t('common.submit')}
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default AssignForm;
