export enum Roles {
    admin = 'admin',
    controller_1st = 'controller_1st',
    controller_2nd = 'controller_2nd',
    analyst = 'analyst',
}

export enum RolesUa {
    admin = 'enums.roles.admin',
    controller_1st = 'enums.roles.controller_1st',
    controller_2nd = 'enums.roles.controller_2nd',
    analyst = 'enums.roles.analyst',
}
