import dayjs, { Dayjs } from 'dayjs';
import i18next from 'i18next';
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME } from '../constants';

export const getDateView = (
    date?: string | Date,
    format: boolean = false,
    compare: boolean = false,
    withTime: boolean = false,
) => {
    const t = i18next.t;
    if (!date) return '';

    let result: Dayjs | string = dayjs(date);

    if (withTime) result = dayjs(date).format(DATE_FORMAT_WITH_TIME);
    if (format) result = dayjs(date).format(DATE_FORMAT);
    if (compare) {
        const now = dayjs();
        const diffYear = now.diff(date, 'year') || '';
        const diffMonth = now.diff(date, 'month') % 12 || '';

        if (diffYear || diffMonth)
            result =
                result +
                ' (' +
                (diffYear && diffYear + `${t('common.year')} `) +
                (diffMonth && diffMonth + t('common.month')) +
                ')';
    }

    return result;
};
