import React, { FC } from 'react';
import { Button, Form, notification, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { PRIORITY_CODE_IMMEDIATELY, PRIORITY_CODE_PRIORITY, PRIORITY_CODE_USUAL } from '../../../constants/Alert';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { BaseAlert } from '../../../models/Alert';
import { useUpdatePriorityMutation } from '../../../redux/api/alertsApi';
import { useGetPrioritiesQuery } from '../../../redux/api/dictionaryApi';
import { useTranslation } from 'react-i18next';

interface PriorityFormProps {
    ids: Array<BaseAlert['id']>;
    onSuccess: () => void;
}

const ALLOWED_CODES = [PRIORITY_CODE_IMMEDIATELY, PRIORITY_CODE_PRIORITY, PRIORITY_CODE_USUAL];
const PriorityForm: FC<PriorityFormProps> = ({ ids, onSuccess }) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [updatePriority, { isLoading }] = useUpdatePriorityMutation();
    const { data: priorities, isLoading: isLoadingPriorities } = useGetPrioritiesQuery();

    const handleSubmit = () => {
        updatePriority({
            ids,
            urgencyCode: form.getFieldValue('priority'),
        })
            .unwrap()
            .then(() => {
                notification.success({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: t('forms.alert.priority.notification', { description: priorities?.find((item) => item.code === form.getFieldValue('priority'))?.description }),
                });
                onSuccess();
            })
            .catch((error) =>
                notification.error({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: error.data.message,
                }),
            );
    };

    const filtered = priorities
        ?.filter((item) => ALLOWED_CODES.includes(item.code))
        .map((item) => ({ value: item.code, label: item.description }));

    return (
        <Spin spinning={isLoading}>
            <Form
                form={form}
                onFinish={handleSubmit}
                validateMessages={{
                    required: t('validation.required'),
                }}
                layout="vertical">
                <Form.Item name="priority" label={t('forms.alert.priority.label')} rules={[{ required: true }]}>
                    <Select options={filtered} loading={isLoadingPriorities} placeholder={t('forms.alert.priority.selectPlaceholder')} />
                </Form.Item>
                <Row justify="end">
                    <Button type="primary" htmlType="submit">
                        {t('common.submit')}
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default PriorityForm;
