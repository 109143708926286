import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Collapse, Row, Col, Space, Button, Table, Spin, Form, Input, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { TableRowSelection } from 'antd/lib/table/interface';
import EditableCell from './EditableCell';
import { BASE_NOTIFICATION_CONFIG } from '../../../../constants/common';
import { RuleModel, RuleParamModel } from '../../../../models/RulesModel';
import { useUpdateRuleMutation } from '../../../../redux/api/rulesApi';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface DataType {
    code: React.Key;
    description: string;
}

const components = {
    body: {
        cell: EditableCell,
    },
};

const describeColumns = (t: TFunction<"translation", undefined>) => ([
    {
        title: t('components.rules.vocabulary.columns.index'),
        dataIndex: 'index',
        render: (value: string) => <Row justify={'center'}>{value}</Row>,
        sorter: (a: any, b: any) => a.code - b.code,
        width: 80,
    },
    {
        title: t('components.rules.vocabulary.columns.description'),
        dataIndex: 'description',
        sorter: (a: any, b: any) => (a.description < b.description ? -1 : a.description > b.description ? 1 : 0),
        editable: true,
    },
]);
export interface CollapsedVocabProps {
    model: RuleParamModel;
    ruleId: number;
    isEditingMode: boolean;
}
const Vocabulary = ({ model, ruleId, isEditingMode }: CollapsedVocabProps) => {
    const { t } = useTranslation();
    const [updateRule, { isLoading: isUpdating }] = useUpdateRuleMutation();
    const [open, setOpen] = useState(false);
    const [form] = useForm();
    const [dataSource, setDataSource] = useState<Array<string>>(model.value as Array<string>);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isEdited, setEdited] = useState(false);
    const data = Array.isArray(dataSource)
        ? dataSource.map((item, index) => ({ code: index, description: item, index: index + 1 }))
        : [];

    const handleSave = (e: SyntheticEvent) => {
        e.stopPropagation();
        updateRule({
            id: ruleId,
            params: [
                {
                    id: model.id,
                    key: model.key,
                    value: dataSource,
                },
            ],
        } as RuleModel)
            .unwrap()
            .then(() => {
                setEdited(false);
                notification.success({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: t('components.rules.vocabulary.notification.success'),
                });
            })
            .catch((e) =>
                notification.error({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: e.data.message,
                }),
            );
    };
    const handleDelete = (e: SyntheticEvent) => {
        e.stopPropagation();
        setDataSource(dataSource.filter((item, index) => !selectedRowKeys.includes(index)));
        setSelectedRowKeys([]);
        setEdited(true);
    };
    const handleChangeRow = useCallback(
        (row: DataType) => {
            const newData = [...dataSource];
            const index = newData.findIndex((item, index) => row.code === index);
            if (!dataSource.includes(row.description)) {
                newData.splice(index, 1, row.description);
                setDataSource(newData);
                setEdited(true);
            } else if (newData[index] !== row.description) {
                notification.error({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: t('components.rules.vocabulary.notification.exists'),
                });
            }
        },
        [dataSource, t],
    );
    const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys), []);
    const addRecord = async () => {
        try {
            await form.validateFields();
            if (!dataSource.includes(form.getFieldValue('description'))) {
                setDataSource((prev) => [...prev, form.getFieldValue('description')]);
                form.resetFields();
                setEdited(true);
            } else {
                notification.error({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: t('components.rules.vocabulary.notification.exists'),
                });
            }
        } catch (e) {}
    };

    const columns = describeColumns(t).map((col: any) => {
        if (!col.editable || !isEditingMode) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                handleSave: handleChangeRow,
            }),
        };
    });

    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const panelHeader = (
        <Row justify="space-between">
            <Col>
                <b>{model.label}</b>
            </Col>
            {isEditingMode && (
                <Col>
                    <Space>
                        <Button
                            disabled={!open || !selectedRowKeys.length || isUpdating}
                            type="primary"
                            danger
                            onClick={handleDelete}>
                            {t('common.delete')}
                        </Button>
                        <Button disabled={!open || isUpdating || !isEdited} type="primary" onClick={handleSave}>
                            {t('common.save')}
                        </Button>
                    </Space>
                </Col>
            )}
        </Row>
    );

    return (
        <Spin spinning={isUpdating}>
            <Collapse onChange={(values) => setOpen(!!(values as Array<string>)?.length)}>
                <Collapse.Panel className="rules-collapse__vocab_panel" header={panelHeader} key="1">
                    {isEditingMode && (
                        <Form
                            form={form}
                            validateMessages={{
                                required: t('validation.required'),
                            }}
                            component={false}>
                            <div className="dictionary-select">
                                <Form.Item
                                    className="dictionary-select__input"
                                    name="description"
                                    rules={[{ required: true }, { whitespace: true, message: t('common.disableWhitespace') }]}>
                                    <Input placeholder={t('components.rules.vocabulary.addRecord')} autoComplete="off" />
                                </Form.Item>
                                <Button onClick={addRecord}>{t('common.add')}</Button>
                            </div>
                        </Form>
                    )}
                    <Table
                        size="small"
                        components={components}
                        rowClassName={() => 'editable-row'}
                        rowSelection={isEditingMode ? rowSelection : undefined}
                        rowKey="code"
                        bordered={false}
                        dataSource={data}
                        columns={columns}
                    />
                </Collapse.Panel>
            </Collapse>
        </Spin>
    );
};

export default Vocabulary;
