import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import en_GB from 'antd/locale/en_GB';
import uk_UA from 'antd/locale/uk_UA';
import dayjs from 'dayjs';
import MainLayout from './components/MainLayout';
import PrivateRoute from './components/PrivateRoute';
import { mainTheme } from './constants/InterfaceThemes';
import { RouteCode } from './constants/enums';
import './css/App.css';
import ChangePassword from './pages/ChangePassword';
import Login from './pages/Login';
import { Locale } from 'antd/lib/locale';

const antdLocales: { [key: string]: Locale } = {
    en: en_GB,
    uk: uk_UA,
};

function App() {
    const { i18n } = useTranslation();
    const [antdLocale, setAntdLocale] = useState(antdLocales[i18n.language]);
    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
        const handleChangeLang = (lang: string) => {
            dayjs.locale(lang);
            setAntdLocale(antdLocales[i18n.language]);
            setForceUpdate((prev) => prev + 1);
        };

        i18n.on('languageChanged', handleChangeLang);

        return () => {
            i18n.off('languageChanged', handleChangeLang);
        };
    }, [i18n]);

    return (
        <div className="App">
            <ConfigProvider theme={mainTheme} locale={antdLocale} key={forceUpdate}>
                <Routes>
                    <Route path={`/${RouteCode.login}`} element={<Login />} />
                    <Route path={`/${RouteCode.changePassword}`} element={<ChangePassword />} />
                    <Route
                        path="*"
                        element={
                            <PrivateRoute>
                                <MainLayout />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </ConfigProvider>
        </div>
    );
}

export default App;
