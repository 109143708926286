import { FC, useState } from 'react';
import { Button, Col, Form, Modal, Row, Space, Spin } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useForm } from 'antd/es/form/Form';
import cronstrue from 'cronstrue';
import 'cronstrue/locales/uk';
import { baseSuccessNotification } from '../../../helpers/baseSuccessNotification';
import { Schedule } from '../../../models/Schedule';
import { useUpdateIntervalMutation } from '../../../redux/api/schedulesApi';
import { Trans, useTranslation } from 'react-i18next';

interface IntervalEditModalProps {
    schedule: Schedule;
    onSuccess: () => void;
}

interface IntervalsForm {
    interval_0: string;
    interval_1: string;
    interval_2: string;
    interval_3: string;
    interval_4: string;
}
const intervalKeys: (keyof IntervalsForm)[] = ['interval_0', 'interval_1', 'interval_2', 'interval_3', 'interval_4'];

const CronInputGroup: FC<{ cron: string | null; disabled?: boolean }> = ({ cron, disabled }) => {
    const cronParts = (cron || '* * * * *').split(' ');
    const cronValidationRegex = /^[\d*\\*][,\\/]?(?:[\d\\,-]*)$/;

    return (
        <Space>
            {cronParts.map((part, index) => {
                const key = 'interval_' + index + (disabled ? '_disabled' : '');
                return (
                    <Form.Item
                        {...(!disabled ? { name: key } : {})}
                        key={key}
                        initialValue={part}
                        rules={[{ required: true }]}>
                        <MaskedInput mask={cronValidationRegex} disabled={disabled} value={part} />
                    </Form.Item>
                );
            })}
        </Space>
    );
};

const IntervalEditModal: FC<IntervalEditModalProps> = ({ schedule, onSuccess }) => {
    const { t, i18n } = useTranslation();
    const [form] = useForm();
    const [cron, setCron] = useState<string>(schedule.interval || '');
    const [updateInterval, { isLoading }] = useUpdateIntervalMutation();

    const getCron = (cron: string) => {
        if (cron)
            try {
                return cronstrue.toString(cron, { locale: i18n.language });
            } catch (e) {
                return 'Invalid cron';
            }
    };
    const handleFormChange = (_changedValues: Partial<IntervalsForm>, allValues: IntervalsForm) => {
        const cronExpression = intervalKeys.map((part) => allValues[part]).join(' ');
        setCron(cronExpression);
    };

    const handleSubmit = () => {
        if (getCron(cron) === 'Invalid cron' || !cron || cron === schedule.interval) {
            return;
        }
        Modal.confirm({
            title: t('common.confirm'),
            content: t('pages.schedulers.intervalEdit.modal.confirm'),
            onOk: () =>
                updateInterval({ id: schedule.id, interval: cron }).then(() => {
                    onSuccess();
                    baseSuccessNotification(t('pages.schedulers.intervalEdit.notifications.success'));
                }),
            okText: t('common.yes'),
            cancelText: t('common.return'),
            style: { top: '20%' },
        });
    };
    return (
        <Spin spinning={isLoading}>
            <Form<IntervalsForm>
                form={form}
                onFinish={handleSubmit}
                onValuesChange={handleFormChange}
                validateMessages={{ required: t('validation.required') }}
                layout="vertical">
                <Trans i18nKey="pages.schedulers.intervalEdit.fields.title" />
                <Row>
                    <Col span={10}>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.fields.items.1" /></Row>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.fields.items.2" /></Row>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.fields.items.3" /></Row>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.fields.items.4" /></Row>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.fields.items.5" /></Row>
                    </Col>
                    <Col span={14}>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.symbols.title" /></Row>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.symbols.items.1" /></Row>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.symbols.items.2" /></Row>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.symbols.items.3" /></Row>
                        <Row><Trans i18nKey="pages.schedulers.intervalEdit.symbols.items.4" /></Row>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginTop: 10 }}>
                    <CronInputGroup cron={schedule.interval} />
                    {getCron(cron)}
                </Row>
                <Row justify="center" style={{ marginTop: 10 }}>
                    <Button type="primary" htmlType="submit">
                        <Trans i18nKey="common.submit" />
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default IntervalEditModal;
