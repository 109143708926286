import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar, Button, Col, Layout, Radio, Row } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { colorPrimary } from '../../constants/InterfaceThemes';
import { useLogoutUserMutation } from '../../redux/api/authApi';
import { logout } from '../../redux/features/authSlice';
import { useAppDispatch } from '../../redux/hooks';

const enableMultiLang = ['true', 'True', 'TRUE'].includes(process.env.REACT_APP_ENABLE_MULTI_LANG || 'false');
const { Header: AntHeader } = Layout;

const langOptions = [
    { label: 'Ук', value: 'uk' },
    { label: 'En', value: 'en' },
];

interface HeaderProps {
    name?: string;
}
const Header: FC<HeaderProps> = ({ name }) => {
    const { i18n } = useTranslation();
    const [logoutUser] = useLogoutUserMutation();
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        logoutUser().then(() => dispatch(logout()));
    };

    const handleLanguage = (event: any) => {
        const lang = event.target.value;
        i18n.changeLanguage(lang);
    };

    return (
        <AntHeader>
            <Row justify="end" gutter={8}>
                {enableMultiLang && (
                    <Col>
                        <Radio.Group
                            options={langOptions}
                            onChange={handleLanguage}
                            value={i18n.language}
                            optionType="button"
                            buttonStyle="solid"
                            size="small"
                        />
                    </Col>
                )}
                <Col>
                    <Avatar size="large" style={{ backgroundColor: colorPrimary }}>
                        {name?.[0]?.toUpperCase()}
                    </Avatar>
                </Col>
                <Col>
                    <Trans i18nKey="components.header.welcome" />, {name}
                </Col>
                <Col>
                    <Button onClick={handleLogout} icon={<LogoutOutlined />} />
                </Col>
            </Row>
        </AntHeader>
    );
};

export default Header;
