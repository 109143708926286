import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Progress, Row, Tag, Tooltip, Typography, notification } from 'antd';
import { TFunction } from 'i18next';
import { STATUS_CODES } from '../../../constants';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { isObject } from '../../../helpers/valueTypeCheck';
import { Session, Status } from '../../../models/Session';
import {
    GetSessionCompletionPercentResponse,
    useLazyGetSessionCompletionPercentQuery,
} from '../../../redux/api/rulesApi';

interface Props {
    value: Status;
    record: Session;
}

interface ProgressBodyProps {
    item: GetSessionCompletionPercentResponse;
}

const handleStatusClick = (session: Session, t: TFunction) => {
    switch (session.status?.code) {
        case STATUS_CODES.success:
            return notification.success({
                ...BASE_NOTIFICATION_CONFIG,
                message: t('pages.sessionRegister.statusRender.notifications.success'),
            });
        case STATUS_CODES.terminated:
            return notification.warning({
                ...BASE_NOTIFICATION_CONFIG,
                message: t('pages.sessionRegister.statusRender.notifications.terminated'),
            });
        case STATUS_CODES.in_progress:
            return notification.info({
                ...BASE_NOTIFICATION_CONFIG,
                message: t('pages.sessionRegister.statusRender.notifications.in_progress'),
            });
        case STATUS_CODES.waiting:
            return notification.info({
                ...BASE_NOTIFICATION_CONFIG,
                message: t('pages.sessionRegister.statusRender.notifications.waiting'),
            });
        case STATUS_CODES.has_errors:
            let errorNum: string[] = [];
            session.errors?.forEach((error, index) => {
                if (isObject(error.errorStack))
                    errorNum.push(`${index + 1}. ${error.stage} - ORA-${error.errorStack?.errorNum}`);
                else errorNum.push(`${index + 1}. ${error.stage} - ${error.errorStack}`);
            });
            let errorText = (
                <div className="session-error-message">
                    {errorNum.map((error, index) => (
                        <span key={index}>
                            <br />
                            {error}
                        </span>
                    ))}
                </div>
            );
            return notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: t('pages.sessionRegister.statusRender.notifications.has_errors'),
                description: errorText,
                duration: 4,
                style: { width: 500 },
            });

        default:
            break;
    }
};

const StatusRender: React.FC<Props> = ({ value, record }) => {
    const { t } = useTranslation();
    const [trigger, { data }] = useLazyGetSessionCompletionPercentQuery();

    const handleOpenChange = (open: boolean) => open && trigger(record?.interval?.code);

    const StatusTag = (
        <Tag color={value?.color} onClick={() => handleStatusClick(record, t)} style={{ cursor: 'pointer' }}>
            {value?.description}
        </Tag>
    );

    const ProgressBody = ({ item }: ProgressBodyProps) => (
        <>
            <Typography.Text type="secondary">{item?.desc}</Typography.Text>
            <Progress percent={item?.percentage} className="session-progress-bar" />
            {item?.totalCount && (
                <Typography.Text color="black">{` ${item?.count || 0} / ${item.totalCount}`}</Typography.Text>
            )}
        </>
    );

    const ProgressBar = () => {
        const { create, rules } = data || {};
        if (!data) {
            return (
                <>
                    <Alert
                        message={t('pages.sessionRegister.statusRender.alert.message')}
                        description={t('pages.sessionRegister.statusRender.alert.description')}
                        type="warning"
                        className="session-empty-progress"
                    />
                </>
            );
        }
        return (
            <Row gutter={[8, 16]}>
                <Col span={24}>{rules && <ProgressBody item={rules} />}</Col>
                <Col span={24}>{create && <ProgressBody item={create} />}</Col>
            </Row>
        );
    };

    const isSessionInProgress =
        value?.code && [STATUS_CODES.in_progress, STATUS_CODES.result_processing].includes(value?.code);

    return (
        <>
            {!isSessionInProgress && StatusTag}
            {isSessionInProgress && (
                <Tooltip
                    color={'white'}
                    title={ProgressBar}
                    onOpenChange={handleOpenChange}
                    overlayClassName="session-progress-tooltip">
                    {StatusTag}
                </Tooltip>
            )}
        </>
    );
};

export default StatusRender;
