import React, { FC, useCallback, useEffect } from 'react';
import { Spin, Form, Button, Row, notification } from 'antd';
import { RoleModel } from '../../../models/RoleModel';
import { useForm } from 'antd/lib/form/Form';
import { useUpdateRoleMutation } from '../../../redux/api/rolesApi';
import { MenuFunctionModel } from '../../../models/MenuFunctionModel';
import RoleDataFields from './RoleDataFields';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { useTranslation } from 'react-i18next';
interface UpdateRoleFormProps {
    role: RoleModel;
    onSuccess: () => void;
}
const UpdateRoleForm: FC<UpdateRoleFormProps> = ({ role, onSuccess }) => {
    const { t } = useTranslation();
    const [form] = useForm<RoleModel>();
    const [updateRole, { isLoading }] = useUpdateRoleMutation();
    const submit = useCallback(
        (values: RoleModel) => {
            if (role) {
                const result: RoleModel = {
                    ...values,
                    menuFunctions:
                        values?.menuFunctions && values?.menuFunctions.length > 0
                            ? values.menuFunctions.map((item: any) => ({ id: item } as MenuFunctionModel))
                            : null,
                } as RoleModel;
                updateRole(result)
                    .unwrap()
                    .then(() => onSuccess())
                    .catch((error: any) => {
                        notification.error({
                            ...BASE_NOTIFICATION_CONFIG,
                            message: error.error,
                        });
                    });
            }
        },
        [role, onSuccess, updateRole],
    );

    useEffect(() => {
        if (role) {
            form.setFieldsValue({
                ...role,
                menuFunctions: role?.menuFunctions ? role?.menuFunctions.map((item: MenuFunctionModel) => item.id) : [],
            } as any);
        }
    }, [form, role]);

    return (
        <Spin spinning={isLoading}>
            <Form<any>
                form={form}
                onFinish={submit}
                validateMessages={{
                    required: t('validation.required'),
                }}>
                <RoleDataFields />
                <Row justify="end">
                    <Button type="primary" htmlType="submit">
                        {t('common.submit')}
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default UpdateRoleForm;
