import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormProps, notification, Row, Select, Spin, Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { TFunction } from 'i18next';
import Conditions from './Conditions';
import Header from './Header';
import History from './History';
import Params from './Params';
import Dictionary from './vocabulary/Dictionary';
import Vocabulary from './vocabulary/Vocabulary';
import {
    RULES_OPTION_PARAMETER_TYPE_DICTIONARY,
    RULES_OPTION_PARAMETER_TYPE_VOCABULARY,
    RULES_OPTIONS_TYPE_EDIT,
    RULES_OPTIONS_TYPE_VIEW,
    ACTIVITY_ALL,
    ACTIVITY_ACTIVE,
    ACTIVITY_DISABLE,
} from '../../../constants/Rules';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { rulesParamsDto } from '../../../helpers/dto/rulesParamsDto';
import { numberToInputString } from '../../../helpers/numberToInputString';
import { OptionsType, RuleModel, RuleParamModel } from '../../../models/RulesModel';
import { useGetSystemDictionaryQuery } from '../../../redux/api/dictionaryApi';
import { useGetRuleQuery, useLazyExitEditingModeQuery, useUpdateRuleMutation } from '../../../redux/api/rulesApi';
import TabsSelect from '../../common/TabsSelect';

interface ContentProps {
    collection: Array<RuleModel>;
    refetchCollection: () => void;
}

const activityOptions = (t: TFunction<'translation', undefined>) => [
    { label: t('components.rules.content.activityOptions.all'), value: ACTIVITY_ALL },
    { label: t('components.rules.content.activityOptions.active'), value: ACTIVITY_ACTIVE },
    { label: t('components.rules.content.activityOptions.disactive'), value: ACTIVITY_DISABLE },
];

const Content: FC<ContentProps> = ({ collection, refetchCollection }) => {
    const { t } = useTranslation();
    const [updateRule, { isLoading: isUpdating }] = useUpdateRuleMutation();
    const [selectedId, setSelectedId] = useState<string>();
    const [optionType, setOptionType] = useState<OptionsType>(RULES_OPTIONS_TYPE_VIEW);
    const [showHistory, setShowHistory] = useState(false);
    const [form] = useForm<RuleModel>();
    const [isEdited, setIsEdited] = useState(false);
    const [activity, setActivity] = useState(ACTIVITY_ALL);
    const {
        data: selectedRule,
        error,
        isLoading,
        isFetching,
        refetch,
    } = useGetRuleQuery(selectedId, { skip: !selectedId });
    const { data: dictParamsGroupTypes } = useGetSystemDictionaryQuery({
        dictionaryName: 'dict_rule_option_params_group_type',
    });
    const [isEditingMode, setIsEditingMode] = useState(false);
    const [exitEditingMode] = useLazyExitEditingModeQuery();

    const rules = useMemo(
        () =>
            collection.filter((rule) =>
                activity === ACTIVITY_ALL ? rule : activity === ACTIVITY_ACTIVE ? rule.isActive : !rule.isActive,
            ),
        [collection, activity],
    );
    const toggleHistoryPage = useCallback(() => setShowHistory((prev) => !prev), []);
    const toggleEditMode = useCallback(
        () =>
            setOptionType((prev) =>
                prev === RULES_OPTIONS_TYPE_VIEW ? RULES_OPTIONS_TYPE_EDIT : RULES_OPTIONS_TYPE_VIEW,
            ),
        [],
    );
    const handleTabClick = useCallback((activeKey: string) => {
        setSelectedId(activeKey);
        setShowHistory(false);
    }, []);
    const onFinish: FormProps<RuleModel>['onFinish'] = (values) => {
        const result = rulesParamsDto(values);

        updateRule(result)
            .unwrap()
            .then(() => {
                selectedRule && exitEditingMode(selectedRule?.id);
                toggleEditMode();
                setIsEdited(false);
                setIsEditingMode(false);
                notification.success({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: t('components.rules.content.notification.success'),
                });
            })
            .catch((error) => {
                toggleEditMode();
                notification.error({ ...BASE_NOTIFICATION_CONFIG, duration: 5, message: error.data.message });
            });
    };
    const discardChanges = () => {
        if (selectedRule) {
            const initialData = { ...selectedRule };
            initialData.params = (initialData.params as RuleParamModel[])?.map((param) => {
                if (param.type?.code === 'number') {
                    return { ...param, value: numberToInputString(param.value as number, param.fraction) };
                }
                return param;
            });
            form.setFieldsValue(initialData);
            setIsEdited(false);
        }
    };

    useEffect(() => {
        if (selectedRule) {
            const initialData = { ...selectedRule };
            initialData.params = (initialData.params as RuleParamModel[])?.map((param) => {
                if (param.type?.code === 'number') {
                    return { ...param, value: numberToInputString(param.value as number, param.fraction) };
                }
                return param;
            });
            form.setFieldsValue({ ...initialData, score: selectedRule.score?.toString() as string });
        }
    }, [form, selectedRule]);

    useEffect(() => {
        if (rules.length && !selectedId) {
            setSelectedId(rules[0].id?.toString());
        }
    }, [rules, selectedId]);

    useEffect(() => {
        if (activity !== ACTIVITY_ALL && rules[0]) {
            setSelectedId(rules[0].id?.toString());
        }
    }, [activity, rules]);

    useEffect(() => {
        if (error && 'status' in error) {
            const errorData = error.data as { message?: string };
            notification.error({ ...BASE_NOTIFICATION_CONFIG, message: errorData.message });
        }
    }, [error]);

    const refetchAll = () => {
        refetchCollection();
        refetch();
    };

    return (
        <>
            <Row gutter={16}>
                <Col>
                    <div className="rules-filter__item">
                        <label htmlFor="shortName">{t('components.rules.content.search')}</label>
                        <TabsSelect
                            activeTab={selectedId}
                            onChangeSelect={handleTabClick}
                            options={rules?.map((item) => ({ label: item.shortName, value: `${item.id}` }))}
                            id="shortName"
                            disabled={optionType === RULES_OPTIONS_TYPE_EDIT}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="rules-filter__item">
                        <label htmlFor="activity">{t('components.rules.content.filter')}</label>
                        <Select
                            className="activity-type"
                            value={activity}
                            onChange={(value) => setActivity(value)}
                            options={activityOptions(t)}
                            id="activity"
                            disabled={optionType === RULES_OPTIONS_TYPE_EDIT}
                        />
                    </div>
                </Col>
            </Row>
            <Tabs
                activeKey={selectedId}
                onChange={handleTabClick}
                defaultActiveKey="1"
                tabPosition="left"
                destroyInactiveTabPane
                items={rules?.map((rule) => {
                    return {
                        label: (
                            <span className={`antd__tab ${rule.isActive === 0 ? 'rules-tabs__label_inactive' : ''}`}>
                                {rule.shortName}
                            </span>
                        ),
                        key: `${rule.id}`,
                        disabled: optionType === RULES_OPTIONS_TYPE_EDIT,
                        children: (
                            <div className="tab_children">
                                <Spin spinning={isLoading || isFetching || isUpdating}>
                                    <Form
                                        form={form}
                                        validateMessages={{
                                            required: t('validation.required'),
                                        }}
                                        onFinish={onFinish}
                                        validateTrigger="onSubmit"
                                        onValuesChange={() => setIsEdited(true)}>
                                        {selectedRule && !showHistory && (
                                            <div className="rules-content">
                                                <Header
                                                    selectedRule={selectedRule}
                                                    onOpenHistory={toggleHistoryPage}
                                                    toggleEditMode={toggleEditMode}
                                                    optionType={optionType}
                                                    isEdited={isEdited}
                                                    onCancel={discardChanges}
                                                    isEditingMode={isEditingMode}
                                                    setIsEditingMode={setIsEditingMode}
                                                    refetchRule={refetchAll}
                                                />
                                                <Params
                                                    selectedRule={selectedRule}
                                                    optionType={optionType}
                                                    dictParamsGroupTypes={dictParamsGroupTypes}
                                                />
                                                <Conditions selectedRule={selectedRule} optionType={optionType} />
                                                {selectedRule?.catalog?.map((param) =>
                                                    param.type?.code === RULES_OPTION_PARAMETER_TYPE_DICTIONARY ? (
                                                        <Dictionary
                                                            key={param.id}
                                                            model={param}
                                                            ruleId={selectedRule.id}
                                                            isEditingMode={isEditingMode}
                                                        />
                                                    ) : param.type?.code === RULES_OPTION_PARAMETER_TYPE_VOCABULARY ? (
                                                        <Vocabulary
                                                            key={param.id}
                                                            model={param}
                                                            ruleId={selectedRule.id}
                                                            isEditingMode={isEditingMode}
                                                        />
                                                    ) : null,
                                                )}
                                            </div>
                                        )}
                                        {selectedRule && showHistory && (
                                            <History selectedRule={selectedRule} onClose={toggleHistoryPage} />
                                        )}
                                    </Form>
                                </Spin>
                            </div>
                        ),
                    };
                })}
            />
        </>
    );
};

export default Content;
