import { RefObject } from 'react';
import { InputRef } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import { DATE_FORMAT_WITH_TIME, USERS_ACTIONS_TABLE } from '../../../constants';
import { getUniqueUserByRole, getUserName } from '../../../helpers';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { RoleModel } from '../../../models/RoleModel';
import { UserModel } from '../../../models/UserModel';
import { UsersActions } from '../../../models/UsersActions';
import { Trans } from 'react-i18next';

const defaultColumnsWidth = {
    0: 46,
    1: 46,
    2: 46,
    3: 136,
} as const;

export const stringContainsColumns = ['action'];
export const stringExistColumns = ['id', 'userId'];
export const dateColumns = ['createdDate'];
export const nameColumns = ['user'];

export const getUsersActionsColumns = (
    sizes: Record<string, number>,
    ref: RefObject<InputRef>,
    getRoles: () => void,
    roles?: RoleModel[],
): ColumnsType<UsersActions> => {
    return [
        {
            title: (
                <ResizableColumn colIndex={0} storageKey={USERS_ACTIONS_TABLE}>
                    <Trans i18nKey="pages.systemLog.columns.usersActions.id" />
                </ResizableColumn>
            ),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: sizes?.[0] || defaultColumnsWidth[0],
            ...getColumnSearchProps<UsersActions>(ref, 'string'),
            sorter: true,
            defaultSortOrder: 'descend',
        },
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={USERS_ACTIONS_TABLE}>
                    <Trans i18nKey="pages.systemLog.columns.usersActions.userId" />
                </ResizableColumn>
            ),
            dataIndex: 'userId',
            key: 'userId',
            ellipsis: true,
            width: sizes?.[1] || defaultColumnsWidth[1],
            render: (user: UserModel) => getUserName(user),
            onFilterDropdownOpenChange: (visible) => visible && !roles && getRoles(),
            filters: getUniqueUserByRole(roles),
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={USERS_ACTIONS_TABLE}>
                    <Trans i18nKey="pages.systemLog.columns.usersActions.createdDate" />
                </ResizableColumn>
            ),
            dataIndex: 'createdDate',
            key: 'createdDate',
            ellipsis: true,
            width: sizes?.[2] || defaultColumnsWidth[2],
            ...getColumnSearchProps<UsersActions>(ref, 'date'),
            render: (value) => (value ? dayjs(value).format(DATE_FORMAT_WITH_TIME) : ''),
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={USERS_ACTIONS_TABLE}>
                    <Trans i18nKey="pages.systemLog.columns.usersActions.action" />
                </ResizableColumn>
            ),
            dataIndex: 'action',
            key: 'action',
            ellipsis: true,
            width: sizes?.[3] || defaultColumnsWidth[3],
            ...getColumnSearchProps<UsersActions>(ref, 'string'),
        },
    ];
};
