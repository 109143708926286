import i18next from 'i18next';
import HistoryRow from '../components/common/HistoryRow';
import { AlertFields } from '../constants/enums';
import { RoleModel } from '../models/RoleModel';
import { RuleModel } from '../models/RulesModel';
import { UserModel } from '../models/UserModel';
import { Trans } from 'react-i18next';

const possibleAccKey = ['account', 'nbs'];
const possibleFromKey = ['timeFrom', 'beforeAge', 'from_value', 'day_from'];
const possibleToKey = ['timeTo', 'afterAge', 'to_value', 'day_to'];
const booleanKey = [
    'is_active',
    'isActive',
    AlertFields.massRegistrationAddress,
    AlertFields.isRequestReceived,
    AlertFields.isDocumentsProvided,
    AlertFields.isIndependentActivity,
    AlertFields.isClientTrustor,
    AlertFields.isClientTrustedPerson,
    AlertFields.isSendingRequestToCustomer,
    AlertFields.isCustomerDeclinedProvidingDocuments,
    AlertFields.isAddressMassRegistration,
    AlertFields.isClientBeneficiary,
    AlertFields.isManyVED,
    AlertFields.isHeadHeadInOtherCompanies,
    AlertFields.isHeadCFOinOtherCompanies,
    AlertFields.isCFOHeadInOtherCompanies,
    AlertFields.isCFOCFOInOtherCompanies,
];
const activeUserKey = ['locked_date', 'lockedDate'];
const rolesKey = ['roles', 'role'];
const rulesKey = ['rule', 'rules'];
const possibleUnitFromKey = ['unit_from', 'unit', 'unitFrom'];
const possibleUnitToKey = ['unit_to', 'unit', 'unitTo'];

export enum PeriodCatalog {
    months = 'місяців',
    days = 'днів',
    hours = 'години',
    minutes = 'хвилини',
    seconds = 'секунди',
}

export enum HistoryTypes {
    alerts = 'alerts',
    users = 'users',
    rules = 'rules',
    dictionaries = 'dictionaries',
}

const getFullName = (user?: Partial<UserModel>, usersDict?: UserModel[]) => {
    let userData = user;
    if (user === null) userData = usersDict?.find((user) => user.id === 999);
    return `${userData?.lastName || ''} ${userData?.firstName || ''} ${userData?.middleName || ''}`;
};

export const getHistoryValue = (
    value: Record<string, any>,
    rolesDict?: RoleModel[],
    blockCode?: HistoryTypes,
    index?: number,
) => {
    const t = i18next.t;
    const valueObjectKey = value && Object.keys(value)[index || 0];
    let rowValue = valueObjectKey === 'params' ? value?.params?.value : value?.[valueObjectKey];

    // if rowValue is object
    if (typeof rowValue === 'object' && rowValue !== null && !Array.isArray(rowValue)) {
        // if object is instance of User
        if (rowValue?.firstName || rowValue?.lastName) {
            rowValue = getFullName(rowValue);
        }
        // if object have params with description 'Період'
        else if (value.params?.description.includes('Період')) {
            rowValue = rowValue.value + ' ' + (PeriodCatalog[rowValue.unit as keyof typeof PeriodCatalog] || '');
        }
        // if object keys include 'from' and 'to'
        else if (
            Object.keys(rowValue).find(
                (key) =>
                    possibleFromKey.includes(key) ||
                    key.includes('from') ||
                    possibleToKey.includes(key) ||
                    key.includes('to'),
            )
        ) {
            const fromValue =
                rowValue[
                    `${Object.keys(rowValue).find((key) => possibleFromKey.includes(key) || key.includes('from'))}`
                ];
            const fromValueType =
                rowValue[`${Object.keys(rowValue).find((key) => possibleUnitFromKey.includes(key))}`] || '';
            const toValue =
                rowValue[`${Object.keys(rowValue).find((key) => possibleToKey.includes(key) || key.includes('to'))}`];
            const toValueType =
                rowValue[`${Object.keys(rowValue).find((key) => possibleUnitToKey.includes(key))}`] || '';
            rowValue =
                'Від: ' +
                fromValue +
                (fromValueType ? ' ' : '') +
                (PeriodCatalog[fromValueType as keyof typeof PeriodCatalog] || '') +
                '; До: ' +
                toValue +
                (toValueType ? ' ' : '') +
                (PeriodCatalog[toValueType as keyof typeof PeriodCatalog] || '') +
                ';';
        }
        // if object key include 'code'
        else if (Object.keys(rowValue).find((key) => key === 'code')) {
            rowValue = value[valueObjectKey]?.description || value[valueObjectKey]?.code;
        }
        // if object key === rules (for map approved and disapproved rules)
        else if (rulesKey.includes(valueObjectKey)) {
            rowValue =
                value[valueObjectKey]?.description &&
                `${value[valueObjectKey]?.description} - ${!value[valueObjectKey].approved ? `${t('common.not')} ` : ''}${t('common.approve')}`;
        }
        // default case for object
        else {
            const value = rowValue?.value || rowValue?.description || t('enums.common.historyNoData');
            const valueType = rowValue?.unit ? ` ${PeriodCatalog[rowValue.unit as keyof typeof PeriodCatalog]}` : '';
            rowValue = value + valueType;
        }
    } else if (Array.isArray(rowValue) && rowValue.every((item) => typeof item === 'string')) {
        rowValue = rowValue.join(', ');
    } else if (Array.isArray(rowValue)) {
        // if block is criterions or indicators - map accounts
        if (blockCode === HistoryTypes.rules) {
            rowValue = rowValue
                .map((item) =>
                    item
                        ? item[`${Object.keys(item).find((key) => possibleAccKey.includes(key))}`] +
                          `${item.ob22?.length ? ': ' + item.ob22.join(',') : ''}`
                        : '',
                )
                .join('; ');
        } else if (rowValue.some((item) => item.edrpou && item.name && item.relationType)) {
            rowValue = rowValue.map((item) => `${item.edrpou}, ${item.name}, ${item.relationType}`).join(';\n');
        } else {
            rowValue = rowValue.map((item) => item?.description).join(', ');
        }
    } else if (rowValue in PeriodCatalog) {
        rowValue = PeriodCatalog[rowValue as keyof typeof PeriodCatalog];
    } else if (booleanKey.includes(valueObjectKey)) {
        rowValue = +rowValue ? t('common.yes') : t('common.no');
    } else if (activeUserKey.includes(valueObjectKey)) {
        rowValue = !!rowValue ? t('enums.activeStatus.inactive') : t('enums.activeStatus.active');
        //TODO: rolesKey is always undefined, when refactor userHistory to common history view change it
    } else if (rolesKey.includes(valueObjectKey)) {
        rowValue = rolesDict?.find((role) => role.name === rowValue)?.description || rowValue;
    }

    return rowValue;
};

export const getHistoryRow = (value: Partial<RuleModel>, blockCode?: HistoryTypes) => {
    return value ? (
        Object.keys(value).map((key, index) => {
            return <HistoryRow value={getHistoryValue(value, undefined, blockCode, index)} key={key} />;
        })
    ) : (
        <div><Trans i18nKey="enums.common.historyNoData" /></div>
    );
};
