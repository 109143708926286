import { translatedEnumUse } from '../helpers/translatedEnum';
import { HISTORY_STATUS } from './enums';

export const statusCatalog: { [key: string]: string } = {
    update: 'enums.history.statusCatalog.update',
    create: 'enums.history.statusCatalog.create',
    remove: 'enums.history.statusCatalog.remove',
};

export const calendarHistoryStatusDesc: { [key: string]: string } = {
    [HISTORY_STATUS.update]: 'enums.history.calendarHistoryStatusDesc.update',
    [HISTORY_STATUS.create]: 'enums.history.calendarHistoryStatusDesc.create',
    [HISTORY_STATUS.remove]: 'enums.history.calendarHistoryStatusDesc.remove',
};

export const rulesFields: { [key: string]: string } = {
    description: 'enums.history.rulesFields.description',
    shortName: 'enums.history.rulesFields.shortName',
    expireInterval: 'enums.history.rulesFields.expireInterval',
    interval: 'enums.history.rulesFields.interval',
    isActive: 'enums.history.rulesFields.isActive',
    riskLevel: 'enums.history.rulesFields.riskLevel',
    score: 'enums.history.rulesFields.score',
    type: 'enums.history.rulesFields.type',
    urgencyType: 'enums.history.rulesFields.urgencyType',
    scoreStateEnterprise: 'enums.history.rulesFields.scoreStateEnterprise',
};

export const statusFilter = translatedEnumUse(statusCatalog, (enumObj) => [
    {
        text: enumObj.update,
        value: 'update',
    },
    {
        text: enumObj.create,
        value: 'create',
    },
    {
        text: enumObj.remove,
        value: 'remove',
    },
]);

export const defaultHistoryQuery = {
    orderBy: 'createdDate desc',
    count: true,
};
