import React, { FC } from 'react';

import { Button, Col, notification, Row, Spin } from 'antd';

import { STATUS_CODE_AGREED } from '../../../constants/Alert';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { STATUS_CODES_TRANSLATION } from '../../../constants/enums';
import { checkUpdateStatusResponse } from '../../../helpers/checkUpdateStatusResponse';
import { BaseAlert } from '../../../models/Alert';
import { useUpdateStatusMutation } from '../../../redux/api/alertsApi';
import { getUser } from '../../../redux/features/authSlice';
import { useAppSelector } from '../../../redux/hooks';
import { Trans } from 'react-i18next';
import { TranslatedEnum } from '../../../helpers/translatedEnum';

interface AgreeAlertProps {
    ids: Array<BaseAlert['id']>;
    onSuccess: () => void;
    onCancel: () => void;
}
const AgreeForm: FC<AgreeAlertProps> = ({ ids, onSuccess, onCancel }) => {
    const [updateStatus, { isLoading }] = useUpdateStatusMutation();
    const user = useAppSelector(getUser);

    const submit = () => {
        updateStatus({ alerts: ids, statusCode: STATUS_CODE_AGREED, controller: user?.id })
            .unwrap()
            .then((res) => {
                checkUpdateStatusResponse(res, TranslatedEnum(STATUS_CODES_TRANSLATION)[STATUS_CODE_AGREED]);
                onSuccess();
            })
            .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
    };
    return (
        <Spin spinning={isLoading}>
            <div><Trans i18nKey="common.acceptMsg" /></div>
            <Row justify="end" gutter={16}>
                <Col>
                    <Button onClick={onCancel}><Trans i18nKey="common.no" /></Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={submit}>
                        <Trans i18nKey="common.yes" />
                    </Button>
                </Col>
            </Row>
        </Spin>
    );
};

export default AgreeForm;
