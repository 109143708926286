import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Table, TableProps } from 'antd';
import { Modal as ModalAntd } from 'antd';
import IntervalEditModal from './components/IntervalEditModal';
import SchedulersHistory from './components/SchedulersHistory';
import { getColumns } from './components/getColumns';
import { HandleClick } from './models/HandleClick';
import Modal from '../../components/common/Modal';
import { SCHEDULERS_TABLE } from '../../constants';
import { baseSuccessNotification } from '../../helpers/baseSuccessNotification';
import { useModal } from '../../hooks';
import { useHandleErrors } from '../../hooks/useHandleError';
import { Schedule } from '../../models/Schedule';
import { useGetSchedulesQuery, useSwitchScheduleMutation, useRunOnceMutation } from '../../redux/api/schedulesApi';

const Schedulers = () => {
    const { t } = useTranslation();
    const [schedule, setSchedule] = useState<Schedule | null>(null);
    const [intervalEditModal, openIntervalEditModal, closeIntervalEditModal] = useModal();
    const [historyPage, openHistoryPage, closeHistoryPage] = useModal();

    const { data, error, isLoading } = useGetSchedulesQuery({ orderBy: 'id' });
    const [switchSchedule, { isLoading: isSwitching, error: isSwitchingError }] = useSwitchScheduleMutation();
    const [runOnce, { isLoading: isRunningOnce, error: isRunningOnceError }] = useRunOnceMutation();

    useHandleErrors(error, isSwitchingError, isRunningOnceError);

    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem(SCHEDULERS_TABLE);
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);

    const handleHistoryClick: HandleClick = (id, e) => {
        e.stopPropagation();
        if (!data) return;
        const schedule = data.find((s) => s.id === id);
        if (!schedule) return;

        setSchedule(schedule);
        openHistoryPage();
    };

    const rowCb: TableProps<Schedule>['onRow'] = useCallback(
        (record: Schedule) => ({
            onClick: () => {
                setSchedule(record);
                openIntervalEditModal();
            },
        }),
        [openIntervalEditModal],
    );

    const handleRunOnceClick: HandleClick = useCallback(
        (id, e) => {
            e.stopPropagation();
            if (id) {
                ModalAntd.confirm({
                    title: t('common.confirm'),
                    content: t('pages.schedulers.modal.runOnce'),
                    onOk: () =>
                        runOnce(id).then(() => baseSuccessNotification(t('pages.schedulers.notifications.runOnce'))),
                    okText: t('common.yes'),
                    cancelText: t('common.return'),
                    style: { top: '20%' },
                });
            }
        },
        [runOnce, t],
    );

    const handleSwitchClick: HandleClick = useCallback(
        (id, e, type) => {
            e.stopPropagation();
            if (id && type) {
                ModalAntd.confirm({
                    title: t('common.confirm'),
                    content: t('pages.schedulers.modal.switch.content', {
                        type:
                            type === 'activate'
                                ? t('pages.schedulers.modal.switch.enable')
                                : t('pages.schedulers.modal.switch.disable'),
                    }),
                    onOk: () => switchSchedule({ id, type }).then(() => baseSuccessNotification(t('pages.schedulers.notifications.switch'))),
                    okText: t('common.yes'),
                    cancelText: t('common.return'),
                    style: { top: '20%' },
                });
            }
        },
        [switchSchedule, t],
    );

    return (
        <Spin spinning={isLoading || isSwitching || isRunningOnce}>
            {historyPage && schedule && (
                <SchedulersHistory schedule={schedule as Schedule} closePage={closeHistoryPage} />
            )}
            {!historyPage && (
                <>
                    <Modal
                        onCancel={closeIntervalEditModal}
                        destroyOnClose
                        title={t('pages.schedulers.modal.edit', { description: schedule?.description })}
                        open={intervalEditModal}>
                        <IntervalEditModal schedule={schedule as Schedule} onSuccess={closeIntervalEditModal} />
                    </Modal>
                    <Table<Schedule>
                        rowKey="id"
                        size="small"
                        scroll={{ x: 600 }}
                        columns={getColumns(sizes, handleHistoryClick, handleRunOnceClick, handleSwitchClick, t)}
                        dataSource={data}
                        bordered
                        pagination={false}
                        onRow={rowCb}
                    />
                </>
            )}
        </Spin>
    );
};

export default Schedulers;
