import i18next from 'i18next';

export const TranslatedEnumValue = (value: string) => {
    return i18next.t(value);
};

export const TranslatedEnum = <T extends object>(enumObj: T) => {
    return new Proxy(enumObj, {
        get(target, prop: string) {
            return TranslatedEnumValue(target[prop as keyof T] as string);
        },
    });
};

type EnumType = { [key: string]: string };
export const translatedEnumValueUse =
    (mapValue: (func: typeof TranslatedEnumValue) => any) =>
    () => {
        return mapValue(TranslatedEnumValue);
    };

export const translatedEnumUse =
    <T extends EnumType>(enumObj: T, mapArray: (enumObj: T) => any) =>
    () => {
        const translatedEnum = TranslatedEnum(enumObj);
        return mapArray(translatedEnum);
    };
