import { ColumnsType } from 'antd/es/table';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import { DATA_PROCESSING_STAT_TABLE } from '../../../constants';
import { RecordModel } from '../../../models/DictionaryModel';
import { DataProcessingStat } from '../../../models/dataProcessingStat';
import { Trans } from 'react-i18next';

const defaultColumnsWidth = {
    0: '5%',
    1: '35%',
    2: '10%',
    3: '10%',
    4: '10%',
    5: '10%',
    6: '10%',
    7: '10%',
} as const;

export const getColumns = (
    sizes: Record<string, number>,
    dictKafkaTopics?: RecordModel[],
): ColumnsType<DataProcessingStat> => {
    return [
        {
            title: (
                <ResizableColumn colIndex={0} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    <Trans i18nKey="pages.processingStat.columns.changeNumber" />
                </ResizableColumn>
            ),
            dataIndex: 'changeNumber',
            key: 'changeNumber',
            ellipsis: true,
            width: sizes?.[0] || defaultColumnsWidth[0],
        },
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    <Trans i18nKey="pages.processingStat.columns.topic" />
                </ResizableColumn>
            ),
            dataIndex: 'topic',
            key: 'topic',
            ellipsis: true,
            width: sizes?.[1] || defaultColumnsWidth[1],
            render: (topic: string) => {
                const dict = dictKafkaTopics?.find((dict) => dict.code === topic);
                return dict?.description || topic;
            },
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    <Trans i18nKey="pages.processingStat.columns.processedСount" />
                </ResizableColumn>
            ),
            dataIndex: 'processedСount',
            key: 'processedСount',
            ellipsis: true,
            width: sizes?.[2] || defaultColumnsWidth[2],
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    <Trans i18nKey="pages.processingStat.columns.unprocessedСount" />
                </ResizableColumn>
            ),
            dataIndex: 'unprocessedСount',
            key: 'unprocessedСount',
            ellipsis: true,
            width: sizes?.[3] || defaultColumnsWidth[3],
        },
        {
            title: (
                <ResizableColumn colIndex={4} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    <Trans i18nKey="pages.processingStat.columns.errorСount" />
                </ResizableColumn>
            ),
            dataIndex: 'errorСount',
            key: 'errorСount',
            ellipsis: true,
            width: sizes?.[4] || defaultColumnsWidth[4],
        },
        {
            title: (
                <ResizableColumn colIndex={5} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    <Trans i18nKey="pages.processingStat.columns.missedСount" />
                </ResizableColumn>
            ),
            dataIndex: 'missedСount',
            key: 'missedСount',
            ellipsis: true,
            width: sizes?.[5] || defaultColumnsWidth[5],
        },
        {
            title: (
                <ResizableColumn colIndex={6} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    <Trans i18nKey="pages.processingStat.columns.otherСount" />
                </ResizableColumn>
            ),
            dataIndex: 'otherСount',
            key: 'otherСount',
            ellipsis: true,
            width: sizes?.[6] || defaultColumnsWidth[6],
        },
        {
            title: (
                <ResizableColumn colIndex={7} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    <Trans i18nKey="pages.processingStat.columns.totalСount" />
                </ResizableColumn>
            ),
            dataIndex: 'totalСount',
            key: 'totalСount',
            ellipsis: true,
            width: sizes?.[7] || defaultColumnsWidth[7],
        },
    ];
};
