import { FC } from 'react';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
    value?: string;
}

const HistoryRow: FC<IProps> = ({ value }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="history__cell" title={value}>
                {!value?.toString().replace(/\s/g, '') ? t('components.historyRow.empty') : value}
            </div>
            <Divider style={{ margin: 0 }} />
        </>
    );
};

export default HistoryRow;
