import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';
import { QueryOptions } from 'odata-query';
import { LeftOutlined } from '@ant-design/icons';
import { DATE_FORMAT, TIME_WITH_SECONDS_FORMAT } from '../../../constants/Alert';
import { defaultHistoryQuery, rulesFields, statusCatalog, statusFilter } from '../../../constants/History';
import { HISTORY_PARAMETR_PARAMS } from '../../../constants/Rules';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { getHistoryFilter } from '../../../helpers/getHistoryFilters';
import { HistoryTypes, getHistoryRow } from '../../../helpers/getHistoryValue';
import getTopSkip from '../../../helpers/getTopSkip';
import { useEscape } from '../../../hooks';
import { RuleHistory, RuleModel } from '../../../models/RulesModel';
import { useGetRuleHistoryQuery } from '../../../redux/api/rulesApi';
import HistoryRow from '../../common/HistoryRow';
import { useTranslation } from 'react-i18next';

interface RulesHistoryProps {
    selectedRule: RuleModel;
    onClose: () => void;
}

const History: FC<RulesHistoryProps> = ({ selectedRule, onClose }) => {
    const { t } = useTranslation();
    useEscape(onClose);
    const dateInputRef = useRef(null);
    const authorInputRef = useRef(null);
    const defaultFilter = { entryId: selectedRule.id };

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const [filter, setFilter] = useState<QueryOptions<RuleHistory>['filter']>(defaultFilter);

    const queryData = useMemo<Partial<QueryOptions<RuleHistory>>>(
        () => ({
            ...defaultHistoryQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: filter,
        }),
        [pagination, filter],
    );

    const { data: ruleHistory, isLoading, isFetching } = useGetRuleHistoryQuery(queryData);

    const count = ruleHistory?.count;

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<RuleHistory>['onChange'] = (newPagination, filters, _sorter) => {
        setFilter([defaultFilter, ...getHistoryFilter(filters)]);
        setPagination(newPagination);
    };

    const column: ColumnsType<RuleHistory> = [
        {
            title: t('common.history.columns.action'),
            dataIndex: 'action',
            key: 'action',
            render: (value) => statusCatalog[value],
            filters: statusFilter(),
            width: 120,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.createdDate'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(DATE_FORMAT),
            ...getColumnSearchProps<RuleHistory>(dateInputRef, 'date'),
            width: 100,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.time'),
            dataIndex: 'createdDate',
            key: 'time',
            render: (value) => moment(value).format(TIME_WITH_SECONDS_FORMAT),
            width: 100,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.user'),
            dataIndex: 'userId',
            key: 'user',
            render: (value) => `${value.lastName} ${value.firstName}`,
            ...getColumnSearchProps<RuleHistory>(authorInputRef, 'fio'),
            width: 150,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.param'),
            dataIndex: 'newValue',
            key: 'param',
            render: (value) =>
                Object.keys(value).map((key, index) => (
                    <HistoryRow
                        value={key === HISTORY_PARAMETR_PARAMS ? value.params.description : rulesFields[key] || key}
                        key={index}
                    />
                )),
            width: 150,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.prevValue'),
            dataIndex: 'prevValue',
            key: 'prevValue',
            render: (value) => getHistoryRow(value, HistoryTypes.rules),
            width: 150,
            ellipsis: true,
        },
        {
            title: t('common.history.columns.newValue'),
            dataIndex: 'newValue',
            key: 'newValue',
            render: (value) => getHistoryRow(value, HistoryTypes.rules),
            width: 150,
            ellipsis: true,
        },
    ];

    return (
        <>
            <Row>
                <div onClick={onClose} className={'user-history__title'}>
                    <LeftOutlined />
                    {t('common.history.title')} "{selectedRule?.shortName}"
                </div>
            </Row>
            <Table
                size="small"
                rowKey="id"
                bordered
                loading={isLoading || isFetching}
                columns={column}
                dataSource={ruleHistory ? ruleHistory.items : []}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default History;
