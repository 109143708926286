import { Button, Form, Input, Row, Space, Spin } from 'antd';
import { FC } from 'react';
import { DictionaryModel } from '../../../models/DictionaryModel';
import { DictionaryRecordFields } from './DictionaryRecordFields';
import { useTranslation } from 'react-i18next';

interface RecordDataFieldsProps {
    isLoading: boolean;
    dictionary?: DictionaryModel;
    isUpdateForm?: boolean;

    fraction?: number;
}

const RecordDataFields: FC<RecordDataFieldsProps> = ({ isLoading, dictionary, isUpdateForm = false, fraction }) => {
    const { t } = useTranslation();
    return (
        <Spin spinning={isLoading}>
            {isUpdateForm && (
                <Form.Item name="id" hidden>
                    <Input disabled={true} />
                </Form.Item>
            )}
            <Form.Item name="dict_name" hidden>
                <Input disabled={true} />
            </Form.Item>
            <Form.Item name="dict_description" label={t('common.dictionary')}>
                <Input disabled={true} />
            </Form.Item>
            {DictionaryRecordFields(isUpdateForm, dictionary, fraction)}
            <Row justify="end">
                <Space wrap>
                    <Button type="primary" htmlType="submit">
                        {t('common.submit')}
                    </Button>
                </Space>
            </Row>
        </Spin>
    );
};

export default RecordDataFields;
