export enum SESSION_HISTORY_FIELDS {
    date = 'enums.sessionHistoryFields.date',
    comment = 'enums.sessionHistoryFields.comment',
}

export enum SESSION_INTERVAL_TRANSLATE {
    one_time_a_day = 'enums.sessionInterval.one_time_a_day',
    one_time_a_week = 'enums.sessionInterval.one_time_a_week',
    one_time_per_month = 'enums.sessionInterval.one_time_per_month',
    one_time_per_quarter = 'enums.sessionInterval.one_time_per_quarter',
}

export enum HISTORY_STATUS {
    update = 'update',
    create = 'create',
    remove = 'remove',
};
