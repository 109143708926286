import React, { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Button, Card, Form, Input, notification, Row, Spin } from 'antd';
import { useChangeUserPasswordMutation } from '../redux/api/authApi';
import { getChangePasswordData, logout } from '../redux/features/authSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

interface ChangePasswordValues {
    email: string;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const ChangePassword: FC = () => {
    const { t } = useTranslation();
    const [changeUserPassword, { isLoading, error, isSuccess }] = useChangeUserPasswordMutation();

    const onFinish = (values: ChangePasswordValues) => {
        changeUserPassword({
            email: values.email,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        });
    };

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: t('pages.changePassword.notifications.success.message'),
                description: t('pages.changePassword.notifications.success.description'),
            });
            dispatch(logout());
        }
    }, [isSuccess, dispatch, t]);

    useEffect(() => {
        if (error && 'data' in error) {
            notification.error({
                message: (error.data as any).message,
            });
        }
    }, [error]);

    const changePasswordData = useAppSelector(getChangePasswordData);
    if (!changePasswordData) {
        return <Navigate to={'/'} />;
    }

    return (
        <div className="login__form">
            <Spin spinning={isLoading}>
                <Card title={<img src={'logo_big.svg'} width={238} alt="logo" />}>
                    <Form<ChangePasswordValues>
                        initialValues={{
                            email: changePasswordData?.email,
                        }}
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off">
                        <Form.Item
                            label={t('pages.changePassword.email')}
                            name="email"
                            rules={[{ required: true, message: t('validation.required') }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            name="oldPassword"
                            label={t('pages.changePassword.oldPassword')}
                            rules={[{ required: true }]}
                            hasFeedback>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            label={t('pages.changePassword.newPassword')}
                            rules={[{ required: true }]}
                            hasFeedback>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label={t('pages.changePassword.confirmPassword')}
                            dependencies={['newPassword']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('pages.changePassword.notifications.diff')));
                                    },
                                }),
                            ]}>
                            <Input.Password />
                        </Form.Item>
                        <Row justify={'center'} style={{ marginTop: 8 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    <Trans i18nKey="pages.changePassword.confirm" />
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </Card>
            </Spin>
        </div>
    );
};

export default ChangePassword;
