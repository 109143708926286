import { FC, useCallback, useEffect } from 'react';
import { Button, Col, Row, Space } from 'antd';
import { HistoryOutlined, SaveOutlined } from '@ant-design/icons';
import { RULES_OPTIONS_TYPE_EDIT, RULES_OPTIONS_TYPE_VIEW } from '../../../constants/Rules';
import { useEscape } from '../../../hooks';
import { baseErrorNotification } from '../../../hooks/useHandleError';
import { OptionsType, RuleModel } from '../../../models/RulesModel';
import { useLazyEnterEditingModeQuery, useLazyExitEditingModeQuery } from '../../../redux/api/rulesApi';
import closeWithoutSaveConfirmModal from '../../common/closeWithoutSaveConfirmModal';
import { useTranslation } from 'react-i18next';

interface RulesHeaderProps {
    selectedRule: RuleModel;
    onOpenHistory: () => void;
    toggleEditMode: () => void;
    optionType: OptionsType;
    isEdited: boolean;
    onCancel: () => void;
    isEditingMode: boolean;
    setIsEditingMode: (value: boolean) => void;
    refetchRule: () => void;
}

const Header: FC<RulesHeaderProps> = ({
    selectedRule,
    onOpenHistory,
    toggleEditMode,
    optionType,
    isEdited,
    onCancel,
    isEditingMode,
    setIsEditingMode,
    refetchRule,
}) => {
    const { t } = useTranslation();
    useEscape(() => handleExitEditingMode());

    const [enterEditingMode, { isFetching: isFetchingEnter }] = useLazyEnterEditingModeQuery();
    const [exitEditingMode, { isFetching: isFetchingExit }] = useLazyExitEditingModeQuery();

    const handleEnterEditingMode = async () => {
        if (!selectedRule.id) return;

        const result = await enterEditingMode(selectedRule.id);
        if (result.error) {
            baseErrorNotification((result.error as any).data?.message || t('components.rules.header.notification.error'));
        } else {
            setIsEditingMode(true);
            refetchRule();
            toggleEditMode();
        }
    };

    const handleExitEditingMode = useCallback(() => {
        const onClose = () => {
            exitEditingMode(selectedRule.id);
            if (isEdited) {
                onCancel();
            }
            setIsEditingMode(false);
            toggleEditMode();
        };

        if (isEdited) {
            closeWithoutSaveConfirmModal(onClose);
        } else {
            onClose();
        }
    }, [exitEditingMode, isEdited, onCancel, selectedRule.id, setIsEditingMode, toggleEditMode]);

    useEffect(() => {
        if (isEditingMode) {
            window.addEventListener('beforeunload', handleExitEditingMode);
        }

        return () => {
            if (isEditingMode) {
                window.removeEventListener('beforeunload', handleExitEditingMode);
            }
        };
    }, [handleExitEditingMode, isEditingMode]);

    return (
        <Row align="middle">
            <Col span={12}>
                <div className="rules-block__title">{selectedRule.shortName}</div>
            </Col>
            <Col span={12}>
                <Row justify="end">
                    <Space>
                        {optionType === RULES_OPTIONS_TYPE_VIEW && (
                            <>
                                <Button
                                    type="default"
                                    onClick={onOpenHistory}
                                    disabled={!selectedRule.id}
                                    icon={<HistoryOutlined />}>
                                    {t('common.history.title')}
                                </Button>
                                <Button type="primary" onClick={handleEnterEditingMode} loading={isFetchingEnter}>
                                    {t('common.edit')}
                                </Button>
                            </>
                        )}
                        {optionType === RULES_OPTIONS_TYPE_EDIT && (
                            <>
                                <Button type="default" onClick={handleExitEditingMode} loading={isFetchingExit}>
                                    {t('common.saveExit')}
                                </Button>
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={!isEdited}>
                                    {t('common.save')}
                                </Button>
                            </>
                        )}
                    </Space>
                </Row>
            </Col>
        </Row>
    );
};

export default Header;
