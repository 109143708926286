export enum LocalityTypes {
    city = 'enums.localityTypes.city',
    urban_village = 'enums.localityTypes.urban_village',
    village = 'enums.localityTypes.village',
}

export enum StreetTypes {
    street = 'enums.streetTypes.street',
    avenue = 'enums.streetTypes.avenue',
    boulevard = 'enums.streetTypes.boulevard',
    alley = 'enums.streetTypes.alley',
    quay = 'enums.streetTypes.quay',
    area = 'enums.streetTypes.area',
    square = 'enums.streetTypes.square',
    descent = 'enums.streetTypes.descent',
    highway = 'enums.streetTypes.highway',
}

export enum BuildingTypes {
    building = 'enums.buildingTypes.building',
    d_v = 'enums.buildingTypes.d_v',
    n_p = 'enums.buildingTypes.n_p',
    p_n = 'enums.buildingTypes.p_n',
}

export enum CorpsTypes {
    corps = 'enums.corpsTypes.corps',
    section = 'enums.corpsTypes.section',
    block = 'enums.corpsTypes.block',
}

export enum ApartmentTypes {
    apartment = 'enums.apartmentTypes.apartment',
    office = 'enums.apartmentTypes.office',
    place = 'enums.apartmentTypes.place',
    room = 'enums.apartmentTypes.room',
}

export enum DictionaryColumnsTypes {
    string = 'string',
    number = 'number',
    dictionary = 'dictionary',
    boolean = 'boolean',
    date = 'date',
}
