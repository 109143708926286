import { FC, useEffect } from 'react';
import { Form, Input, Row, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { DefaultOptionType } from 'antd/es/select';
import { activeStatusSelectOptions } from '../../../constants/User';
import { Roles } from '../../../constants/enums';
import { useGetRolesQuery } from '../../../redux/api/rolesApi';
import { useTranslation } from 'react-i18next';

interface UserDataFieldsProps {
    disableEmail?: boolean;
    disableTempPass?: boolean;
}

const checkDisabledOptions = (rolesOptions: DefaultOptionType[], value?: string[]) => {
    if (value?.length) {
        if (value.includes(Roles.analyst)) {
            rolesOptions.forEach((role) => {
                if (role.value !== Roles.analyst) role.disabled = true;
            });
        } else {
            rolesOptions.forEach((role) => {
                if (role.value === Roles.analyst) role.disabled = true;
            });
        }
        return;
    }
    rolesOptions.forEach((role) => (role.disabled = false));
};

const UserDataFields: FC<UserDataFieldsProps> = ({ disableEmail, disableTempPass }) => {
    const { t } = useTranslation();
    const { data: roles = [], isLoading, isFetching } = useGetRolesQuery();
    const form = Form.useFormInstance();

    const rolesOptions: DefaultOptionType[] = roles.map((role) => ({
        value: role.name,
        label: role.description,
        disabled: false,
    }));

    useEffect(() => checkDisabledOptions(rolesOptions, form.getFieldValue('roles')), [form, rolesOptions]);

    return (
        <>
            <Form.Item label={t('forms.user.data.email')} name="email" rules={[{ required: true }]}>
                <Input placeholder={t('forms.user.data.email')} disabled={disableEmail} />
            </Form.Item>
            <Form.Item label={t('forms.user.data.lastName')} name="lastName" rules={[{ required: true }]}>
                <Input placeholder={t('forms.user.data.lastName')} />
            </Form.Item>
            <Form.Item label={t('forms.user.data.firstName')} name="firstName" rules={[{ required: true }]}>
                <Input placeholder={t('forms.user.data.firstName')} />
            </Form.Item>
            <Form.Item label={t('forms.user.data.middleName')} name="middleName">
                <Input placeholder={t('forms.user.data.middleName')} autoComplete="additional-name" />
            </Form.Item>
            <Form.Item label={t('forms.user.data.phone')} name="phone" rules={[{ len: 17 }]}>
                <MaskedInput
                    mask={[{ mask: '+{38}-\\000{-}000{-}00{-}00', lazy: true }]}
                    placeholder={t('forms.user.data.phone')}
                    autoComplete="phone"
                />
            </Form.Item>
            <Form.Item label={t('forms.user.data.roles.label')} name="roles" rules={[{ required: true }]}>
                <Select
                    mode="multiple"
                    placeholder={t('forms.user.data.roles.placeholder')}
                    style={{ width: '100%' }}
                    options={rolesOptions}
                    loading={isLoading || isFetching}
                    onChange={(value) => checkDisabledOptions(rolesOptions, value)}
                />
            </Form.Item>
            <Form.Item label={t('forms.user.data.activeStatus.label')} name="activeStatus" rules={[{ required: true }]} initialValue={!disableEmail}>
                <Select
                    placeholder={t('forms.user.data.activeStatus.placeholder')}
                    style={{ width: '100%' }}
                    options={activeStatusSelectOptions()}
                    loading={isLoading || isFetching}
                />
            </Form.Item>
            {!disableTempPass && (
                <Form.Item name="password" label={t('forms.user.data.password')} rules={[{ required: true }]}>
                    <Input.Password autoComplete="new-password" />
                </Form.Item>
            )}
            <Row style={{ marginBottom: '1.5rem' }} />
        </>
    );
};

export default UserDataFields;
