import React, { FC, useMemo, useRef } from 'react';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import ResizableColumn from '../../../../../components/common/ResizebleColumn';
import { DATE_FORMAT_FOR_QUERY, RELATIVE_TABLE } from '../../../../../constants/Alert';
import { access, getColumnSearchProps } from '../../../../../helpers/getColumnSearchProps';
import { getDateView } from '../../../../../helpers/getDateView';
import getTableSizeFromStore from '../../../../../helpers/getTableSizeFromStore';
import { localeCompareSort } from '../../../../../helpers/sort';
import { ExtendedCustomer, Relation } from '../../../../../models/Alert';
import TableWrapper from '../TableWrapper';
import { Trans } from 'react-i18next';

interface RelationsProps {
    data?: Relation[];
    loading?: boolean;
    triggerFetch?: () => void;
}
const Relations: FC<RelationsProps> = ({ data, loading, triggerFetch }) => {
    const size = useMemo(() => getTableSizeFromStore(RELATIVE_TABLE), []);
    const inputRef = useRef(null);
    const columns: ColumnsType<Relation> = useMemo(
        () => [
            {
                key: 'clientName',
                dataIndex: 'clientName',
                title: (
                    <ResizableColumn colIndex={0} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.clientName" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'clientName'),
                ...getColumnSearchProps<Relation>(inputRef, 'string', 'clientName'),
                ellipsis: true,
                width: size?.[0] || 120,
            },
            {
                key: 'clientCode',
                dataIndex: 'clientCode',
                title: (
                    <ResizableColumn colIndex={1} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.clientCode" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'clientCode'),
                ...getColumnSearchProps<Relation>(inputRef, 'string', 'clientCode'),
                ellipsis: true,
                width: size?.[1] || 92,
            },
            {
                key: 'birthday',
                dataIndex: 'birthday',
                title: (
                    <ResizableColumn colIndex={2} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.birthday" />
                    </ResizableColumn>
                ),
                render: (_value, record) => {
                    let result = record.isBankClient ? record.customer?.birthday : record.birthDay;
                    return `${getDateView(result, true)}`;
                },
                sorter: (a, b) => {
                    const one = a.isBankClient ? a.customer?.birthday : a.birthDay;
                    const two = b.isBankClient ? b.customer?.birthday : b.birthDay;
                    return new Date(one as string).getTime() - new Date(two as string).getTime();
                },
                ...getColumnSearchProps<Relation>(inputRef, 'date', 'birthDay'),
                onFilter: (value: any, record: Relation & Partial<ExtendedCustomer>) =>
                    dayjs(access(record, record.isBankClient ? ['customer', 'birthday'] : 'birthDay') as string)
                        .format(DATE_FORMAT_FOR_QUERY)
                        .includes((value as string).toLowerCase()),
                ellipsis: true,
                width: size?.[2] || 82,
            },
            {
                key: 'relationName',
                dataIndex: 'relationName',
                title: (
                    <ResizableColumn colIndex={3} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.relationName" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'relationName'),
                ...getColumnSearchProps<Relation>(inputRef, 'string', 'relationName'),
                ellipsis: true,
                width: size?.[3] || 150,
            },
            {
                key: 'vaga1',
                dataIndex: 'vaga1',
                title: (
                    <ResizableColumn colIndex={4} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.vaga1" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'vaga1'),
                ...getColumnSearchProps<Relation>(inputRef, 'string', 'vaga1'),
                ellipsis: true,
                width: size?.[4] || 150,
            },
            {
                key: 'vaga2',
                dataIndex: 'vaga2',
                title: (
                    <ResizableColumn colIndex={5} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.vaga2" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'vaga2'),
                ...getColumnSearchProps<Relation>(inputRef, 'string', 'vaga2'),
                ellipsis: true,
                width: size?.[5] || 150,
            },
            {
                key: 'isBankClient',
                dataIndex: 'isBankClient',
                title: (
                    <ResizableColumn colIndex={6} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.isBankClient" />
                    </ResizableColumn>
                ),
                render: (value) => (value ? 'Так' : 'Ні'),
                sorter: (a, b) => (a.isBankClient as number) - (b.isBankClient as number),
                filters: [
                    { text: 'Так', value: 1 },
                    { text: 'Ні', value: 0 },
                ],
                onFilter: (value, record) => value === record.isBankClient,
                ellipsis: true,
                width: size?.[6] || 150,
            },
            {
                //todo замапить значения
                render: () => '-',
                key: 'Додаткова інформація',
                dataIndex: 'Додаткова інформація',
                title: (
                    <ResizableColumn colIndex={7} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.additionalInfo" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'vaga2'),
                ...getColumnSearchProps<Relation>(inputRef, 'string', 'vaga2'),
                ellipsis: true,
                width: size?.[7] || 150,
            },
            {
                key: 'customer/customerId',
                dataIndex: ['customer', 'customerId'],
                title: (
                    <ResizableColumn colIndex={8} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="common.customerId" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a.customer, b.customer, 'customerId'),
                ...getColumnSearchProps<Relation & Partial<ExtendedCustomer>>(inputRef, 'string', [
                    'customer',
                    'customerId',
                ]),
                ellipsis: true,
                width: size?.[8] || 50,
            },
            {
                key: 'customer/dpaRegistrationDate',
                dataIndex: ['customer', 'dpaRegistrationDate'],
                title: (
                    <ResizableColumn colIndex={9} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.dpaRegistrationDate" />
                    </ResizableColumn>
                ),
                render: (value) => `${getDateView(value, true)}`,
                sorter: (a, b) =>
                    new Date(a.customer?.dpaRegistrationDate as string).getTime() -
                    new Date(b.customer?.dpaRegistrationDate as string).getTime(),
                ...getColumnSearchProps<Relation & Partial<ExtendedCustomer>>(inputRef, 'date', [
                    'customer',
                    'dpaRegistrationDate',
                ]),
                ellipsis: true,
                width: size?.[9] || 80,
            },
            {
                key: 'customer/riskLevel',
                dataIndex: ['customer', 'riskLevel'],
                title: (
                    <ResizableColumn colIndex={10} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.riskLevel" />
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a.customer, b.customer, 'riskLevel'),
                ...getColumnSearchProps<Relation & Partial<ExtendedCustomer>>(inputRef, 'string', [
                    'customer',
                    'riskLevel',
                ]),
                ellipsis: true,
                width: size?.[10] || 80,
            },
            {
                key: 'customer/riskLevelDate',
                dataIndex: ['customer', 'riskLevelDate'],
                title: (
                    <ResizableColumn colIndex={11} storageKey={RELATIVE_TABLE}>
                        <Trans i18nKey="pages.alerts.components.bankInfo.relations.riskLevelDate" />
                    </ResizableColumn>
                ),
                render: (value) => `${getDateView(value, true)}`,
                sorter: (a, b) =>
                    new Date(a.customer?.riskLevelDate as string).getTime() -
                    new Date(b.customer?.riskLevelDate as string).getTime(),
                ...getColumnSearchProps<Relation & Partial<ExtendedCustomer>>(inputRef, 'date', [
                    'customer',
                    'riskLevelDate',
                ]),
                ellipsis: true,
                width: size?.[11] || 80,
            },
        ],
        [size],
    );

    return (
        <TableWrapper
            rowKey="id"
            dataSource={data}
            columns={columns}
            loading={loading}
            triggerFetch={triggerFetch}
            isActionBar={true}
            isClearFilters={true}
        />
    );
};

export default Relations;
