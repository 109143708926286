import { ReactNode } from 'react';
import { FormInstance, Tooltip } from 'antd';
import { Dayjs } from 'dayjs';
import { TFunction } from 'i18next';
import { getAbbr } from './getAbbr';
import { getDateView } from './getDateView';
import { sortByDate } from './sort';
import { translatedEnumValueUse } from './translatedEnum';
import Skeleton from '../components/common/Skeleton';
import UserModal from '../components/modal/UserModal';
import {
    ALERT_PARAMETER,
    INDIVIDUAL_TYPES,
    LEGAL_TYPES,
    RULES_TYPE_CRITERION,
    RULES_TYPE_INDICATOR,
} from '../constants';
import { AlertAdditionalInfo, AlertDataOfLegal, BaseAlert, CustomerAddress, ExtendedAlert } from '../models/Alert';
import { RecordModel } from '../models/DictionaryModel';
import { UserModel } from '../models/UserModel';
import { Option } from '../pages/alerts/components/card/Card';

const approveOption = translatedEnumValueUse((t) => [
    { value: 1, label: t('common.yes') },
    { value: 0, label: t('common.no') },
]);

const isDocumentsProvidedOptions = translatedEnumValueUse((t) => [
    { value: 1, label: t('common.yes') },
    { value: 0, label: t('common.no') },
    { value: 2, label: t('common.partially') },
    { value: 3, label: t('common.notAsked') },
]);

const numberOfIncomingLetterStyle = { width: '280px' };

const getLegalAddress = (addresses?: CustomerAddress[]) => {
    const data = addresses?.find((addr) => addr.addressType === 'Юридична');
    const result = [];
    if (data?.country) result.push(data?.country);
    if (data?.postCode) result.push(data?.postCode);
    if (data?.region) result.push(data?.region);
    if (data?.area) result.push(data?.area);
    if (data?.locality) result.push(`м. ${data?.locality}`);
    if (data?.addressText) result.push(`${data?.addressText}`);

    return result.length ? result.join(', ') : '';
};

const getCustomerAddress = (addresses?: CustomerAddress[]) => {
    const data = addresses?.find((addr) => addr.addressType === 'Фактична');
    const result = [];
    if (data?.country) result.push(data?.country);
    if (data?.postCode) result.push(data?.postCode);
    if (data?.region) result.push(data?.region);
    if (data?.area) result.push(data?.area);
    if (data?.locality) result.push(`м. ${data?.locality}`);
    if (data?.street) result.push(`${data?.streetType} ${data?.street}`);
    if (data?.house) result.push(`${data?.houseType ? data?.houseType + ' ' : ''}${data?.house}`);
    if (data?.room) result.push(`${data.roomType ? data.roomType + ' ' : ''}${data?.room}`);
    return result.length ? result.join(', ') : '';
};

const isExist = (t: TFunction, value?: string | number | boolean | null) =>
    !!value ? t('common.yes') : t('common.no');
const isLegalType = (type?: string) => LEGAL_TYPES().includes(type || '');
const isIndividualType = (type?: string) => INDIVIDUAL_TYPES().includes(type || '');

export enum RowTadaType {
    date = 'date',
    select = 'select',
    textArea = 'textArea',
    numberInput = 'numberInput',
    clientData = 'client-data',
    cfoData = 'cfo-data',
    table = 'table',
}
interface BaseAlertTabRowData {
    key: string;
    name: string;
    editable?: boolean;
    hide?: boolean;
    type?: RowTadaType;
    required?: boolean;
    placeholder?: string;
    disabled?: boolean;
    rows?: number; // for textArea
    style?: { [index: string]: string };
    multiply?: boolean; // for select
    options?: any[]; // for select
    disabledPastPeriod?: boolean; // for datePicker
}

interface EditableAlertTabRowData extends BaseAlertTabRowData {
    editable: true;
    value: string | number | boolean | null | Dayjs | number[] | string[] | ReactNode | AlertDataOfLegal[];
    fieldName: string | string[];
}

interface NonEditableAlertTabRowData extends BaseAlertTabRowData {
    editable?: false;
    value?: string | number | boolean | null | Dayjs | number[] | string[] | ReactNode | AlertDataOfLegal[];
}

export type AlertTabRowData = EditableAlertTabRowData | NonEditableAlertTabRowData;

const getAdditionalInfo = (key: string, additionalInfo?: AlertAdditionalInfo, isAdditionalInfoLoading?: boolean) => {
    if (!additionalInfo) {
        return <Skeleton loading={isAdditionalInfoLoading} />;
    }

    switch (key) {
        case ALERT_PARAMETER.analysisPeriod:
            return `${getDateView(additionalInfo.analysisFromDate, true)} - ${getDateView(
                additionalInfo.analysisToDate,
                true,
            )}`;
        case ALERT_PARAMETER.firstCurrentAccountDate:
            return getDateView(additionalInfo.firstCurrentAccountDate, true);
        case ALERT_PARAMETER.vedFromAnotherType:
            return (
                additionalInfo?.customer?.vedFromAnotherType +
                (additionalInfo?.customer?.vedDescriptionFromAnotherType
                    ? ` - ${additionalInfo.customer.vedDescriptionFromAnotherType}`
                    : '')
            );
        default:
            return '';
    }
};

export const getGeneralData = (
    alert: ExtendedAlert,
    t: TFunction,
    additionalInfo?: AlertAdditionalInfo,
    isAdditionalInfoLoading?: boolean,
): AlertTabRowData[] => [
    {
        key: ALERT_PARAMETER.status,
        name: t('helpers.getAlertData.getGeneralData.status'),
        value: alert.status?.description,
        editable: false,
    },
    { key: ALERT_PARAMETER.id, name: t('helpers.getAlertData.getGeneralData.id'), value: alert.id, editable: false },
    {
        key: ALERT_PARAMETER.createdDate,
        name: t('helpers.getAlertData.getGeneralData.createdDate'),
        value: alert.createdDate && getDateView(alert.createdDate, true),
        editable: false,
    },
    {
        key: ALERT_PARAMETER.urgency,
        name: t('helpers.getAlertData.getGeneralData.urgency'),
        value: alert.urgency?.description,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.executionDate,
        name: t('helpers.getAlertData.getGeneralData.executionDate'),
        value: alert.executionDate && getDateView(alert.executionDate, true),
        editable: false,
    },
    {
        key: ALERT_PARAMETER.analysisPeriod,
        name: t('helpers.getAlertData.getGeneralData.analysisPeriod'),
        value: getAdditionalInfo(ALERT_PARAMETER.analysisPeriod, additionalInfo, isAdditionalInfoLoading),
        editable: false,
    },
    {
        key: ALERT_PARAMETER.analyst,
        name: t('helpers.getAlertData.getGeneralData.analyst'),
        value: <UserModal user={alert.analyst} />,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.analystEmail.join('/'),
        name: t('helpers.getAlertData.getGeneralData.analystEmail'),
        value: alert.analyst?.email,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.controller,
        name: t('helpers.getAlertData.getGeneralData.controller'),
        value: <UserModal user={alert.controller} />,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.controllerEmail.join('/'),
        name: t('helpers.getAlertData.getGeneralData.controllerEmail'),
        value: alert.controller?.email,
        editable: false,
    },
];

export const getRequisitesData = (
    alert: ExtendedAlert,
    additionalInfo: AlertAdditionalInfo | undefined,
    isAdditionalInfoLoading: boolean,
    form: FormInstance<any>,
    t: TFunction,
): AlertTabRowData[] => {
    const isClientIndividual = alert.customer?.customerTypeName === t('enums.alerts.customer.types.individual');
    const isClientIndividualEntrepreneur =
        alert.customer?.customerTypeName === t('enums.alerts.customer.types.individual_entrepreneur');
    const isClientLegal = isLegalType(alert.customer?.customerTypeName);
    const isClientPhysical = isIndividualType(alert.customer?.customerTypeName);
    return [
        {
            key: 'customerTypeName', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.customerTypeName'),
            value: getAbbr(alert.customer?.customerTypeName),
            editable: false,
        },
        {
            key: 'hasAnotherType', // ФО/ФОП
            name: t('helpers.getAlertData.getRequisitesData.hasAnotherType', {
                clientType: isClientIndividual ? t('common.customerTypes.pe') : t('common.customerTypes.pp'),
            }),
            value: isExist(t, alert.customer?.hasAnotherType),
            editable: false,
            hide: isClientLegal,
        },
        {
            key: 'vedFromAnotherType', // ФО
            name: t('helpers.getAlertData.getRequisitesData.vedFromAnotherType'),
            value: getAdditionalInfo(ALERT_PARAMETER.vedFromAnotherType, additionalInfo, isAdditionalInfoLoading),
            editable: false,
            hide: !isClientIndividual,
        },
        {
            key: 'isIndependentActivity', // ФО
            name: t('helpers.getAlertData.getRequisitesData.isIndependentActivity'),
            value: isExist(t, alert.isIndependentActivity),
            editable: false,
            hide: !isClientIndividual,
        },
        {
            key: 'independentActivityType', // ФО
            name: t('helpers.getAlertData.getRequisitesData.independentActivityType'),
            value: alert.independentActivityType,
            editable: true,
            fieldName: ['independentActivityType'],
            hide: !isClientIndividual || !form?.getFieldValue('isIndependentActivity'),
            required: form?.getFieldValue('isIndependentActivity'),
        },
        {
            key: 'isAddressMassRegistration', // ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.isAddressMassRegistration'),
            value: alert.isAddressMassRegistration,
            editable: true,
            fieldName: ['isAddressMassRegistration'],
            type: RowTadaType.select,
            options: approveOption(),
            hide: isClientIndividual,
            required: true,
        },
        {
            key: 'massRegistrationAddressCount', // ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.massRegistrationAddressCount'),
            value: alert.massRegistrationAddressCount,
            editable: true,
            fieldName: ['massRegistrationAddressCount'],
            type: RowTadaType.numberInput,
            hide: isClientIndividual || !form?.getFieldValue('isAddressMassRegistration'),
            required: form?.getFieldValue('isAddressMassRegistration'),
        },
        {
            key: 'isClientTrustor', // ФО/ФОП
            name: t('helpers.getAlertData.getRequisitesData.isClientTrustor', {
                entrepreneur: isClientIndividualEntrepreneur
                    ? `,${t('helpers.getAlertData.getRequisitesData.common.like')} ${t('common.customerTypes.pp')}, `
                    : ' ',
            }),
            value: alert.isClientTrustor,
            editable: true,
            hide: isClientLegal,
            fieldName: ['isClientTrustor'],
            type: RowTadaType.select,
            options: approveOption(),
            required: true,
        },
        {
            key: 'trustorData', // ФО/ФОП
            name: t('helpers.getAlertData.getRequisitesData.trustorData'),
            value: alert.trustorData,
            editable: true,
            fieldName: ['trustorData'],
            hide: isClientLegal || !form?.getFieldValue('isClientTrustor'),
            required: form?.getFieldValue('isClientTrustor'),
        },
        {
            key: 'isClientTrustedPerson', // ФО/ФОП
            name: t('helpers.getAlertData.getRequisitesData.isClientTrustedPerson', {
                entrepreneur: isClientIndividualEntrepreneur
                    ? `,${t('helpers.getAlertData.getRequisitesData.common.like')} ${t('common.customerTypes.pp')}, `
                    : ' ',
            }),
            value: alert.isClientTrustedPerson,
            editable: true,
            hide: isClientLegal,
            fieldName: ['isClientTrustedPerson'],
            type: RowTadaType.select,
            options: approveOption(),
            required: true,
        },
        {
            key: 'trustedPersonData', // ФО
            name: t('helpers.getAlertData.getRequisitesData.trustedPersonData'),
            value: alert.trustedPersonData,
            editable: true,
            fieldName: ['trustedPersonData'],
            hide: isClientLegal || !form?.getFieldValue('isClientTrustedPerson'),
            required: form?.getFieldValue('isClientTrustedPerson'),
        },
        {
            key: 'isClientBeneficiary', // ФОП
            name: t('helpers.getAlertData.getRequisitesData.isClientBeneficiary'),
            value: alert.isClientBeneficiary,
            editable: true,
            hide: !isClientIndividualEntrepreneur,
            fieldName: ['isClientBeneficiary'],
            type: RowTadaType.select,
            options: approveOption(),
            required: true,
        },
        {
            key: 'clientBeneficiaryCount', // ФОП
            name: t('helpers.getAlertData.getRequisitesData.clientBeneficiaryCount'),
            value: alert.clientBeneficiaryCount,
            editable: true,
            fieldName: ['clientBeneficiaryCount'],
            type: RowTadaType.numberInput,
            hide: !isClientIndividualEntrepreneur || !form?.getFieldValue('isClientBeneficiary'),
            required: form?.getFieldValue('isClientBeneficiary'),
        },
        {
            key: 'alertsDataOfLegalClientData', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.alertsDataOfLegalClientData'),
            value: alert.alertsDataOfLegal?.filter((item) => item.dataType === RowTadaType.clientData),
            type: RowTadaType.clientData,
        },
        {
            key: 'businessSegment', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.businessSegment'),
            value: alert.customer?.businessSegment,
            editable: false,
        },
        {
            key: 'dpaRegistrationDate', // ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.dpaRegistrationDate'),
            value: getDateView(alert.customer?.dpaRegistrationDate, true, true),
            editable: false,
            hide: isClientIndividual,
        },
        {
            key: 'birthday', // ФО/ФОП
            name: t('helpers.getAlertData.getRequisitesData.birthday'),
            value: getDateView(alert.customer?.birthday, true, true),
            editable: false,
            hide: isClientLegal,
        },
        {
            key: 'Citizenship', // ФО/ФОП
            name: t('helpers.getAlertData.getRequisitesData.Citizenship'),
            value:
                ((alert.customer?.citizenshipCode && alert.customer?.citizenshipCode + ' - ') || '') +
                (alert.customer?.citizenshipName || ''),
            editable: false,
            hide: isClientLegal,
        },
        {
            key: 'Residency', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.Residency'),
            value:
                ((alert.customer?.countryCode && alert.customer?.countryCode + ' - ') || '') +
                (alert.customer?.countryName || ''),
            editable: false,
        },
        {
            key: 'addresses', // ФО/ФОП
            name: t('helpers.getAlertData.getRequisitesData.addresses'),
            value: getCustomerAddress(alert.customer.addresses),
            editable: false,
            hide: isClientLegal,
        },
        {
            key: 'temporaryAddress', // ФО
            name: t('helpers.getAlertData.getRequisitesData.temporaryAddress'),
            value: getLegalAddress(alert.customer.addresses),
            editable: false,
            hide: !isClientIndividual || (!alert.customer?.countryCode && !alert.customer?.countryName),
        },
        {
            key: 'registrationAddress', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.registrationAddress'),
            value: getLegalAddress(alert.customer.addresses),
            editable: false,
            hide: isClientPhysical,
        },
        {
            key: 'anotherAddressInfo', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.anotherAddressInfo'),
            value: alert.anotherAddressInfo,
            editable: true,
            fieldName: ['anotherAddressInfo'],
        },
        {
            key: 'massRegistrationAddress', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.massRegistrationAddress'),
            value: alert.massRegistrationAddress,
            editable: true,
            fieldName: ['massRegistrationAddress'],
            type: RowTadaType.select,
            options: approveOption(),
        },
        {
            key: 'numberOfRegistredSGD', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.numberOfRegistredSGD'),
            value: alert.numberOfRegistredSGD,
            editable: false,
        },
        {
            key: 'socialStatus', // ФО
            name: t('helpers.getAlertData.getRequisitesData.socialStatus'),
            value: alert.socialStatus,
            editable: true,
            fieldName: ['socialStatus'],
            hide: !isClientIndividual,
        },
        {
            key: 'typeOfEmployment', // ФО
            name: t('helpers.getAlertData.getRequisitesData.typeOfEmployment'),
            value: alert.typeOfEmployment,
            editable: true,
            fieldName: ['typeOfEmployment'],
            hide: !isClientIndividual,
        },
        {
            key: 'work', // ФО
            name: t('helpers.getAlertData.getRequisitesData.work'),
            value: alert.work,
            editable: true,
            fieldName: ['work'],
            hide: !isClientIndividual,
        },
        {
            key: 'position', // ФО
            name: t('helpers.getAlertData.getRequisitesData.Посада'),
            value: alert.position,
            editable: true,
            fieldName: 'position',
            hide: !isClientIndividual,
        },
        {
            key: 'informationAboutHead', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.informationAboutHead'),
            value: alert.informationAboutHead,
            hide: isClientPhysical,
        },
        {
            key: 'isHeadHeadInOtherCompanies', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.isHeadHeadInOtherCompanies'),
            value: alert.isHeadHeadInOtherCompanies,
            editable: true,
            fieldName: ['isHeadHeadInOtherCompanies'],
            type: RowTadaType.select,
            options: approveOption(),
            hide: isClientPhysical,
            required: true,
        },
        {
            key: 'headHeadInOtherCompaniesCount', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.headHeadInOtherCompaniesCount'),
            value: alert.headHeadInOtherCompaniesCount,
            editable: true,
            fieldName: ['headHeadInOtherCompaniesCount'],
            type: RowTadaType.numberInput,
            hide: isClientPhysical || !form?.getFieldValue('isHeadHeadInOtherCompanies'),
            required: form?.getFieldValue('isHeadHeadInOtherCompanies'),
        },
        {
            key: 'isHeadCFOinOtherCompanies', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.isHeadCFOinOtherCompanies'),
            value: alert.isHeadCFOinOtherCompanies,
            editable: true,
            fieldName: ['isHeadCFOinOtherCompanies'],
            type: RowTadaType.select,
            options: approveOption(),
            hide: isClientPhysical,
            required: true,
        },
        {
            key: 'headCFOinOtherCompaniesCount', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.headCFOinOtherCompaniesCount'),
            value: alert.headCFOinOtherCompaniesCount,
            editable: true,
            fieldName: ['headCFOinOtherCompaniesCount'],
            type: RowTadaType.numberInput,
            hide: isClientPhysical || !form?.getFieldValue('isHeadCFOinOtherCompanies'),
            required: form?.getFieldValue('isHeadCFOinOtherCompanies'),
        },
        {
            key: 'informationAboutCFO', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.informationAboutCFO'),
            value: alert.informationAboutCFO,
            hide: isClientPhysical,
        },
        {
            key: 'CFOCitizenship', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.CFOCitizenship'),
            value: alert.CFOCitizenship,
            hide: isClientPhysical,
        },
        {
            key: 'CFOStake', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.CFOStake'),
            value: alert.CFOStake,
            hide: isClientPhysical,
        },
        {
            key: 'isCFOHeadInOtherCompanies', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.isCFOHeadInOtherCompanies'),
            value: alert.isCFOHeadInOtherCompanies,
            editable: true,
            fieldName: ['isCFOHeadInOtherCompanies'],
            type: RowTadaType.select,
            options: approveOption(),
            hide: isClientPhysical,
            required: true,
        },
        {
            key: 'CFOHeadInOtherCompaniesCount', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.CFOHeadInOtherCompaniesCount'),
            value: alert.CFOHeadInOtherCompaniesCount,
            editable: true,
            fieldName: ['CFOHeadInOtherCompaniesCount'],
            type: RowTadaType.numberInput,
            hide: isClientPhysical || !form?.getFieldValue('isCFOHeadInOtherCompanies'),
            required: form?.getFieldValue('isCFOHeadInOtherCompanies'),
        },

        {
            key: 'isCFOCFOInOtherCompanies', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.isCFOCFOInOtherCompanies'),
            value: alert.isCFOCFOInOtherCompanies,
            editable: true,
            fieldName: ['isCFOCFOInOtherCompanies'],
            type: RowTadaType.select,
            options: approveOption(),
            hide: isClientPhysical,
            required: true,
        },
        {
            key: 'CFOCFOInOtherCompaniesCount', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.CFOCFOInOtherCompaniesCount'),
            value: alert.CFOCFOInOtherCompaniesCount,
            editable: true,
            type: RowTadaType.numberInput,
            fieldName: ['CFOCFOInOtherCompaniesCount'],
            hide: isClientPhysical || !form?.getFieldValue('isCFOCFOInOtherCompanies'),
            required: form?.getFieldValue('isCFOCFOInOtherCompanies'),
        },
        {
            key: 'alertsDataOfLegalCfoData', // ЮО
            name: t('helpers.getAlertData.getRequisitesData.alertsDataOfLegalCfoData'),
            value: alert.alertsDataOfLegal?.filter((item) => item.dataType === RowTadaType.cfoData),
            type: RowTadaType.cfoData,
            hide: isClientPhysical,
        },
        {
            key: 'isCustomerPEP', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.isCustomerPEP'),
            value: isExist(t, alert.customer?.isCustomerPEP),
            editable: false,
        },
        {
            key: 'customerPEPId', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.customerPEPId'),
            value: isClientPhysical ? alert.customer?.physicalCustomerPEPId : alert.customer?.juridicalCustomerPEPId,
            editable: false,
        },
        {
            key: 'ved', // ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.ved'),
            value: alert.customer?.ved + ' - ' + alert.customer?.vedDescription,
            editable: false,
            hide: isClientIndividual,
        },
        {
            key: 'isManyVED', // ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.isManyVED'),
            value: alert.isManyVED,
            editable: true,
            fieldName: ['isManyVED'],
            type: RowTadaType.select,
            options: approveOption(),
            hide: isClientIndividual,
            required: true,
        },
        {
            key: 'kvedQuantity', // ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.kvedQuantity'),
            value: alert.kvedQuantity,
            editable: true,
            fieldName: ['kvedQuantity'],
            type: RowTadaType.numberInput,
            hide: isClientIndividual || !form?.getFieldValue('isManyVED'),
            required: form?.getFieldValue('isManyVED'),
        },
        {
            key: 'licensePermitsInfo', // ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.licensePermitsInfo'),
            value: alert.licensePermitsInfo,
            editable: false,
            hide: isClientIndividual,
        },
        {
            key: 'authorizedCapital', // ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.authorizedCapital'),
            value: alert.customer?.authorizedCapital,
            editable: false,
            hide: isClientPhysical,
        },
        {
            key: 'numberOfEmployees', // ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.numberOfEmployees'),
            value: alert.numberOfEmployees,
            editable: true,
            fieldName: ['numberOfEmployees'],
            type: RowTadaType.numberInput,
            hide: isClientIndividual,
        },
        {
            key: 'businessRelationshipDate', // ФО/ФОП/ЮО
            name: t('helpers.getAlertData.getRequisitesData.businessRelationshipDate'),
            value: getDateView(alert.customer?.businessRelationshipDate, true, true),
            editable: false,
        },
        {
            key: 'firstCurrentAccountDate',
            fieldName: 'firstCurrentAccountDate',
            name: t('helpers.getAlertData.getRequisitesData.firstCurrentAccountDate'),
            value: alert.customer?.firstCurrentAccountDate && getDateView(alert.customer.firstCurrentAccountDate),
            editable: true,
            type: RowTadaType.date,
        },
    ];
};

export const getProfileData = (alert: ExtendedAlert, t: TFunction): AlertTabRowData[] => [
    {
        key: 'riskLevelOnCreate', // ФО/ФОП/ЮО
        name: t('helpers.getAlertData.getProfileData.riskLevelOnCreate'),
        value: alert.riskLevelOnCreate,
        editable: false,
    },
    {
        key: 'riskLevelDateOnCreate', // ФО/ФОП/ЮО
        name: t('helpers.getAlertData.getProfileData.riskLevelDateOnCreate'),
        value: getDateView(alert.riskLevelDateOnCreate, true),
        editable: false,
    },
    {
        key: 'risksCriteria', // ФО/ФОП/ЮО
        name: t('helpers.getAlertData.getProfileData.risksCriteria'),
        value: (
            <div>
                {alert.rules
                    ?.filter((model) => model.addedManually === 0 && model.rule.type.code === RULES_TYPE_CRITERION)
                    .map((model) => (
                        <Tooltip title={model.rule.description} key={model.id}>
                            <div className="field__rule-description">
                                {model.rule.code}&nbsp;{model.rule.description}
                            </div>
                        </Tooltip>
                    ))}
            </div>
        ),
        editable: false,
    },
    {
        key: 'riskScore', // ФО/ФОП/ЮО
        name: t('helpers.getAlertData.getProfileData.riskScore'),
        value: alert.riskSumScore,
        editable: false,
    },
    {
        key: 'suspicionIndicator', // ФО/ФОП/ЮО
        name: t('helpers.getAlertData.getProfileData.suspicionIndicator'),
        value: (
            <div>
                {alert.rules
                    ?.filter((model) => model.addedManually === 0 && model.rule.type.code === RULES_TYPE_INDICATOR)
                    .map((model) => (
                        <Tooltip title={model.rule.description} key={model.id}>
                            <div className="field__rule-description">
                                {model.rule.code}&nbsp;{model.rule.description}
                            </div>
                        </Tooltip>
                    ))}
            </div>
        ),
        editable: false,
    },
];

export const getAdditionalData = (
    alert: ExtendedAlert,
    t: TFunction,
    form?: FormInstance<any>,
    branches?: RecordModel[],
): AlertTabRowData[] => [
    {
        key: 'bankCode',
        name: t('helpers.getAlertData.getAdditionalData.bankCode'),
        value: alert.customer?.bankCode,
        editable: false,
    },
    {
        key: 'branches',
        name: t('helpers.getAlertData.getAdditionalData.branches'),
        value: alert.branches?.map((branch) => branch.id),
        editable: true,
        fieldName: ['branches'],
        type: RowTadaType.select,
        multiply: true,
        options: branches?.map((branch) => ({ value: branch.id, label: branch.description })) || [],
    },
    {
        key: 'Корпоративна електронна скринька Філія',
        name: t('helpers.getAlertData.getAdditionalData.corp1'),
        value: '-',
        editable: false,
    },
    {
        key: 'branchName',
        name: t('helpers.getAlertData.getAdditionalData.branchName'),
        value: alert.customer.branchName?.description,
        editable: false,
    },
    {
        key: 'Корпоративна електронна скринька Відділення',
        name: t('helpers.getAlertData.getAdditionalData.corp2'),
        value: '-',
        editable: false,
    },
    {
        key: 'manager',
        name: t('helpers.getAlertData.getAdditionalData.manager'),
        value: alert.customer?.manager,
        editable: false,
    },
    {
        key: 'Корпоративна електронна скринька Менеджер',
        name: t('helpers.getAlertData.getAdditionalData.corp3'),
        value: '-',
        editable: false,
    },
    {
        key: 'anotherContactInfo',
        name: t('helpers.getAlertData.getAdditionalData.anotherContactInfo'),
        value: alert.anotherContactInfo,
        editable: true,
        fieldName: ['anotherContactInfo'],
        type: RowTadaType.textArea,
        rows: 2,
    },
    {
        key: 'listOfRequireDocuments',
        name: t('helpers.getAlertData.getAdditionalData.listOfRequireDocuments'),
        value: alert.listOfRequireDocuments,
        editable: true,
        fieldName: 'listOfRequireDocuments',
        type: RowTadaType.textArea,
        rows: 10,
    },
    {
        key: 'isSendingRequestToCustomer',
        name: t('helpers.getAlertData.getAdditionalData.isSendingRequestToCustomer'),
        value: alert.isSendingRequestToCustomer,
        editable: true,
        fieldName: 'isSendingRequestToCustomer',
        type: RowTadaType.select,
        options: approveOption(),
        required: true,
    },
    {
        key: 'sendingRequestToCustomerDate',
        name: t('helpers.getAlertData.getAdditionalData.sendingRequestToCustomerDate'),
        value: getDateView(alert.sendingRequestToCustomerDate),
        editable: true,
        fieldName: 'sendingRequestToCustomerDate',
        type: RowTadaType.date,
        hide: !form?.getFieldValue('isSendingRequestToCustomer'),
        required: form?.getFieldValue('isSendingRequestToCustomer'),
    },
    {
        key: 'numberOfIncomingLetter',
        name: t('helpers.getAlertData.getAdditionalData.numberOfIncomingLetter'),
        value: alert.numberOfIncomingLetter,
        editable: true,
        fieldName: 'numberOfIncomingLetter',
        style: numberOfIncomingLetterStyle,
        hide: !form?.getFieldValue('isSendingRequestToCustomer'),
        required: form?.getFieldValue('isSendingRequestToCustomer'),
    },
    {
        key: 'isRequestReceived',
        name: t('helpers.getAlertData.getAdditionalData.isRequestReceived'),
        value: alert.isRequestReceived,
        editable: true,
        fieldName: 'isRequestReceived',
        type: RowTadaType.select,
        options: approveOption(),
        required: true,
    },
    {
        key: 'requestReceivedDate',
        name: t('helpers.getAlertData.getAdditionalData.requestReceivedDate'),
        value: getDateView(alert.requestReceivedDate),
        editable: true,
        fieldName: 'requestReceivedDate',
        type: RowTadaType.date,
        required: form?.getFieldValue('isRequestReceived') ? true : false,
        hide: !form?.getFieldValue('isRequestReceived'),
    },
    {
        key: 'isDocumentsProvided',
        name: t('helpers.getAlertData.getAdditionalData.isDocumentsProvided'),
        value: alert.isDocumentsProvided,
        editable: true,
        fieldName: 'isDocumentsProvided',
        type: RowTadaType.select,
        options: isDocumentsProvidedOptions(),
        required: true,
    },
    {
        key: 'providingDocumentsDate',
        name: t('helpers.getAlertData.getAdditionalData.providingDocumentsDate'),
        value: getDateView(alert.providingDocumentsDate),
        editable: true,
        fieldName: 'providingDocumentsDate',
        type: RowTadaType.date,
        required: form?.getFieldValue('isDocumentsProvided'),
        hide: form?.getFieldValue('isDocumentsProvided') !== 1,
    },
    {
        key: 'isCustomerDeclinedProvidingDocuments',
        name: t('helpers.getAlertData.getAdditionalData.isCustomerDeclinedProvidingDocuments'),
        value: alert.isCustomerDeclinedProvidingDocuments,
        editable: true,
        fieldName: 'isCustomerDeclinedProvidingDocuments',
        type: RowTadaType.select,
        options: approveOption(),
        required: true,
    },
    {
        key: 'dateOfDeclineProvidingDocuments',
        name: t('helpers.getAlertData.getAdditionalData.dateOfDeclineProvidingDocuments'),
        value: getDateView(alert.dateOfDeclineProvidingDocuments),
        editable: true,
        fieldName: 'dateOfDeclineProvidingDocuments',
        type: RowTadaType.date,
        hide: !form?.getFieldValue('isCustomerDeclinedProvidingDocuments'),
        required: form?.getFieldValue('isCustomerDeclinedProvidingDocuments'),
    },
    {
        key: 'commentsAboutRequest',
        name: t('helpers.getAlertData.getAdditionalData.commentsAboutRequest'),
        value: alert.commentsAboutRequest,
        editable: true,
        fieldName: 'commentsAboutRequest',
        type: RowTadaType.textArea,
        rows: 2,
    },
];

export const getCommentsData = (
    alert: ExtendedAlert,
    indicators: Option[],
    criteria: Option[],
    t: TFunction,
    minRisksMeasures?: RecordModel[],
    form?: FormInstance<any>,
): AlertTabRowData[] => [
    {
        key: 'commentsAboutProvidingDocuments',
        fieldName: 'commentsAboutProvidingDocuments',
        name: t('helpers.getAlertData.getCommentsData.commentsAboutProvidingDocuments'),
        value: alert.commentsAboutProvidingDocuments,
        editable: true,
        required: true,
        type: RowTadaType.textArea,
        rows: 10,
    },
    {
        key: 'anotherCounterpartiesInfo',
        name: t('helpers.getAlertData.getCommentsData.anotherCounterpartiesInfo'),
        value: alert.anotherCounterpartiesInfo,
        editable: true,
        fieldName: 'anotherCounterpartiesInfo',
        type: RowTadaType.textArea,
        rows: 10,
    },
    {
        key: 'otherMeasuresOfPZNP',
        fieldName: 'otherMeasuresOfPZNP',
        name: t('helpers.getAlertData.getCommentsData.otherMeasuresOfPZNP'),
        value: alert.otherMeasuresOfPZNP,
        editable: true,
        required: true,
        type: RowTadaType.textArea,
        rows: 3,
    },
    {
        key: 'negativeInformation',
        fieldName: 'negativeInformation',
        name: t('helpers.getAlertData.getCommentsData.negativeInformation'),
        value: alert.negativeInformation,
        editable: true,
        required: true,
        type: RowTadaType.textArea,
        rows: 3,
    },
    {
        key: 'manuallyCriteria',
        fieldName: 'manuallyCriteria',
        name: t('helpers.getAlertData.getCommentsData.manuallyCriteria'),
        value: alert.rules
            ?.filter(
                (rule) =>
                    rule.rule.type.code === RULES_TYPE_CRITERION && rule.addedManually === 1 && rule.additional === 0,
            )
            .map((rule) => rule.rule.id),
        editable: true,
        multiply: true,
        type: RowTadaType.select,
        options: criteria,
    },

    {
        key: 'manuallyIndicator',
        fieldName: 'manuallyIndicator',
        name: t('helpers.getAlertData.getCommentsData.manuallyIndicator'),
        value: alert.rules
            ?.filter(
                (rule) =>
                    rule.rule.type.code === RULES_TYPE_INDICATOR && rule.addedManually === 1 && rule.additional === 0,
            )
            .map((rule) => rule.rule.id),
        editable: true,
        multiply: true,
        type: RowTadaType.select,
        options: indicators,
    },
    {
        key: 'bankImplementationOfOtherNPC',
        fieldName: 'bankImplementationOfOtherNPC',
        name: t('helpers.getAlertData.getCommentsData.bankImplementationOfOtherNPC'),
        value:
            (alert.cddMeasures?.length || 0) > 0
                ? [...(alert.cddMeasures || [])]
                      .sort((a, b) => sortByDate(b, a, 'date'))
                      .map(
                          (item) =>
                              `${getDateView(item.date, false, false, true)}: ${item.type?.description} - ${
                                  item.description
                              };`,
                      )
                      .join('\n')
                : alert.bankImplementationOfOtherNPC,
        editable: !alert.cddMeasures?.length,
        required: !alert.cddMeasures?.length,
        type: RowTadaType.textArea,
        rows: 2,
        placeholder: 'Введіть значення, яке буде експортовано до електронної анкети клієнта та його ризик-профілю',
    },
    {
        key: 'minRisksMeasures',
        fieldName: 'minRisksMeasures',
        name: t('helpers.getAlertData.getCommentsData.minRisksMeasures'),
        value: alert.minRisksMeasures?.map((item) => item.id),
        editable: true,
        required: true,
        type: RowTadaType.select,
        options: minRisksMeasures?.map((item) => ({ value: item.id, label: item.description })),
        multiply: true,
        placeholder: 'Оберіть значення, яке буде експортовано до результатів звіту «Моніторинг ділових відносин»',
    },
    {
        key: 'measuresToMinimazeRisks',
        name: t('helpers.getAlertData.getCommentsData.measuresToMinimazeRisks'),
        value: alert.measuresToMinimazeRisks,
        editable: true,
        fieldName: 'measuresToMinimazeRisks',
        required: (() => {
            const otherId = minRisksMeasures?.find((item) => item.description === 'Інше')?.id;
            return form?.getFieldValue('minRisksMeasures')?.some((item: RecordModel['id']) => item === otherId);
        })(),
        type: RowTadaType.textArea,
        rows: 2,
    },
    {
        key: 'additionalComments',
        fieldName: 'additionalComments',
        name: t('helpers.getAlertData.getCommentsData.additionalComments'),
        value: alert.additionalComments,
        editable: true,
        type: RowTadaType.textArea,
        rows: 2,
    },
];

export const getConclusionData = (
    alert: ExtendedAlert,
    availableManual: Option[] = [],
    availableAdditionalCriteria: Option[] = [],
    t: TFunction,
    solution?: RecordModel[],
    totalRiskScore?: number,
    form?: FormInstance<any>,
    availableManualIndicators?: Option[],
    availableAdditionalIndicators?: Option[],
): AlertTabRowData[] => [
    {
        key: 'conclusionAboutSuspicions',
        fieldName: 'conclusionAboutSuspicions',
        name: t('helpers.getAlertData.getConclusionData.conclusionAboutSuspicions'),
        value: alert.conclusionAboutSuspicions,
        editable: true,
        required: true,
        type: RowTadaType.textArea,
        rows: 5,
    },
    {
        key: 'conclusion',
        fieldName: 'conclusion',
        name: t('helpers.getAlertData.getConclusionData.conclusion'),
        value: alert?.conclusion?.map((item) => item.id),
        options: solution?.map((item) => ({ value: item.id, label: item.description })),
        type: RowTadaType.select,
        editable: true,
        required: true,
        multiply: true,
    },
    {
        key: 'other',
        name: t('helpers.getAlertData.getConclusionData.other'),
        value: alert.other,
        editable: true,
        fieldName: 'other',
        required: (() => {
            const otherId = solution?.find((item) => item.description === 'Інше')?.id;
            return form?.getFieldValue('conclusion')?.some((item: RecordModel['id']) => item === otherId);
        })(),
        type: RowTadaType.textArea,
        rows: 2,
    },
    {
        key: 'approvedCriteria',
        name: t('helpers.getAlertData.getConclusionData.approvedCriteria'),
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_CRITERION && model.approved === 1 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'approvedCriteria',
        type: RowTadaType.select,
        options: availableManual,
        multiply: true,
    },
    {
        key: 'declinedCriteria',
        name: t('helpers.getAlertData.getConclusionData.declinedCriteria'),
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_CRITERION && model.approved === 0 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'declinedCriteria',
        type: RowTadaType.select,
        options: availableManual,
        multiply: true,
        disabled: true,
    },
    {
        key: 'additionalCriteria',
        name: t('helpers.getAlertData.getConclusionData.additionalCriteria'),
        value:
            alert.rules
                ?.filter((model) => model.rule.type.code === RULES_TYPE_CRITERION && model.additional === 1)
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'additionalCriteria',
        type: RowTadaType.select,
        options: availableAdditionalCriteria,
        multiply: true,
    },
    {
        key: 'totalRiskSumScore',
        name: t('helpers.getAlertData.getConclusionData.totalRiskSumScore'),
        value: totalRiskScore,
        editable: false,
    },
    {
        key: 'approvedIndicators',
        name: t('helpers.getAlertData.getConclusionData.approvedIndicators'),
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_INDICATOR && model.approved === 1 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'approvedIndicators',
        type: RowTadaType.select,
        options: availableManualIndicators,
        multiply: true,
    },
    {
        key: 'declinedIndicators',
        name: t('helpers.getAlertData.getConclusionData.declinedIndicators'),
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_INDICATOR && model.approved === 0 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'declinedIndicators',
        type: RowTadaType.select,
        options: availableManualIndicators,
        multiply: true,
        disabled: true,
    },
    {
        key: 'additionalIndicators',
        name: t('helpers.getAlertData.getConclusionData.additionalIndicators'),
        value:
            alert.rules
                ?.filter((model) => model.rule.type.code === RULES_TYPE_INDICATOR && model.additional === 1)
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'additionalIndicators',
        type: RowTadaType.select,
        options: availableAdditionalIndicators,
        multiply: true,
    },
];

export const getApprovalData = (
    alert: BaseAlert,
    t: TFunction,
    rolesParams?: UserModel['rolesParams'],
): AlertTabRowData[] => [
    {
        key: 'controllerComments',
        name: t('helpers.getAlertData.getApprovalData.controllerComments'),
        value: alert.controllerComments,
        editable: false,
    },
    {
        key: 'conclusionDate',
        fieldName: 'conclusionDate',
        name: t('helpers.getAlertData.getApprovalData.conclusionDate'),
        value: getDateView(alert.conclusionDate, !rolesParams?.IS_CONTROLLER),
        editable: rolesParams?.IS_CONTROLLER,
        required: rolesParams?.IS_CONTROLLER ?? false,
        disabledPastPeriod: true,
        type: RowTadaType.date,
    },
    {
        key: 'closeDate',
        name: t('helpers.getAlertData.getApprovalData.closeDate'),
        value: alert.closeDate && getDateView(alert.closeDate, true),
        editable: false,
    },
];

export const getMeasuresData = (): AlertTabRowData[] => [
    {
        key: 'cddMeasures',
        name: 'Заходи, які було вжито',
        type: RowTadaType.table,
    },
];
