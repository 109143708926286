import React, { FC } from 'react';
import { Form, Input, Select } from 'antd';
import { useGetMenuFunctionsQuery } from '../../../redux/api/userApi';
import { useTranslation } from 'react-i18next';
const RoleDataFields: FC = () => {
    const { t } = useTranslation();
    const { data: menuFunctions, isLoading: menuIsLoading, isFetching: menuIsFetching } = useGetMenuFunctionsQuery();

    return (
        <>
            <Form.Item name="name" rules={[{ required: true }]}>
                <Input placeholder={t('forms.role.roleData.name')} disabled />
            </Form.Item>
            <Form.Item name="description">
                <Input placeholder={t('forms.role.roleData.description')} />
            </Form.Item>
            <Form.Item name="menuFunctions">
                <Select
                    mode="multiple"
                    placeholder={t('forms.role.roleData.menuFunctions.placeholder')}
                    style={{ width: '100%' }}
                    fieldNames={{ value: 'id', label: 'name' }}
                    options={menuFunctions as any}
                    loading={menuIsLoading || menuIsFetching}
                />
            </Form.Item>
        </>
    );
};

export default RoleDataFields;
